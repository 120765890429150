import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // @Input max_h:boolean;
  @Input() max_h: any;
  constructor() { }
  ngOnInit(): void {    
  }
}
