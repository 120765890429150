<footer>
    <!-- id="page-footer" -->
   <!--  footer-wrapper -->
    <div class="footer-wrapper" [ngClass]="{'footer_add':max_h == true}">
        <!-- <div class="footer-navigation">
            <div class="container">
                <div class="vertical-aligned-elements"> -->
        <!-- <div class="footer_content"> -->
        <span class="float_l  ">
                <!-- <a href="">{{'Help' | translate}}</a> | --> <a routerLink="/contact">{{'Contact' | translate}}</a> | <a routerLink="/terms-conditions">{{'Terms and Conditions' | translate}}</a> | <a routerLink="/privacy-policy">
                    {{'Privacy Policy' | translate}}
                </a>
                <br>(C) 2021 {{'Fort Kochi Tourisum App' | translate}}, {{'All right reserved' | translate}}.</span>
        <span class="follow  ">
          <!--   <a class="">{{'Follow us' | translate}} </a> -->
            <!-- <span class="material-icons">facebook</span>
        <span class="iconify" data-icon="mdi-instagram" data-inline="false"></span> -->
        <!-- <mat-icon>facebook</mat-icon>
        <mat-icon>twitter</mat-icon> fa-2x -->
       <!--  <a ><i class="fa fa-twitter social-icon fa-2x"></i></a>
        <a ><i class="fa fa-facebook-square social-icon fa-2x"></i></a>
        <a ><i class="fa fa-instagram social-icon fa-2x"></i></a> -->
        </span>
        <!-- </div> -->
        <!-- </div>
            </div>
        </div> -->
    </div>
</footer>