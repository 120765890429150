<!-- <div class="map-section" [ngClass]="{'remove-map-section':chevaron_status == false}">-->
<div class="map-section-item">
    <div #gmap id="gmap"></div>
</div><!--  *ngIf="Service.sub_layer_url != '' && Service.sub_layer_url != undefined && Service.sub_layer_url != null" -->
<div class="main-search-item" [ngClass]="{'show_hide_transit': transit_show == false||transit_show_state==false,'main-search-item_h':transit_status == true}">
    <button class="btn_blue" [disabled]="transit_show_state==false" [ngClass]="{'transit_show_state':transit_show_state==false}" (click)="transitToggle()"><span>{{'Transit Options'|translate}}</span> <span class="material-icons" *ngIf="transit_show">arrow_drop_down</span><span class="material-icons" *ngIf="!transit_show">arrow_drop_up</span></button>
     
    <div class="main-search-box route-box-item">
        <div class="search-box-guid">
            <div class="mg_top d-flex">
                
                <div class="transit_main">
                    <p class="clr_white">{{'Choose your options'|translate}}</p>
                    <div class="choose_your_option">
                        <div>
                            <p class="clr_white">
                                <img src="assets/images/2.jpg" width="60px" height="35px" style="border-radius:10px;" />
                                <span class="choose_option_txt"> Jeeva Medicals</span>
                            </p> 
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteBus">
                        <p class="transit_pad" #route_bus (click)="routeTimerCheck('bus')">
                            <i class="fa fa-bus fa-2x text-white" aria-hidden="true"></i>
                            <span class="choose_option_txt">{{'Bus'|translate}}</span>
                        </p>
                            <!-- <p class="choose_option_txt"></p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteTaxi">
                            <p class="transit_pad" #route_taxi (click)="routeTimerCheck('taxi')"><i class="fas fa-taxi fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">{{'Taxi'|translate}}</span></p>
                            <!-- <p class="choose_option_txt">Taxi</p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteTrain">
                            <p class="transit_pad" #route_train (click)="routeTimerCheck('train')"><i class="fas fa-train fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">{{'Train'|translate}}</span></p>
                            <!-- <p class="choose_option_txt">Train</p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteFerry">
                            <p class="transit_pad" #route_ferry (click)="routeTimerCheck('ferry')"><i class="fas fa-ship fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">{{'Ferry'|translate}}</span></p>
                            <!-- <p class="choose_option_txt">Ferry</p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteBike">
                            <p class="transit_pad" #route_bike (click)="routeTimerCheck('bike')"><i class="fas fa-motorcycle fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">{{'Bike'|translate}}</span></p>
                            <!-- <p class="choose_option_txt">Bike</p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteWalk">
                            <p class="transit_pad" #route_walk (click)="routeTimerCheck('walk')"><i class='fas fa-walking fa-2x text-white'></i><span class="choose_option_txt">{{'Walk'|translate}}</span>
                            </p>
                            <!-- <p class="choose_option_txt">Walk</p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteAuto">
                            <!-- <p class="transit_pad" #route_auto (click)="routeTimerCheck('auto')"><i class='fas fa-cab fa-2x text-white'></i><span class="choose_option_txt">{{'Auto'|translate}}</span>
                            </p> -->
                            <p class="transit_pad" #route_auto (click)="routeTimerCheck('auto')"><img style="width: 17px;" src="assets/images/autoicon.png"/><span class="choose_option_txt">{{'Auto'|translate}}</span>
                            </p>
                            <!-- <p class="choose_option_txt">Auto</p> -->
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteMetro">
                            <p class="transit_pad" #route_metro (click)="routeTimerCheck('metro')"><i class='fas fa-subway fa-2x text-white'></i><span class="choose_option_txt">{{'Metro'|translate}}</span>
                            </p> 
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteBicycle">
                            <p class="transit_pad" #route_bicycle (click)="routeTimerCheck('bicycle')"><i class='fas fa-bicycle fa-2x text-white'></i><span class="choose_option_txt">{{'Bicycle'|translate}}</span>
                            </p> 
                        </div>
                        <div class="option_icon_main" *ngIf="availableRouteCar">
                            <p class="transit_pad" #route_car (click)="routeTimerCheck('car')"><i class='fas fa-car fa-2x text-white'></i><span class="choose_option_txt">{{'Car'|translate}}</span>
                            </p> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="route-box-guid">
                <!-- <div class="route_main"> -->
                <div *ngIf="bus_element == true">
                    <p class="clr_white">{{'Bus Routes'|translate}}</p>
                    <!-- <swiper [config]="config" class="swiper_main_wid">
                        
                        <div class="route_timer_box" *ngFor="let bus_route of bus_route_list">
                            <div class="timer_box">
                                <p class="timer_txt_t">{{bus_route.eta}}</p>
                                <p class="f_sz25">{{bus_route.timing}}</p>
                                <p>{{bus_route.noon}}</p>
                            </div>
                        </div>
                    </swiper> -->
                    <swiper [config]="config" class="swiper_main_wid">
                        <div class="swiper-wrapper">
                            <div class="route_timer_box swiper-slide" *ngFor="let bus_route of bus_route_list">
                                <div class="timer_box" (click)="ShowBusTimings(bus_route)">
                                    <p class="timer_txt_t">{{bus_route.route_long_name}}</p>
                                    <p class="f_sz25">{{bus_route.timing}}</p>
                                    <p>{{bus_route.noon}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </swiper>
                    <br/>
                     <br />
                    <div>
                        <div class="route_timer_box"
                            *ngIf="busTimingStart.stopstime_arrival_time  && busTimingEnd.stopstime_arrival_time"> 
                            <div class="timer_box">
                                <p class="timer_txt_t">Start Time: {{busTimingStart.stopstime_arrival_time}}</p>
                                <p class="timer_txt_t">End Time: {{busTimingEnd.stopstime_arrival_time}}</p>
                                <p class="timer_txt_t" *ngIf="busFare">Total Fare:{{busFare.fare_price}} </p>
                            </div>
                        </div>
                    </div>
                    <p *ngIf="!bus_route_list" class="clr_white">No Records Found</p>
                </div>
                <div *ngIf="ferry_element == true">
                    <p class="clr_white">{{'Ferry Routes'|translate}}</p>
                    <swiper [config]="config" class="swiper_main_wid">
                        <div class="swiper-wrapper">
                            <div class="route_timer_box swiper-slide" *ngFor="let ferryRoute of ferry_route_list">
                                <div class="timer_box" (click)="ShowFerryTimings(ferryRoute)">
                                    <p class="timer_txt_t">{{ferryRoute.ferry_route_long_name}}</p>
                                </div>
                            </div>
                        </div> 
                    </swiper>
                    <br />
                    <div *ngIf="ferry_element == true && ferryTimeInfo">
                        <div class="route_timer_box">
                            <div class="timer_box">
                                <p class="timer_txt_t">Start Time: {{ferryTimeInfo.ferry_stopstime_arrival_time}}</p>
                                <p class="timer_txt_t"></p>
                                <p class="timer_txt_t">Total Fare: 73</p>
                            </div>
                        </div>
                    </div>
                    <p *ngIf="!ferry_route_list" class="clr_white">No Records Found</p>
                </div>
 
                    <div *ngIf="metro_element == true">
                        <p class="clr_white">{{'Metro Routes'|translate}}</p>
                        <swiper [config]="config" class="swiper_main_wid">
                            <div class="swiper-wrapper">
                                <div class="route_timer_box swiper-slide" *ngFor="let metroRoute of metro_route_list">
                                    <div class="timer_box" (click)="ShowMetroTimings(metroRoute)">
                                        <p class="timer_txt_t">{{metroRoute.kmrl__route_long_name}}</p>
                                    </div>
                                </div>
                            </div> 
                        </swiper>
                        <br />
                        <div *ngIf="metro_element == true && metroTimeInfo">
                            <div class="route_timer_box">
                                <div class="timer_box">
                                    <p class="timer_txt_t">Start Time: {{metroTimeInfo.kmrl_stopstime_departure_time}}</p>
                                    <p class="timer_txt_t"></p>
                                    <p class="timer_txt_t">Total Fare: 73</p>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="(metro_route_list == undefined) ||  (metro_route_list.length == 0)" class="clr_white">No Records Found</p>
                    </div>

                <div *ngIf="auto_element" class="route_main_distance" #distance_fees>
                    <p class="clr_white">{{'Distance & Fees'|translate}}</p>
                    <div class="router_option">
                        <div class="route_timer_box">
                            <ion-row>
                                <ion-col size="3" class="timer_box">
                                    <p class="totalkm">{{'Total'|translate}} Km</p>
                                    <p class="distance">0.800</p>
                                </ion-col>
                                <ion-col size="3" class="timer_box">
                                    <p class="totalkm">{{'Fare'|translate}}</p>
                                    <p class="distance">15</p>
                                </ion-col>
                            </ion-row>
                        </div>
                    </div>
                </div>
            <!--     <div class="route_main_distance" #distance_fees>
                    <p class="clr_white">{{'Distance & Fees'|translate}}</p>
                    <div class="router_option">
                        <div class="route_timer_box">
                            <div class="timer_box_dis">
                                <p class="timer_txt_t">{{'Total'|translate}} Km</p>
                                <p class="f_sz25">0.800</p>
                            </div>
                        </div>
                        <div class="route_timer_box">
                            <div class="timer_box_dis">
                                <p class="timer_txt_t">{{'Fare'|translate}}</p>
                                <p class="f_sz25">15</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- <div #gmap id="gmap"></div> -->