<div class="row">
    <app-header></app-header>
</div>
<!-- *ngIf="events_details == false" -->
<div class="page-content" *ngIf="events_details == false">
    <div class="map-box-item map-top"><!-- style="margin-top: 135px;" -->
        <div class="hero-section height-300px has-map map" [ngClass]="{'maxi': maxiHeight == true||Service.main_url=='wafi'}">
            <div #gmap id="gmap" style="width:100%;">
            </div>
            <!-- <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" id="search" #search> -->
        </div>
      <!--   <div>
            <div *ngIf="event_status == true && maxiHeight == false && Service.main_url!='wafi'" class="event_txt"><span class="circle_cnt_black">6</span> <span class="">Events</span></div>
            <div *ngIf="Service.main_url!='wafi'" class="pull-right chevaron_main" style="margin-right:10px;margin-top: 0px;">
                <a  *ngIf="maxiHeight == false" (click)="heightChange('max')" type="button"><span routerLink="/exko" style="cursor: pointer;" class="more_txt">{{'More'|translate}}</span><i class="fa fa-chevron-down fa-2x cursor_pointer" aria-hidden="true"
                    style="color:#888;font-size: 20px;"></i></a>
                <a  *ngIf="maxiHeight == true" (click)="heightChange('min')" type="button"><span routerLink="/exko" style="cursor: pointer;" class="more_txt">{{'More'|translate}}</span><i class="fa fa-chevron-up fa-2x cursor_pointer" aria-hidden="true"
                    style="color:#888;font-size: 20px;"></i></a>
            </div>
        </div> -->
    </div>
  <!--   <div *ngIf="maxiHeight == false&&Service.main_url=='home'" class="col-lg-12 col-sm-12 swiper_main swiper-main">
        <swiper [config]="config">
            <div class="swiper-wrapper">
                <div *ngFor="let item of Service.eventDetails" class="swiper-slide card card-body">
                    <div class="img_height_200">
                        <img (click)="eventsDetail(item)" class="emerg_inner_img cursor_pointer" [src]='item.imageId' />
                    </div>
                    <p class="carousel_item_head">{{item.title}} </p>
                    <p class="font_w_600 cursor_pointer" matTooltipPosition="below" [matTooltipClass]="'row-tooltip'" matTooltip="{{item.description}}" [innerHTML]="item.description.slice(0, 60) + (item.description.length > 60 ? '...' : '')"></p>
                    <p class="mrg_bot7" matTooltipPosition="below" [matTooltipClass]="'row-tooltip'" matTooltip="{{item.address.fullAddrText}}">{{item.address.fullAddrText.slice(0, 45) + (item.address.fullAddrText.length > 45 ? "..." : "")}}</p>
                    <p class="mrg_bot7">{{item.start}} - {{item.end}}</p>
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </swiper>
    </div> -->
</div>
<div class="col-md-12 footer_bot" *ngIf="events_details == false">
    <app-footer [max_h]="maxiHeight"></app-footer>
</div>

<script>
    
    //Set each section's height equals to the window height
    $('section').height($(window).height());
    /*set the class 'active' to the first element 
     this will serve as our indicator*/
    $('section').first().addClass('active');

    /* handle the mousewheel event together with 
     DOMMouseScroll to work on cross browser */
    $(document).on('mousewheel DOMMouseScroll', function (e) {
        e.preventDefault();//prevent the default mousewheel scrolling
        var active = $('section.active');
        //get the delta to determine the mousewheel scrol UP and DOWN
        var delta = e.originalEvent.detail < 0 || e.originalEvent.wheelDelta > 0 ? 1 : -1;
        
        //if the delta value is negative, the user is scrolling down
        if (delta < 0) {
            //mousewheel down handler
            next = active.next();
            //check if the next section exist and animate the anchoring
            if (next.length) {
               /*setTimeout is here to prevent the scrolling animation
                to jump to the topmost or bottom when 
                the user scrolled very fast.*/
                var timer = setTimeout(function () {
                    /* animate the scrollTop by passing 
                    the elements offset top value */
                    $('body, html').animate({
                        scrollTop: next.offset().top
                    }, 'slow');
                    
                    // move the indicator 'active' class
                    next.addClass('active')
                        .siblings().removeClass('active');
                    
                    clearTimeout(timer);
                }, 800);
            }

        } else {
            //mousewheel up handler
            /*similar logic to the mousewheel down handler 
            except that we are animate the anchoring 
            to the previous sibling element*/
            prev = active.prev();

            if (prev.length) {
                var timer = setTimeout(function () {
                    $('body, html').animate({
                        scrollTop: prev.offset().top
                    }, 'slow');

                    prev.addClass('active')
                        .siblings().removeClass('active');
                    
                    clearTimeout(timer);
                }, 800);
            }

        }
    });


</script>
