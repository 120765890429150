import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component'; 
import { EmergencyComponent } from './pages/emergency/emergency.component';
import { EventsComponent } from './pages/events/events.component';
import { InitHomeComponent } from './pages/init-home/init-home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsandconditionsComponent } from './pages/termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
// import{ MapSectionComponent } from './pages/map-section/map-section.component';
// import { from } from 'rxjs';
const routes: Routes = [
/*   {path:'home', component:HomeComponent}, */
  {path:'home', component:InitHomeComponent},
  // {path:'home/:id', component:InitHomeComponent},
  {path:'contact', component:ContactComponent},
  {path:'wafi', component:EmergencyComponent},
  {path:'exko', component:EventsComponent},
  { path: 'wafi/:item', component:EmergencyComponent },
  { path: 'wafi/:item/:item', component:EmergencyComponent },
  { path: 'wafi/:item/:item/:item', component:EmergencyComponent },
  
  { path: 'exko/:item', component:EventsComponent },
  { path: 'exko/:item/:item', component:EventsComponent },
  // {path:'exko/:item', redirectTo: 'exko', pathMatch: 'full'},
  {path:'',redirectTo:'home', pathMatch:'full'},
  {path:'terms-conditions', component:TermsandconditionsComponent},
  {path:'privacy-policy', component:PrivacypolicyComponent},
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
