<div class="row" *ngIf="show_map == false">
    <app-header (disableFun)="disableFun()" (parentMain)="wafiPlacesGetWithMainLayer()" (parentWafiFun)="wafiPlacesGetWithLayer()" (singlePlacesFns)="wafiPlacesDetails($event)"></app-header>
</div>

<div class="cotact-wrapper" *ngIf="show_map == false">

    <div class="form-section pr-0" [ngClass]="{'remove-form-section':chevaron_status == false}">
        <span *ngIf="chevaron_status == true" (click)="chevaronMin('min')" class="chevaron_left_sty">
            <i class="fas fa-chevron-left"></i>
        </span>
        <span *ngIf="chevaron_status == false" (click)="chevaronMin('max')" class="chevaron_left_sty">
            <i class="fas fa-chevron-right"></i>
        </span>
        <div class="form-item" style="padding-top:90px;">
            <span class="emergncy_steps"><span (click)="wafiPlacesDetailsClose()" class="back_btn"
                    *ngIf="back_btn==true&&qr_back_btn==true"><span><i class="fas fa-chevron-left left_icon_pos"></i>
                    </span><span class="cursor_pointer">{{'Back'|translate}}</span></span>
            <span>{{Service.mainLayer}}</span><span class="arrow_icn"><i class="fa fa-arrow-right arrow_icn_right" aria-hidden="true"></i>
                    
                </span> <span style="color:'#445396'"> {{Service.subLayer}}</span><span class="circle_cnt">{{Service.placesList.length}}</span></span>

            <div class="emerg_sec-scroll  pr-0 col-sm-12 col-xs-12 col-md-12 col-lg-12" *ngIf="Service.placesList?.length>0">
                <ng-scrollbar #scrollable track="all" thumbClass="custom-scrollbars" pointerEventsMethod="scrollbar">
                    <div *ngIf="placesList_Single==''">

                        <div *ngFor="let data of Service.placesList">
                            <div class="emerg_sec" *ngIf="data.showstatus">
                                <div class="emerg_main">
                                    <div (click)="wafiPlacesDetails(data)" class="emerg_img"> <img [src]='data.imageId' /></div>
                                    <div class="emerg_main_txt">
                                        <div class="emerg_main_txt_inn">{{data.title}} <span class="green_clr">{{data.status}}</span><span class="green_clr">{{data.openstatus}}</span></div>
                                        <div class="emerg_main_txt_inn1" [innerHTML]="data.scheduleText"></div>
                                        <div class="emerg_main_txt_inn1" *ngIf="data.schedules?.length>0&&data.scheduleText==''">Timings<br/>{{data.schedules[0].opentime}}-{{data.schedules[0].closetime}}</div>
                                        <div class="emerg_main_txt_inn2" *ngIf="data.schedules?.length>0">{{data.schedules[0].dayto}}</div>
                                    </div>

                                </div>
                                <div>
                                    <ul class="emerg_icn">
                                        <li><i class="fas fa-stethoscope"></i></li>
                                        <li><i class="fas fa-glass-martini-alt"></i></li>
                                        <li><i class="fas fa-prescription-bottle"></i></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="placesList_Single!='' &&  placesList_Single != undefined">

                        <div class="emerg_sec" *ngIf="placesList_Single.showstatus">
                            <div class="emerg_main">
                                <div class="emerg_img"><img [src]='placesList_Single.imageId' /></div>
                                <div class="emerg_main_txt">
                                    <div class="emerg_main_txt_inn">{{placesList_Single.title}} <span class="green_clr">{{placesList_Single.status}}</span><span class="green_clr">{{placesList_Single.openstatus}}</span></div>
                                    <div class="emerg_main_txt_inn1" [innerHTML]="placesList_Single.scheduleText"></div>
                                    <div class="emerg_main_txt_inn1" *ngIf="placesList_Single.schedules?.length>0&&placesList_Single.scheduleText==''">Timings<br/>{{placesList_Single.schedules[0].opentime}}-{{placesList_Single.schedules[0].closetime}}</div>
                                    <div class="emerg_main_txt_inn2" *ngIf="placesList_Single.schedules?.length>0">{{placesList_Single.schedules[0].dayto}}</div>
                                </div>
                                <div class="content-height" *ngIf="back_btn == true">
                                    <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar">
                                        <div class="emerg_detail" *ngIf="back_btn == true&&placesList_Single.details.description !=null" [innerHTML]="placesList_Single.details.description"></div>
                                        <div class="emerg_detail" *ngIf="back_btn == true&&placesList_Single.details.info !=null">{{placesList_Single.details.info}}</div>
                                        <div *ngIf="back_btn == true">
                                            <div class="residency-details" id="demo">
                                                <div *ngFor="let list of placesList_Single.details.images" class="residency-details-item item-1"><img [src]="list" alt="" (click)="zoomImage('add')" class="img-responsive cursor_pointer">
                                                    <i class="fa fa-times cursor_pointer" (click)="zoomImage('remove')" 
                                                    [ngClass]="isImgZoom == true ? 'remove_show_emerg' : 'remove_Icon'"></i> 
                                                </div>
                                            </div>
                                        </div>
                                    </ng-scrollbar>
                                </div>
                            </div>
                            <div>
                                <ul class="emerg_icn">
                                    <li><i class="fas fa-stethoscope"></i></li>
                                    <li><i class="fas fa-glass-martini-alt"></i></li>
                                    <li><i class="fas fa-prescription-bottle"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </ng-scrollbar>
            </div>
            <div class="no_data" *ngIf="Service.placesList?.length==0">
                {{'No Data Found'|translate}}
            </div>
        </div>
        <!-- <div>
            
            <span class="copyright_side"><a href="">{{'Help'|translate}}</a> | <a routerLink="/contact">{{'Contact'|translate}}</a> | <a href="">{{'Terms and Conditions'|translate}}</a> | <a href="">
                {{'Private Policy'|translate}}
            </a>
            <br>(C) 2021 {{'Fort Kochi Tourisum App'|translate}}, {{'All right reserved'|translate}}.
            <div class="row">
            <span  class="follow col-lg-12"><a >Follow us </a>
                <a><i class="fa fa-twitter social-icon fa-2x"></i></a>
                <a ><i  class="fa fa-facebook-square social-icon fa-2x"></i></a>
                <a ><i  class="fa fa-instagram social-icon fa-2x"></i></a></span>
            </div>
        </span>
           
        </div> -->
        
    </div>
    <div class="map-section" [ngClass]="{'remove-map-section':chevaron_status == false}">
        <app-map-section (parentwafiPlacesGetAll)="wafiPlacesGetWithMainLayer()" [transit_show_state]="back_btn" (parentwafiPlacesGet)="wafiPlacesGetWithLayer()" [chev]="chevaron_status"></app-map-section>
    </div>
</div>

<!-- <div class="page-content" *ngIf="show_map == true">

    <div class="map-box-item">
        <div id="map_canvas" style="width: 100%;" class="maxi"></div>

    </div>
</div> -->
<!-- <div class="col-md-12 footer_bot" *ngIf="show_map == true">
    <app-footer></app-footer>
</div> -->
<app-home *ngIf="show_map == true"></app-home>