import { Component,Renderer2,Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CustomService } from './services/custom.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
// import { ScriptService } from "./services/script.service";
// import  *  as  data  from  '../assets/i18n/en.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FK-WEB';
  // loadAPI: Promise<any>;
  constructor(@Inject(DOCUMENT) private _document: Document,private renderer: Renderer2,public Service: CustomService,public router: Router,public cookieService: CookieService,public translate: TranslateService){
    let oldScript:any = document.getElementById("googlemap");
    // if (oldScript !== null&&oldScript !== undefined&&oldScript !== '') {
    //   oldScript.remove();
    // }
    if (oldScript === null||oldScript === undefined||oldScript === '') {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.id='googlemap';
    // script.src = 'http://maps.googleapis.com/maps/api/js?language=' + localStorage.getItem('current_language') + '&libraries=geometry&sensor=false&key=AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg';
    script.src = 'https://maps.googleapis.com/maps/api/js?language=' + localStorage.getItem('current_language') + '&key=AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg';//&sensor=false
    this.renderer.appendChild(this._document.body, script);
    }
    this.Service.current_language = 'EN';
    translate.addLangs(['EN', 'ML']);
    translate.setDefaultLang('EN');
    this.translate.use(this.Service.current_language);
  }
  
    ngOnInit(){ 
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        console.log(result.state);
        if(result.state === "granted" || result.state === "prompt"){
              if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
  
          let latitude = position.coords.latitude.toFixed(6);
          let langitude = position.coords.longitude.toFixed(6) 
          this.Service.current_lat = Number(latitude);
          this.Service.current_lng = Number(langitude);
        },err=>{
          console.log("err",err); 
          this.Service.current_lat = 9.953000;
          this.Service.current_lng = 76.240000;
        });
      } else { 
        this.Service.current_lat = 9.953000;
        this.Service.current_lng = 76.240000;
      }
        } else {
          this.Service.current_lat = 9.953000;
          this.Service.current_lng = 76.240000;
        }
      });
 
   }

  
}
