import { NgModule, CUSTOM_ELEMENTS_SCHEMA,APP_INITIALIZER } from '@angular/core';
import { HttpClientModule,HttpClient,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './pages/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './pages/footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { ContactComponent } from './pages/contact/contact.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared_module/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmergencyComponent } from './pages/emergency/emergency.component';
import { MapSectionComponent } from './pages/map-section/map-section.component';
import { EventsComponent } from './pages/events/events.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CustomService } from './services/custom.service';
// import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
// import { SwiperModule } from 'swiper/angular';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import {MatIconModule} from '@angular/material/icon';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader'; 
import { CookieService } from 'ngx-cookie-service';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { InitHomeComponent } from './pages/init-home/init-home.component';
import { QRCodeModule } from 'angular2-qrcode';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSpinnerModule  } from "ngx-spinner";
import { TermsandconditionsComponent } from './pages/termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export function get_settings(appLoadService: CustomService) {
  return () => appLoadService.typeList();
}
// export function get_wafi(appLoadService: CustomService) {
//   return () => appLoadService.wafiMenuList();
// }
// export function get_exko(appLoadService: CustomService) {
//   return () => appLoadService.exkoMenuList();
// }
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ContactComponent,
    SidebarComponent,
    EmergencyComponent,
    MapSectionComponent,
    EventsComponent,
    InitHomeComponent,
    TermsandconditionsComponent,
    PrivacypolicyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    RichTextEditorAllModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg',
      libraries: ['places']
    }),
    BrowserAnimationsModule,
    // Angular2UsefulSwiperModule,
    NgxUsefulSwiperModule,
    // SwiperModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    MatIconModule,
    QRCodeModule,
    NgxSpinnerModule,
    // MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    
    CustomService,CookieService,{ provide: APP_INITIALIZER, useFactory: get_settings, deps: [CustomService], multi: true },
    
    { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
// {  
//   provide: HTTP_INTERCEPTORS,  
//   useClass: JwtInterceptor,  
//   multi: true  
// },
// { provide: APP_INITIALIZER, useFactory: get_wafi, deps: [CustomService], multi: true },{ provide: APP_INITIALIZER, useFactory: get_exko, deps: [CustomService], multi: true },
export class AppModule { }
