<div class="row">
    <app-header></app-header>
</div>
<div class="cotact-wrapper">
    <div class="form-section" [ngClass]="{'remove-form-section':chevaron_status == false}">
        <span style="margin-top: 0px;" *ngIf="chevaron_status == true" (click)="chevaronMin('min')"  class="chevaron_left_sty">
            <i class="fas fa-chevron-left"></i>
        </span>
        <span style="margin-top: 0px;" *ngIf="chevaron_status == false" (click)="chevaronMin('max')" class="chevaron_left_sty">
            <i class="fas fa-chevron-right"></i>
        </span>
        <div class="form-item">
            <h2>{{'Talk to Us'|translate}}</h2>
            <p> {{'Got a query'|translate}}</p>
            <form [formGroup]="sendMailForm" #sendMailId="ngForm">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput autocomplete="off"
                            oninput="this.value = this.value.replace(/^\s*/, '')"
                            formControlName="mailerName" placeholder="{{ 'Name' | translate }}">
                            <!-- <mat-error *ngIf="errorHandling('mailerName', 'required')">
                                You must provide a name
                            </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput autocomplete="off"
                            oninput="this.value = this.value.replace(/^\s*/, '')"
                            formControlName="mailerEmail" placeholder="{{ 'Email Id' | translate }}">
                        <!-- <mat-error *ngIf="errorHandling('mailerEmail', 'required')">
                            You must provide a email
                        </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput (keypress)="Service.NumberKeyPress($event)" [maxlength]="10" autocomplete="off"
                            oninput="this.value = this.value.replace(/^\s*/, '')"
                            formControlName="mailercontactNumber" placeholder="{{ 'Contact no.(optional)' | translate }}">
                            <!-- <mat-error *ngIf="errorHandling('mailercontactNumber', 'required')">
                                You must provide a contact no
                            </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                    <mat-select [disableOptionCentering]="true" autocomplete="off"
                         formControlName="mailerPurpose" placeholder="{{ 'Purpose' | translate }}">
                         <mat-option value="Event">Event</mat-option>
                         <mat-option value="Information about city">Information about city</mat-option>
                         <mat-option value="Suggestion">Suggestion</mat-option>
                         <mat-option value="Complaint">Complaint</mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <textarea matInput rows="5" formControlName="mailerMessage" cols="40" placeholder="Your query/information to share:"></textarea>
                       
                    </mat-form-field>
                </div>
                <div class="form-group">
                   
                    <!-- <button type="submit" class="btn_send" (click)="sendMail()">Send Message</button> -->
                    <button type="submit" class="btn-raidus-white send-btn" (click)="sendMail()">Send</button>
                </div>
            </form>
            <!-- <form [formGroup]="sendMailForm" autocomplete="off" #sendMailId="ngForm">
                <div class="col-md-12">
                    <mat-form-field>
                       
                        <input matInput autocomplete="off" placeholder="Name" formControlName="cont_name">
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <input matInput autocomplete="off" placeholder="Email" formControlName="cont_email">
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <input matInput autocomplete="off" placeholder="Phone/Mobile" formControlName="cont_phone">
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                  
                    <mat-form-field>
                        <input matInput autocomplete="off" placeholder="Location" formControlName="cont_location">
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <mat-label>Purpose</mat-label>
                       
                        <mat-select formControlName="cont_purpose">
                            <mat-option value="1">xxx</mat-option>
                            <mat-option value="2">yyy</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <textarea matInput placeholder="Suggestion / Comments" formControlName="cont_sugg"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-md-12 text-center">
                    <button type="button" class="btn-raidus-white send-btn">Send</button>
                </div>
            </form> -->
        </div>
        <div>
            <!-- <app-footer></app-footer> -->
           <!--  <span class="copyright_side">
                <a href="">Help</a> | <a routerLink="/contact">Contact</a> | <a href="">Terms and Conditions</a> | <a href="">
                    Private Policy
                </a>
                <br>(C) 2021 Fort Kochi Tourisum App, All right reserved.</span> -->
        </div>
    </div>
    <!-- <div class="map-section" [ngClass]="{'remove-map-section':chevaron_status == false}">
        <div class="map-section-item">
            <div #gmap id="gmap"></div>
        </div>
        <div class="main-search-item" [ngClass]="{'show_hide_transit': transit_show == false}">
            <p class="btn_blue" (click)="transitToggle()"><span>Transit Options</span> <span class="material-icons" *ngIf="transit_show">arrow_drop_down</span><span class="material-icons" *ngIf="!transit_show">arrow_drop_up</span></p>
            <div class="main-search-box">
                <div class="mg_top">
                    <img src="assets/images/2.jpg" width="70px" height="50px" style="border-radius:10px;" />
                    <span>Jeeva Medicals</span>
                </div>
                <div class="transit_main">
                    <p style="color:#fff;">Choose your options</p>
                    <div class="choose_your_option">
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_bus (click)="routeTimerCheck('bus')"><i class="fa fa-bus fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">Bus</span></p>
                           
                        </div>
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_taxi (click)="routeTimerCheck('taxi')"><i class="fas fa-taxi fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">Taxi</span></p>
                         
                        </div>
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_train (click)="routeTimerCheck('train')"><i class="fas fa-train fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">Train</span></p>
                           
                        </div>
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_ferry (click)="routeTimerCheck('ferry')"><i class="fas fa-ship fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">Ferry</span></p>
                          
                        </div>
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_bike (click)="routeTimerCheck('bike')"><i class="fas fa-motorcycle fa-2x text-white" aria-hidden="true"></i><span class="choose_option_txt">Bike</span></p>
                            
                        </div>
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_walk (click)="routeTimerCheck('walk')"><i class='fas fa-walking fa-2x text-white'></i><span class="choose_option_txt">Walk</span></p>
                         
                        </div>
                        <div class="option_icon_main">
                            <p class="transit_pad" #route_auto (click)="routeTimerCheck('auto')"><i class='fas fa-car-side fa-2x text-white'></i><span class="choose_option_txt">Auto</span></p>
                          
                        </div>
                    </div>
                </div>
                <div class="route_main" #route_main>
                    <p style="color:#fff;">Bus Routes</p>
                    <div class="router_option">
                        <div class="route_timer_box">
                            <div class="timer_box">
                                <p class="timer_txt_t">ETA</p>
                                <p class="f_sz25">08.25</p>
                                <p>am</p>
                            </div>
                            <div class="timer_txt">
                                Aluva Bypass,Padma
                            </div>
                        </div>
                        <div class="route_timer_box">
                            <div class="timer_box">
                                <p class="timer_txt_t">ETA</p>
                                <p class="f_sz25">08.25</p>
                                <p>am</p>
                            </div>
                            <div class="timer_txt">
                                Aluva Bypass,Padma
                            </div>
                        </div>
                        <div class="route_timer_box">
                            <div class="timer_box">
                                <p class="timer_txt_t">ETA</p>
                                <p class="f_sz25">08.25</p>
                                <p>am</p>
                            </div>
                            <div class="timer_txt">
                                Aluva Bypass,Padma
                            </div>
                        </div>
                    </div>
                </div>
                <div class="route_main" #distance_fees>
                    <p style="color:#fff;">Distance & Fees</p>
                    <div class="router_option">
                        <div class="route_timer_box">
                            <div class="timer_box">
                                <p class="timer_txt_t">Total Km</p>
                                <p class="f_sz25">0.800</p>
                            </div>
                        </div>
                        <div class="route_timer_box">
                            <div class="timer_box">
                                <p class="timer_txt_t">Fare</p>
                                <p class="f_sz25">15</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="map-section" style="margin-top: 0px;" [ngClass]="{'remove-map-section':chevaron_status == false}">
        <app-map-section [chev]="chevaron_status"></app-map-section>
    </div>
</div>