import { Component, OnInit,ViewChild, ElementRef,Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MapsAPILoader } from '@agm/core';
import { Routes, RouterModule,Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomService } from '../../services/custom.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  // contactForm: FormGroup;
  // @ViewChild("contactId") contact:any;
  sendMailForm: any;
  @ViewChild("sendMailId") sendMailId: any;
  // @ViewChild('gmap', { static: true }) mapElement: any;
  // @ViewChild('route_bus', { static: true }) routeBus: any;
  // @ViewChild('route_taxi', { static: true }) routeTaxi: any;
  // @ViewChild('route_train', { static: true }) routeTrain: any;
  // @ViewChild('route_ferry', { static: true }) routeFerry: any;
  // @ViewChild('route_bike', { static: true }) routeBike: any;
  // @ViewChild('route_walk', { static: true }) routeWalk: any;
  // @ViewChild('route_auto', { static: true }) routeAuto: any;
  // @ViewChild('route_main', { static: true }) routeMain: any;
  // @ViewChild('distance_fees', { static: true }) distanceFees: any;
  map: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  // ,private mapsAPILoader: MapsAPILoader
  constructor(public translate: TranslateService,el: ElementRef, private formBuilder: FormBuilder, public Service: CustomService, private renderer: Renderer2,private router: Router) { 
    
  }
  ngOnInit(): void {
    this.formValidation();
  }

  formValidation() {
    this.sendMailForm = this.formBuilder.group({
      mailerName: ['',Validators.required],
      mailerEmail: ['',Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      mailercontactNumber: [''],
      mailerPurpose: [''],
      mailerMessage: ['',Validators.required]
    });
  }
  // formValidation(){
  //   this.contactForm = this.formBuilder.group({
  //     AccYr_Id: [''],
  //   })
  // }
  // transit_show:boolean = false;
  // transit_status:boolean = false;
  // transitToggle(){
  //   this.transit_show  = !this.transit_show
  // }
  
  chevaron_status:boolean = true;
  chevaronMin(type:any){
    if(type=='min'){
      this.chevaron_status = false;
    }
    else{
      this.chevaron_status = true;
    }
  }

  sendMail() {
   
    if (this.sendMailForm.valid) {
      const httpOptions = {
        headers: { 'Authorization': this.Service.authorization_token },
        params: this.sendMailForm.value
      };
      let Data = { 'url': this.Service.BASE_URL + 'sendFeedback', 'header': httpOptions }
      this.Service.SendMailApiService(Data, (result: any): void => {
        this.Service.AlertSuccess(result.message);
        this.sendMailId.resetForm();
        // this.sendMailId.markAsUntouched();
        // this.sendMailId.updateValueAndValidity();

      })
    }
  }
}
