import { Component, OnInit, ViewChild, Inject, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MapSectionComponent } from '../map-section/map-section.component';
import { CustomService } from '../../services/custom.service';
declare var $: any;
import Swiper, { SwiperOptions } from 'swiper/core';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
} from "swiper/core";
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';

// import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from '@angular/common/http';
// import {ProgressSpinnerMode} from '@angular/material/progress-spinner';

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService],
})

export class EventsComponent implements OnInit {
  // width:any =200;
  @ViewChild('newSwiper') newSwiper: any;
  // mode: ProgressSpinnerMode = 'indeterminate';
  // value = 50;
  config: SwiperOptions = {
    // pagination: { el: '.swiper-pagination', clickable: true },
    // pagination: { clickable: true },
    initialSlide: 0, // Slide Index Starting from 0
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      360: {
        slidesPerView: 1,
        spaceBetween: 6,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 6,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 6,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 6,
      },
    },
    // spaceBetween: 30
  };


  showgrid: boolean = true;
  showgrid_status: number = 0;
  @ViewChild(MapSectionComponent) mapchild: any;
  event_list: any = [];
  origin: any;
  destination: any;
  markerIcon: any;
  qr_back_btn: boolean = true;
  title: any;
  url1: any;
  slides: string[];
  panelOpenState = false;
  searchInfo: any;
  isImgZoom:boolean = false
  constructor(public http: HttpClient, private spinner: NgxSpinnerService, public Service: CustomService, private router: Router, private el: ElementRef, @Inject(DOCUMENT) document: any) {

    this.slides = Array.from({ length: 1000 }).map(
      (el, index) => `Slide ${index + 1}`
    );

    if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
      this.spinner.show();
      this.showgrid = false;
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.searchInfo = this.router.getCurrentNavigation()?.extras.state;
      this.isImgZoom = false;
      // this.isPandemic = val.data == "pandemic" ? true : false;
       
       setTimeout(() => {
         if (this.searchInfo.data.type === "search") {
          
           let sinle_places = this.Service.eventDetails.filter((x: any) => x.id == this.searchInfo.data.id)[0];
           if(sinle_places != undefined){ 
             
             this.eventsDetail(sinle_places);
           }else{
             // this.Service.AlertWarning("Route is not available");
           }          
         }
       }, 6000);
     }

  }
  bus_route_list: any = [{ id: 1, eta: 'ETA', timing: '8.30', noon: 'am', place: 'Aluva Bypass,Padma' }, { id: 2, eta: 'ETA', timing: '9.30', noon: 'am', place: 'Aluva Bypass,Padma' }, { id: 3, eta: 'ETA', timing: '12.30', noon: 'pm', place: 'Aluva Bypass,Padma' }]
  ngOnInit() {

    this.markerIcon = 'assets/images/icon_general.png';
    setTimeout(() => {
      // if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
      //   if (history.state.data == 'mainevent') {
      //     this.getEventsfns('main')
      //   }
      //   else {
      //     this.showgrid = history.state.data != '' && history.state.data != null && history.state.data != undefined ? false : true;
      //     let sinle_event = this.Service.eventDetails.filter((x: any) => x.id == history.state.data.id)[0];
      //     this.eventsDetail(sinle_event, '')
      //   }
      // }

      // else if(){
      //   this.Service.eventsDetailsFns();
      // }

      this.getApis()
    }, 500);
    // this.title = this.Service.mainLayer
  }
  //CONFIG API CALL
  getApis() {
    this.Service.GetApiUrl().subscribe((data: any) => {
      this.Service.BASE_URL = data.BASE_URL;
      this.eventsDetailsFns();
      // this.getRoleDataFns();
    })
  }
  events_details: boolean = false;
  chevaron_status: boolean = true;
  chevaronMin(type: any) {
    if (type == 'min') {
      this.chevaron_status = false;
    }
    else {
      this.chevaron_status = true;
    }
  }

  eventsDetailsFns() {
    this.spinner.show();
    this.Service.eventDetails = [];
    this.Service.eventAllDetails = [];
    var getAllDetails = [];
    var eventDetailsItems = [];
    var httpOptions;
    if (this.Service.main_layer_url != '' && this.Service.main_layer_url != null && this.Service.main_layer_url != undefined || this.Service.main_url == 'home') {
      httpOptions = {
        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
        params: { 'level': 'med', 'scale': Math.ceil(this.Service.zoom), 'lat': this.Service.current_lat, 'lon': this.Service.current_lng, 'lc': this.Service.lc }
      };
    }
    else {
      httpOptions = {
        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
        params: { 'level': 'med', 'scale': this.Service.zoom, 'lat': this.Service.current_lat, 'lon': this.Service.current_lng }
      };
    }

    let Data = { 'url': this.Service.BASE_URL + 'events', 'header': httpOptions }
    this.Service.GetApiService(Data, (result: any): void => {
      // if(history.state.data==''||history.state.data==null||history.state.data==undefined){
      this.spinner.hide();
      // }
      eventDetailsItems = result;
      eventDetailsItems.forEach((element: any, index: any) => {
        // element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/event/' + element.imageId : 'assets/images/exko.jpg';
        let Data = this.Service.IMG_URL + 'images/event/' + element.imageId
        this.http.get(Data).subscribe((res: any) => {
          element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/event/' + element.imageId : 'assets/images/noImage.png'
        }, err => {
          if (err.status == 404) {
            element.imageId = 'assets/images/noImage.png';
          } else {
            element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/event/' + element.imageId : 'assets/images/noImage.png'
          }

        })
      });

      if (this.Service.main_layer_url != '' && this.Service.main_layer_url != null && this.Service.main_layer_url != undefined) {
        this.Service.eventDetails = eventDetailsItems;

        this.Service.mainLayer = this.Service.expkoMenuList.filter((x: any) => x.code == this.Service.main_layer_url).map((x: any) => x.name)[0];
      }
      else {
        getAllDetails = eventDetailsItems;
        this.Service.eventAllDetails = [];
        for (var i = 0; i < this.Service.expkoMenuList.length; i++) {
          let x = getAllDetails.filter((x: any) => x.layerCode == this.Service.expkoMenuList[i].code);
          let y = { name: this.Service.expkoMenuList[i].name, array_data: x }
          this.Service.eventAllDetails.push(y);
        }
      }
      if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
        // let layer_name = this.Service.expkoMenuList.filter((x: any) => x.code == history.state.data.layerCode)[0].name; 
        this.eventsDetail(history.state.data)
      }
    })

  }

  eventsDetail(data: any) {
    this.spinner.show();
    let layername: any = this.Service.expkoMenuList.filter((x: any) => x.code == data.layerCode)[0].name;
    // this.Service.mainLayer = layername;
    if (layername !== '' && layername !== undefined && layername !== null) {
      this.Service.mainLayer = layername;
    }
    this.Service.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
    const httpOptions = {
      // data.id
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'id': data.id }
    };
    let Data = { 'url': this.Service.BASE_URL + 'event', 'header': httpOptions }
    this.Service.GetApiService(Data, (result: any): void => {
      this.spinner.hide();
      // result.icon_img = 'assets/images/icons/clinic.png';
      if (history.state.data != null && history.state.data != undefined && history.state.data != '') {
        this.qr_back_btn = history.state.data.type_status == 'qrcode' ? false : true;
      }
      this.events_details = true;
      this.showgrid = false;
      if (result.images.length > 0) {
        for (var i = 0; i < result.images.length; i++) {
          result.images[i] = data.imageId;

          /* let Data = this.Service.IMG_URL + 'images/event/' + result.images[i] 
          this.http.get(Data).subscribe((res:any)=>{ 
            result.images[i] = result.images[i] != null && result.images[i] != undefined && result.images[i] != '' ? this.Service.IMG_URL + 'images/event/' + result.images[i] : 'assets/images/noimage.jpg'
 
          },err =>{
            if(err.status == 404){
              result.images[i] = 'assets/images/noImage.png';
            }else{
              result.images[i] = result.images[i] != null && result.images[i] != undefined && result.images[i] != '' ? this.Service.IMG_URL + 'images/event/' + result.images[i] : 'assets/images/noimage.jpg'
            } 
          }) */
        }
      } else {
        let img = 'assets/images/noimage.jpg';
        result.images.push(img)
      }
      this.event_list = result;
      this.showgrid_status = 1;
      setTimeout(() => {
        this.origin = { lat: 9.9630317, lng: 76.2431732 };
        data.iconId = '';
        if (history.state.data != null && history.state.data != undefined && history.state.data != '') {
          if (history.state.data.type_status == 'qrcode') {
            let icon = "assets/images/flag.png"
            let destination = { lat: data.lat, lng: data.lon };
            this.mapchild.makeDestMarker(destination, icon, this.mapchild.map);
          } else {

            this.mapchild.mapDirectionView(this.origin, this.event_list);
          }
        } else {
          this.mapchild.mapDirectionView(this.origin, this.event_list);
        }

      }, 1000);
    })
  }

  eventsDetailClose() {

    this.events_details = false;
    this.showgrid = true;

  }
  getEventsfns(event: any) {


    this.showgrid = true;

    this.Service.lc = this.Service.main_layer_url;
    this.eventsDetailsFns();
  }

  zoomImage(type: any) {
    if(type =='add'){
      this.isImgZoom = true;
     $("#demo .item-1 img").addClass("zoom_img");
     // $("#demo .item-1 i").addClass("remove_show_emerg")
    }
    if(type =='remove'){
     this.isImgZoom = false;
       $("#demo .item-1 img").removeClass("zoom_img");
       // $("#demo .item-1 i").removeClass("remove_show_emerg")
     }
  }
  knowMoreFns(eventInfo: any) {
    eventInfo.type = 'viewall'
    // let x = this.Service.expkoMenuList.filter((x: any) => x.name == data)[0]; 
    this.url1 = 'exko/' + eventInfo.layerCode;
    this.router.navigate(['/exko/' + eventInfo.layerCode], { replaceUrl: true });
    // if (x != null && x != undefined && x != '') {
    // this.router.navigate(['/' + x.type + '/' + x.code],{state:{data:data}})
    // }
  }
  // DownloadQRCode(){
  //   // let value = 'https://kochiithile.com/#/home/1';
  //   // debugger;
  //   const qrcodeDiv12 = document.getElementById('qr_code') as HTMLElement; 
  //   const myimg1:any = qrcodeDiv12.children[0].children[0].children[0]; 
  //   const linkSource = myimg1.src;
  //    const downloadLink = document.createElement("a");
  //         const fileName = "home.png";

  //         downloadLink.href = linkSource;
  //         downloadLink.download = fileName;
  //         downloadLink.click();
  // }
}
