import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit, NgZone, Renderer2 } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MapSectionComponent } from '../map-section/map-section.component';
import { CustomService } from '../../services/custom.service';
declare var google: any;
import { SwiperOptions } from 'swiper';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild(MapSectionComponent) mapchild: any;
  event_show: boolean = false;
  maxiHeight: boolean = false;
  back_btn: boolean = false;
  qr_back_btn: boolean = true;
  allmarkerset: any = [];
  markers: any = [];
  markerIcon: any;
  main_layer_url = "";
  sub_layer_url = "";
  config: SwiperOptions = {
    pagination: { clickable: true },
    initialSlide: 3, // Slide Index Starting from 0
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  };
  events_details: boolean = false;
  event_status: boolean = false;
  geoCoder: any;
  // ElementRef<HTMLInputElement>  
  // latitude: any;
  // longitude: any;
  latitude1: any;
  longitude1: any;
  zoom: any;
  address: any;
  origin: any;
  destination: any;
  @ViewChild('gmap', { static: false }) gmap: any;
  map: any;
  public current_url: string = "";
  icon_url: any;
  // longtitude: any;
  placesList: any = [];
  current_lat: any;
  current_lng: any;
  show_map: boolean = true;
  // locationDetails:any;
  // @ViewChild('newSwiper') swiperDirectiveRef: any;
  @ViewChild('gmap') gmapchild: any;
  layername: any;
  cookieValue: any;
  cookieLayerName: any;
  url1: any;
  // loadAPI: Promise<any>;
  constructor(@Inject(DOCUMENT) private _document: Document, private renderer: Renderer2, public cookieService: CookieService, public Service: CustomService, private router: Router, private route: ActivatedRoute, private el: ElementRef, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, @Inject(DOCUMENT) document: any) {
    this.current_url = this.router.url;
    this.url1 = this.router.url;

    this.Service.main_url = this.url1.split('/')[1];
    this.Service.main_layer_url = this.url1.split('/')[2];
    this.Service.sub_layer_url = this.url1.split('/')[3];
    this.Service.current_language = localStorage.getItem('current_language');

  }

  ngOnInit() {
    this.icon_url = 'assets/images/marker-icon.png';
    if (this.current_url == '/home') {
      this.event_status = false;
    }
    else {
      this.event_status = true;
    }
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      console.log(result.state);
      if(result.state === "granted" || result.state === "prompt"){
            if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {

        let latitude = position.coords.latitude.toFixed(6);
        let langitude = position.coords.longitude.toFixed(6)

        this.origin = { lat: Number(latitude), lng: Number(langitude) };
        this.Service.current_lat = Number(latitude);
        this.Service.current_lng = Number(langitude);
      },err=>{
        console.log("err",err);
       
        this.origin = { lat: 9.9612714, lng: 76.2457904 };
        this.Service.current_lat = 9.953000;
        this.Service.current_lng = 76.240000;
      });
    } else {
      this.origin = { lat: 9.9612714, lng: 76.2457904 };
      this.Service.current_lat = 9.953000;
      this.Service.current_lng = 76.240000;
    }
      } else{
        this.origin = { lat: 9.9612714, lng: 76.2457904 };
        this.Service.current_lat = 9.953000;
        this.Service.current_lng = 76.240000;
      }
    });




    this.Service.zoom = 16;
    // this.Service.lc = 'evnt';
    // if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
    //   window.location.reload();
    // }

    setTimeout(() => {
      this.getApis();
    }, 1000);
  }
  //CONFIG API CALL
  getApis() {
    this.Service.GetApiUrl().subscribe((data: any) => {
      this.Service.BASE_URL = data.BASE_URL;
      this.loadMap();
      // this.wafiPlacesGetWithLayer();
      this.placesGetWithoutLayer();
    })
  }

  lc: any;
  marker: any;
  directionsService: any;
  directionsRenderer: any;
  loadMap() {
    


    var mapOptions = {
      zoom: this.Service.zoom,
      center: this.origin,
      mapId: '7ab97996c3d8635c',
      zoomControl: false,
    }
    this.map = new google.maps.Map(document.getElementById('gmap') as HTMLElement, mapOptions);
    // this.map = new google.maps.Map(this.el.nativeElement.gmapchild, mapOptions);
    var zoomDiv = document.createElement('div');
    zoomDiv.style.margin = '9px 10px 0px 0px';
    zoomDiv.style.cursor = 'pointer';
    // zoomDiv.style.opacity = "0.8";
    zoomDiv.style.backgroundColor = "#FFFFFF";
    zoomDiv.style.fontFamily = 'Open Sans';
    zoomDiv.style.borderRadius = '3px';
    zoomDiv.style.height = '72px';
    zoomDiv.style.width = '42px';
    var zoomout = document.createElement('div');
    zoomout.title = 'Click to zoom out';
    zoomout.style.width = '100%';
    zoomout.style.height = '50%';
    zoomout.style.borderBottom = "1px solid rgb(158, 158, 158)";
    zoomDiv.appendChild(zoomout);
    var zoomoutText = document.createElement('div');
    zoomoutText.innerHTML = '<i class="fa fa-minus" style="line-height:36px" aria-hidden="true"></i>';
    zoomoutText.style.fontSize = '18px';
    zoomoutText.style.textAlign = 'center';
    zoomoutText.style.color = "#9e9e9e";
    zoomout.appendChild(zoomoutText);
    var zoomin = document.createElement('div');
    zoomin.title = 'Click to zoom in';
    zoomin.style.width = '100%';
    zoomin.style.height = '50%';
    zoomDiv.appendChild(zoomin);
    var zoominText = document.createElement('div');
    zoominText.innerHTML = '<i class="fa fa-plus" style="line-height:36px" aria-hidden="true"></i>';
    zoominText.style.fontSize = '18px';
    zoominText.style.textAlign = 'center';
    zoominText.style.color = "#9e9e9e";
    zoomin.appendChild(zoominText);
    google.maps.event.addDomListener(zoomout, 'click', () => {
      this.Service.zoom = this.map.getZoom() - 1;
      if (this.Service.zoom != 0) {
        this.map.setZoom(this.Service.zoom);
        // this.Service.eventsDetailsFns();
        this.placesGetWithoutLayer();
        //   this.wafiPlacesGetWithLayer();
        //this.wafiPlacesGetWithMainLayer();
        this.createStrokeFns(this.Service.zoom);
      }
    });
    google.maps.event.addDomListener(zoomin, 'click', () => {
      this.Service.zoom = this.map.getZoom() + 1;
      if (this.Service.zoom != 21) {
        this.map.setZoom(this.Service.zoom);
        // this.Service.eventsDetailsFns();
        this.placesGetWithoutLayer();
        // this.wafiPlacesGetWithLayer();
        // this.wafiPlacesGetWithMainLayer();
        this.createStrokeFns(this.Service.zoom);
      }
    });

    google.maps.event.addListener(this.map, 'zoom_changed',  () => { 
      if(Math.ceil(this.map.getZoom()) != Math.ceil(this.Service.zoom)){         
        this.placesGetWithoutLayer();
        this.createStrokeFns(this.Service.zoom);
      } 
      this.Service.zoom = Math.ceil(this.map.getZoom());
    });
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomDiv);
    this.marker = new google.maps.Marker({
      position: this.origin,
      title: "im here!",
    });
    this.marker.setMap(this.map);
    var radius_circle = new google.maps.Circle({
      center: this.origin,
      radius: 1 * 1000,
      strokeColor: "#919191",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FFFFFF",
      fillOpacity: 0,
      clickable: false,
      map: this.map
    });
    // this.createStrokeFns1();
    //this.createStrokeFns(this.Service.zoom);
  }
  event_list: any = []
  eventsDetail(data: any) {
    this.router.navigate(['/exko/evnt'])
  }
  chevaron_status: boolean = true;
  chevaronMin(type: any) {
    if (type == 'min') {
      this.chevaron_status = false;
    }
    else {
      this.chevaron_status = true;
    }
  }
  wafiPlacesDetailsClose() {
    this.Service.placesList.forEach(function (element: any) {
      element.showstatus = true;
    });
    this.Service.zoom = 15;
    setTimeout(() => {
      if (this.Service.placesList.length > 0) {
        this.mapchild.clearMarkers();
      }
      if (this.Service.placesList.length > 0 && this.Service.sub_layer_url == '') {
        this.mapchild.markerMultiSet(this.Service.placesList);
      }
      else {
        this.mapchild.markerSet(this.allmarkerset, this.markerIcon);
      }
    }, 100);
  }
  wafiPlacesDetails(data: any) {
    this.Service.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
    const httpOptions = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'id': data.id }
    };
    let Data = { 'url': this.Service.BASE_URL + 'place', 'header': httpOptions }
    this.Service.GetApiService(Data, (result: any): void => {
      if (result.images.length > 0) {
        for (var i = 0; i < result.images.length; i++) {
          result.images[i] = this.Service.IMG_URL + 'images/place/' + result.images[i];
        }
      }
      else {
        let img = 'assets/images/wafi.jpg';
        result.images.push(img)
      }
      if (history.state.data != null && history.state.data != undefined && history.state.data != '') {
        this.qr_back_btn = history.state.data.type_status == 'qrcode' ? false : true;
      }
      // else{
      //   this.back_btn = true;
      // }
      this.back_btn = true;
      this.Service.placesList.forEach(function (element: any) {
        if (element.id != data.id) {
          element.showstatus = false;
        }
        else {
          element.details = result;
          element.details.imageId = element.imageId
          element.details.iconId = element.iconId
        }
      });
      // let dd = this.Service.placesList;
      if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
        data = this.Service.placesList.filter((x: any) => x.id == history.state.data.id)[0];
      }
      //this.placesList_Single = data;
      setTimeout(() => {
        this.origin = { lat: 9.9630317, lng: 76.2431732 };
        if (history.state.data != null && history.state.data != undefined && history.state.data != '') {
          if (history.state.data.type_status == 'qrcode') {
            let icon = "assets/images/flag.png"
            let destination = { lat: data.lat, lng: data.lon };
            this.mapchild.makeDestMarker(destination, icon, this.mapchild.map);
          }
          else {
            this.mapDirectionView(this.origin, data.details);
          }
        }
        else {
          this.mapDirectionView(this.origin, data.details);
        }
      }, 1000);
    })
  }
  placesGetWithoutLayer() {
    // let lang_name = localStorage.getItem('language_name');
    
    this.Service.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
    const httpOptions = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'level': 'summary', 'scale': this.Service.zoom, 'lat': this.Service.current_lat, 'lon': this.Service.current_lng }
    };
    let Data = { 'url': this.Service.BASE_URL + 'places', 'header': httpOptions }
    this.Service.GetApiService(Data, (result: any): void => {
      this.placesList = result;
      this.placesList.forEach((element: any) => {
        if (this.Service.current_language === 'ML') {
          if (element.layerCode === "trns") {
            element.layerCode = "trml";
            if (element.subLayerCode === "aust") {
              element.subLayerCode = "auml";
            }
            if (element.subLayerCode === "bcds") {
              element.subLayerCode = "bcml";
            }
            if (element.subLayerCode === "bcrp") {
              element.subLayerCode = "bdml";
            }
            if (element.subLayerCode === "boje") {
              element.subLayerCode = "boml";
            }
            if (element.subLayerCode === "bstn") {
              element.subLayerCode = "bsml";
            }
            if (element.subLayerCode === "mstn") {
              element.subLayerCode = "msml";
            }
            if (element.subLayerCode === "pafa") {
              element.subLayerCode = "paml";
            }
            if (element.subLayerCode === "rstn") {
              element.subLayerCode = "rsml";
            }
            if (element.subLayerCode === "txst") {
              element.subLayerCode = "txml";
            }
          }
          if (element.layerCode === "tour") {
            element.layerCode = "toml";
            if (element.subLayerCode === "acco") {
              element.subLayerCode = "acml";
            }
            if (element.subLayerCode === "acni") {
              element.subLayerCode = "niml";
            }
            if (element.subLayerCode === "ahlm") {
              element.subLayerCode = "ahml";
            }
            if (element.subLayerCode === "bvof") {
              element.subLayerCode = "bvml";
            }
            if (element.subLayerCode === "food") {
              element.subLayerCode = "foml";
            }
            if (element.subLayerCode === "hert") {
              element.subLayerCode = "heml";
            }
            if (element.subLayerCode === "shop") {
              element.subLayerCode = "shml";
            }
            if (element.subLayerCode === "tinf") {
              element.subLayerCode = "icml";
            }
            if (element.subLayerCode === "vvps") {
              element.subLayerCode = "vvml";
            }
          }
          if (element.layerCode === "recr") {
            element.layerCode = "reml";
            if (element.subLayerCode === "auch") {
              element.subLayerCode = "adml";
            }
            if (element.subLayerCode === "beac") {
              element.subLayerCode = "beml";
            }
            if (element.subLayerCode === "coce") {
              element.subLayerCode = "ccml";
            }
            if (element.subLayerCode === "grnd") {
              element.subLayerCode = "grml";
            }
            if (element.subLayerCode === "papl") {
              element.subLayerCode = "pkml";
            }
            if (element.subLayerCode === "thci") {
              element.subLayerCode = "ctml";
            }
          }
          if (element.layerCode === "emrg") {
            element.layerCode = "emml";
            if (element.subLayerCode === "clnc") {
              element.subLayerCode = "clml";
            }
            if (element.subLayerCode === "eaps") {
              element.subLayerCode = "eaml";
            }
            if (element.subLayerCode === "fisn") {
              element.subLayerCode = "fsml";
            }
            if (element.subLayerCode === "hosp") {
              element.subLayerCode = "homl";
            }
            if (element.subLayerCode === "phmy") {
              element.subLayerCode = "phml";
            }

          }
          if (element.layerCode === "educ") {
            element.layerCode = "edml";
            if (element.subLayerCode === "colg") {
              element.subLayerCode = "coml";
            }
            if (element.subLayerCode === "libr") {
              element.subLayerCode = "lbml";
            }
            if (element.subLayerCode === "schl") {
              element.subLayerCode = "scml";
            }
          }
          if (element.layerCode === "admn") {
            element.layerCode = "asml";
            if (element.subLayerCode === "cnts") {
              element.subLayerCode = "goml";
            }
          }
          if (element.layerCode === "relg") {
            element.layerCode = "wsml";
            if (element.subLayerCode === "chur") {
              element.subLayerCode = "chml";
            }
            if (element.subLayerCode === "mosq") {
              element.subLayerCode = "moml";
            }
            if (element.subLayerCode === "othe") {
              element.subLayerCode = "otml";
            }
            if (element.subLayerCode === "syng") {
              element.subLayerCode = "syml";
            }
            if (element.subLayerCode === "tmpl") {
              element.subLayerCode = "tmml";
            }

          }
          if (element.layerCode === "othr") {
            element.layerCode = "otml";
            if (element.subLayerCode === "atms") {
              element.subLayerCode = "atml";
            }
            if (element.subLayerCode === "baca") {
              element.subLayerCode = "bbml";
            }
            if (element.subLayerCode === "bank") {
              element.subLayerCode = "bkml";
            }
            if (element.subLayerCode === "drwa") {
              element.subLayerCode = "dwml";
            }
            if (element.subLayerCode === "fwif") {
              element.subLayerCode = "fwml";
            }
            if (element.subLayerCode === "popb") {
              element.subLayerCode = "poml";
            }
            if (element.subLayerCode === "ptrs") {
              element.subLayerCode = "ptml";
            }
            if (element.subLayerCode === "toil") {
              element.subLayerCode = "toml";
            }
          }
          if (element.layerCode === "C019") {
            element.layerCode = "C0ML";
            if (element.subLayerCode === "trce") {
              element.subLayerCode = "teml";
            }
            if (element.subLayerCode === "TSTC") {
              element.subLayerCode = "tcml";
            }
            if (element.subLayerCode === "VACC") {
              element.subLayerCode = "vcml";
            }
          }
        }

        //  
        let ic_filt = this.Service.wayfiMenuList.filter((x: any) => x.code == element.layerCode)[0].subLayers.filter((x: any) => x.code == element.subLayerCode);
        // 

        if (ic_filt.length > 0) {
          element.iconId = this.Service.IMG_URL + 'images/sublayer/' + ic_filt[0].iconId
        }
        else {
          element.iconId = 'assets/images/icon_general.png'
        }
      });

      if (this.placesList.length > 0) {

        var list: any = []

        if (this.Service.zoom >= 16) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 0);

        }
        if (this.Service.zoom == 15) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 4);
        }
        if (this.Service.zoom == 14) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 6);
        }
        if (this.Service.zoom == 13) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 8);
        }
        if (this.Service.zoom == 12) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 10);
        }
        if (this.Service.zoom == 11) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 12);
        }
        if (this.Service.zoom == 10) { 
          list = this.Service.getDistanceFromLatLonInKm(this.placesList, 14);
        }
        this.hideMarkers(list); 
        this.markerSet(list, '');
      }
    })
  }

  public hideMarkers(list: any): void {
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(null);
    }
    this.markerSet(list, 'hidemarker');
    // this.loadMap();
  }

  // Shows any markers currently in the array.
  public showMarkers(list: any): void {
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(this.map);
    }
    // this.loadMap();
    this.markerSet(list, 'showmarker');
  }
  // wafiPlacesGetWithMainLayer() {

  //   // let cookieValue = this.cookieService.get("cur_layer"); 

  //   // let url_split = cookieValue.split('/')   
  //   // this.Service.main_url = url_split[1];
  //   // this.Service.main_layer_url = url_split[2]; 
  //   this.show_map == false;
  //   this.allmarkerset = [];
  //   this.Service.placesList = [];
  //   this.current_lat = 9.953000;
  //   this.current_lng = 76.240000; 
  //   this.Service.current_language = localStorage.getItem('current_language') !=null && localStorage.getItem('current_language') !=undefined && localStorage.getItem('current_language') !=''?localStorage.getItem('current_language'):'EN';

  //   const httpOptions = {
  //     headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
  //     params: { 'level': 'summary', 'scale': this.Service.zoom, 'lat': this.current_lat, 'lon': this.current_lng, 'layer': this.Service.main_layer_url }
  //   };
  //   let Data = { 'url': this.Service.BASE_URL + 'places', 'header': httpOptions }
  //   this.Service.GetApiService(Data, (result: any): void => {
  //     this.Service.placesList = result;
  //     this.Service.placesList.forEach((element: any, index: any) => {
  //       let ic_filt = this.Service.wayfiMenuList.filter((x: any) => x.code == element.layerCode)[0].subLayers.filter((x: any) => x.code == element.subLayerCode);
  //       if (ic_filt.length > 0) {
  //         element.iconId = this.Service.IMG_URL+'images/sublayer/' + ic_filt[0].iconId
  //       }
  //       else {
  //         element.iconId = 'assets/images/icon_general.png'
  //       } 
  //       element.showstatus = true;
  //       element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL+'images/place/' + element.imageId : 'assets/images/wafi.jpg';
  //       if (element.schedules != '' && element.schedules != null && element.schedules != undefined) {
  //         element.schedules[0].opentime = element.schedules[0].open.substring(0, 2) < 12 ? element.schedules[0].open.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].open.substring(0, 2) - 12 + ':' + element.schedules[0].open.substring(2, 4) + 'pm';
  //         element.schedules[0].closetime = element.schedules[0].close.substring(0, 2) < 12 ? element.schedules[0].close.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].close.substring(0, 2) - 12 + ':' + element.schedules[0].close.substring(2, 4) + 'pm';

  //         let day = element.schedules[0].day != '' && element.schedules[0].day != null && element.schedules[0].day != undefined ? this.Service.scheduleList.filter((x: any) => x.code == element.schedules[0].day).map((x: any) => x.name)[0] : '';
  //         element.schedules[0].dayto = day;
  //         var time = new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
  //         element.openstatus = time < element.schedules[0].closetime && time > element.schedules[0].opentime ? 'Open' : 'Close';
  //       }

  //     }); 
  //     if (this.Service.placesList.length > 0) {
  //       this.mapchild.markerMultiSet(this.Service.placesList);
  //     }
  //     else {
  //       setTimeout(() => {
  //         this.loadMap();
  //       }, 100);
  //     }
  //   }) 
  // } 
  //  wafiPlacesGetWithLayer() {  
  //    var  httpOptions:any; 
  //    this.current_lat = 9.953000;
  //    this.current_lng = 76.240000;
  //   // let url_split = this.current_url.split('/')  
  //    //if(url_split[1] === "wafi" ){
  //      this.layername = "wafi";
  //   //  this.main_layer_url = url_split[1];
  //    /* if(url_split[3])
  //    {
  //       this.sub_layer_url = url_split[3];
  //       httpOptions = {
  //        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
  //        params: { 'level': 'med', 'scale': this.Service.zoom, 'lat': this.current_lat, 'lon': this.current_lng, 'layer': this.main_layer_url, 'sublayer': this.sub_layer_url }
  //      };
  //    }
  //    else{ */
  //       httpOptions = {
  //        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
  //        params: { 'level': 'summary', 'scale': this.Service.zoom, 'lat': this.current_lat, 'lon': this.current_lng, 'layer':this.Service.main_url }
  //      };
  //    //} 
  //    this.show_map == false;
  //    this.allmarkerset = [];

  //    // this.Service.zoom = 16;
  //    this.Service.current_language = localStorage.getItem('current_language') !=null && localStorage.getItem('current_language') !=undefined && localStorage.getItem('current_language') !=''?localStorage.getItem('current_language'):'EN';

  //    for (var i = 0; i < this.Service.wayfiMenuList.length; i++) {
  //      if (this.Service.wayfiMenuList[i].subLayers != undefined && this.Service.wayfiMenuList[i].subLayers != null && this.Service.wayfiMenuList[i].subLayers.length > 0 && this.Service.wayfiMenuList[i].subLayers != '') {
  //        this.Service.subLayer = this.Service.wayfiMenuList[i].subLayers.filter((x: any) => x.code == this.Service.sub_layer_url).map((x: any) => x.name)[0];
  //        if (this.Service.subLayer != null && this.Service.subLayer != '' && this.Service.subLayer != undefined) {
  //          break;
  //        }
  //      }
  //    }
  //    // this.zoom = 16;

  //    let Data = { 'url': this.Service.BASE_URL + 'places', 'header': httpOptions }
  //    this.Service.GetApiService(Data, (result: any): void => {
  //      result.forEach((element: any, index: any) => {
  //        if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
  //          if (element.id == history.state.data.id) {
  //                element.showstatus = true;
  //              }
  //              else{
  //                element.showstatus = false;
  //              }
  //        }
  //        else{
  //          element.showstatus = true;
  //        }
  //      }) 
  //      this.Service.placesList = result; 
  //    /*   let ic_filt = this.Service.wayfiMenuList.filter((x: any) => x.code == this.main_layer_url)[0].subLayers; */

  //     /*  this.markerIcon = this.Service.IMG_URL+'images/sublayer/' + ic_filt.filter((x: any) => x.code ==  this.sub_layer_url).iconId; */


  //      this.Service.placesList.forEach((element: any, index: any) => {
  //        this.allmarkerset.push({ lat: element.lat, lng: element.lon })
  //        let ic_filt = this.Service.wayfiMenuList.filter((x: any) => x.code == element.layerCode)[0].subLayers.filter((x: any) => x.code == element.subLayerCode);
  //       
  //         if (ic_filt.length > 0) {
  //          //element.iconId = this.Service.IMG_URL+'images/sublayer/' + ic_filt[0].iconId
  //           //element.iconId = ic_filt[0].iconId
  //           element.iconId = this.Service.IMG_URL+'images/sublayer/' + ic_filt[0].iconId;
  //         }
  //         else {
  //           element.iconId = 'assets/images/icon_general.png'
  //         } 
  //         element.showstatus = true;

  //        //element.iconId = this.markerIcon;
  //        element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL+'images/place/' + element.imageId : 'assets/images/wafi.jpg';
  //        if (element.schedules != '' && element.schedules != null && element.schedules != undefined) {
  //          element.schedules[0].opentime = element.schedules[0].open.substring(0, 2) < 12 ? element.schedules[0].open.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].open.substring(0, 2) - 12 + ':' + element.schedules[0].open.substring(2, 4) + 'pm';
  //          element.schedules[0].closetime = element.schedules[0].close.substring(0, 2) < 12 ? element.schedules[0].close.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].close.substring(0, 2) - 12 + ':' + element.schedules[0].close.substring(2, 4) + 'pm';

  //          let day = element.schedules[0].day != '' && element.schedules[0].day != null && element.schedules[0].day != undefined ? this.Service.scheduleList.filter((x: any) => x.code == element.schedules[0].day).map((x: any) => x.name)[0] : '';
  //          element.schedules[0].dayto = day;
  //          var time = new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
  //          element.openstatus = time < element.schedules[0].closetime && time > element.schedules[0].opentime ? 'Open' : 'Close';
  //        }

  //      });if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
  //        let sinle_places = this.Service.placesList.filter((x: any) => x.id == history.state.data.id)[0];
  //        this.wafiPlacesDetails(sinle_places);
  //      }
  //      if (this.Service.placesList.length > 0 && (history.state.data == '' || history.state.data == null || history.state.data == undefined)) {
  //       // this.mapchild.markerMultiSet(this.Service.placesList);
  //         //this.markerSet(this.allmarkerset, this.markerIcon);
  //      }
  //      else {
  //        setTimeout(() => {
  //          this.loadMap();
  //        }, 100);
  //      }
  //    })

  //   //}
  //  /*  if(url_split[1] === "exko")
  //    {
  //      this.Service.eventsDetailsFns() 
  //      this.layername = "exko";
  //      this.Service.current_lat = 9.953000;
  //      this.Service.current_lng = 76.240000;
  //      this.Service.zoom = 16; 
  //      this.Service.lc =this.Service.main_layer_url;
  //      this.markerIcon = 'assets/images/icon_general';
  //      if(history.state.data !='' && history.state.data !=null && history.state.data !=undefined){ 
  //        let sinle_event = this.Service.eventDetails.filter((x:any)=>x.id == history.state.data.id)[0];
  //          this.eventsDetails(sinle_event)
  //      }
  //    } */
  //  } 
  /*   eventsDetails(data: any) {
      this.Service.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
      const httpOptions = { 
        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
        params: { 'id': data.id }
      };
      let Data = { 'url': this.Service.BASE_URL + 'event', 'header': httpOptions }
      this.Service.GetApiService(Data, (result: any): void => { 
        if (history.state.data != null && history.state.data != undefined && history.state.data != '') {
          this.qr_back_btn = history.state.data.type_status == 'qrcode' ? false : true;
        }
        this.events_details = true; 
        if (result.images.length > 0) {
          for (var i = 0; i < result.images.length; i++) {
            result.images[i] = this.Service.IMG_URL + 'images/event/' + result.images[i];
          }
        } else {
          let img = 'assets/images/exko.jpg';
          result.images.push(img)
        } 
        this.event_list = result; 
        setTimeout(() => {
          this.origin = { lat: 9.9630317, lng: 76.2431732 };
          data.iconId = '';
          if (history.state.data != null && history.state.data != undefined && history.state.data != '') {
            if (history.state.data.type_status == 'qrcode') {
              let icon = "assets/images/flag.png"
              let destination = { lat: data.lat, lng: data.lon };
              this.mapchild.makeDestMarker(destination, icon, this.mapchild.map);
            } else {
              this.mapDirectionView(this.origin, data);
            }
          } else {
            this.mapDirectionView(this.origin, data);
          }
        }, 1000);
      })
    } */
  heightChange(type: any) {
    // var mapmargin = 50;
    if (type == "min") {
      this.maxiHeight = false;
    }
    else {
      this.maxiHeight = true;
    }
  }
  infowindow: any;
  /*  markerSet(marker: any, markerIcon: any) {
     if (this.directionsRenderer) {
       this.directionsRenderer.setMap(null);
     }
     for (let i = 0; i < this.markers.length; i++) {
       this.markers[i].setMap(null);
     }
     this.markers = [];
     var icons_start = {
       url: markerIcon != '' && markerIcon != null && markerIcon != undefined ? markerIcon : "assets/images/marker-icon.png", // url
       scaledSize: new google.maps.Size(20, 20), // scaled size
     };
     for (var i = 0; i < marker.length; i++) {
       var marke = new google.maps.Marker({
         position: marker[i],
         map: this.map,
         icon: icons_start,
       });
       this.markers.push(marke);
     }
     for (let i = 0; i < this.markers.length; i++) {
       this.markers[i].setMap(this.map);
     }
     // this.Service.zoom = 16;
     this.map.setZoom(this.Service.zoom);
   } */
  markerSet(markerdata: any, type: any) {

    var bounds = new google.maps.LatLngBounds();
    var content = '';
    for (var i = 0; i < markerdata.length; i++) {
      var icons_start = {
        url: markerdata[i].iconId, // url\
        scaledSize: new google.maps.Size(20, 20),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(11, 40),
        labelOrigin: new google.maps.Point(11, 30)
      };
      var infowindow: any;
      var markerLabel = 'GooooooooooooooooO!';
      var icon_label = {
        text: markerdata[i].title,
        color: "black",
        // fontWeight: "bold",
        fontSize: "10px"
      }
      var marker = new google.maps.Marker({
        position: { lat: markerdata[i].lat, lng: markerdata[i].lon },
        map: this.map,
        icon: icons_start,
        label: icon_label,
        title: markerdata[i].title
      });
      infowindow = new google.maps.InfoWindow({ maxWidth: 200 });
      var map = this.map;
      google.maps.event.addListener(marker, 'click', (function (marker, i) {
        return function () {
          var content = "<div class='pop_head'>" + markerdata[i].title + "</div><div class='pop_add'>" + markerdata[i].address.fullAddrText + "</div>"
          infowindow.setContent(content);
          infowindow.open(map, marker);
        }
      })(marker, i));
      marker.setMap(this.map)
      if (type == 'hidemarker') {
        marker.setMap(null);
      }
      this.Service.markers.push(marker);

    }

  }

  // createStrokeFns1(){
  //   var static_street_list = [{
  //     origin:{lat:9.9640384,lon:76.2390787},
  //     destination:{lat:9.9644284,lon:76.2400264},
  //     color:"#ffd666"
  //   },
  //   // {
  //   //   origin:{lat:9.9492001,lng:76.2404252},
  //   //   destination:{lat:9.9602465,lng:76.2407171},
  //   //   color:"#ffd666"
  //   // }
  //   ]
  //   var directionsDisplay = new Array();
  //   for (var i=0; i< static_street_list.length; i++){
  //   var rendererOptions = {
  //       map: this.map,
  //       preserveViewport:true
  //   }
  //   var directionsService = new google.maps.DirectionsService();
  //   var travelMode = google.maps.DirectionsTravelMode.DRIVING;  
  //   var request = {
  //       origin: static_street_list[i].origin,
  //       destination: static_street_list[i].destination,
  //       travelMode: travelMode
  //   };  
  //   directionsService.route({
  //     origin: static_street_list[i].origin,
  //     destination: static_street_list[i].destination,
  //     travelMode: google.maps.TravelMode['DRIVING'],
  //   }, (response:any, status:any) =>{
  //     if (status === 'OK') {
  //       var disp = new google.maps.DirectionsRenderer(rendererOptions);     
  //                 disp.setMap(this.map);
  //                 disp.setDirections(response);
  //     }
  //   })
  // }
  // }
  mapDirectionView(origin: any, data: any) {
    // this.marker.setMap(null);
    var lineSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      fillOpacity: 1,
      scale: 3
    };
    var arrowSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      fillOpacity: 1,
      scale: 5
    };
    var polylineDotted = {
      strokeColor: '#02205b',
      strokeOpacity: 0,
      fillOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '10px'
      },
      {
        icon: arrowSymbol,
        offset: '30%',
        repeat: '200px'
      }
      ],
    };
    var rendererOptions = {
      map: this.map,
      suppressMarkers: true,
      polylineOptions: polylineDotted
    };
    const symbolOne = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      strokeColor: "#F00",
      // fillColor: "#F00",
      fillOpacity: 1,
    };
    const stepDisplay = new google.maps.InfoWindow();
    var directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions);
    // let directionstatus:any='';
    directionsService.route({
      origin: origin,
      destination: { lat: data.lat, lng: data.lon },
      travelMode: google.maps.TravelMode['DRIVING'],
    }, (response: any, status: any) => {
      if (status === 'OK') {
        // directionstatus = '';
        this.directionsRenderer.setDirections(response);
        // var polyline = new google.maps.Polyline({
        //   // path: [],
        //   icons: [
        //     {
        //       icon: symbolOne,
        //       offset: "10%",
        //       repeat: '100px'
        //     },
        //   ],
        //   map: this.map,
        // });
        // var bounds = new google.maps.LatLngBounds(); 
        // var legs = response.routes[0].legs;
        // for (var i=0;i<legs.length;i++) {
        //   var steps = legs[i].steps;
        //   for (var j=0;j<steps.length;j++) {
        //     var nextSegment = steps[j].path;
        //     for (var k=0;k<nextSegment.length;k++) {
        //       polyline.getPath().push(nextSegment[k]);
        //       // bounds.extend(nextSegment[k]);
        //     }
        //   }
        // }
        // polyline.setMap(this.map);
        // this.map.fitBounds(bounds);
        // this.fx(response.routes[0]);
        // this.showSteps(response, this.markerArray, stepDisplay, this.map);
      } else {
        this.Service.AlertWarning('No route could be found between the origin and destination');
        // alert('No route could be found between the origin and destination'); 
      }
    });
    var icons_start = {
      url: "assets/images/marker-icon.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
    };
    var icons_end = {
      url: data.iconId != '' && data.iconId != null && data.iconId != undefined ? data.iconId : "assets/images/icon_general.png", // url
      scaledSize: new google.maps.Size(40, 30), // scaled size
    };
    // var icon_label ={
    //   text: data.title,
    //   color: "black",
    //   // fontWeight: "bold",
    //   fontSize: "10px"
    // }
    this.makeMarker(origin, icons_start, "im here", this.map);
    this.marker = new google.maps.Marker({
      position: { lat: data.lat, lng: data.lon },
      map: this.map,
      icon: icons_end,
      // label:icon_label,
      title: 'title'
    });
    var content = "<div><img class='marker_pop_img' src=" + data.imageId + "></div><div class='pop_head'>" + data.title + "</div><div class='pop_add'>" + data.address.fullAddrText + "</div>"
    this.infowindow = new google.maps.InfoWindow({
      content: content,
      maxWidth: 250
    });
    this.marker.addListener("click", () => {
      this.infowindow.open(this.map, this.marker);
    });
  }
  makeMarker(position: any, icon: any, title: any, map: any) {
    new google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title
    });
  }
  createStrokeFns1() {
    var static_street_list = [
      {
        origin: { lat: 9.9492001, lng: 76.2404252 },
        destination: { lat: 9.9602465, lng: 76.2407171 },
        color: "#ffd666",
        type: 'line'
      },
      {
        origin: { lat: 9.960935, lng: 76.2513652 },
        destination: { lat: 9.9611259, lng: 76.2540239 },
        color: "#ffd666",
        type: 'line'
      },
      {
        origin: { lat: 9.9640456, lng: 76.2384906 },
        destination: { lat: 9.9677474, lng: 76.2413264 },
        color: "#8fc987",
        type: 'dash'
      },
    ]
    var service = new google.maps.DirectionsService();
    // for (var i = 0; i < static_street_list.length; i++) {
    static_street_list.forEach((element, index) => {
      // var src = static_street_list[i].origin;
      // var des = static_street_list[i].destination;
      // var color = static_street_list[i].color;
      var src = element.origin;
      var des = element.destination;
      var color = element.color;
      service.route({
        origin: src,
        destination: des,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
      }, (result: any, status: any) => {
        if (status == google.maps.DirectionsStatus.OK) {
          var path = new google.maps.MVCArray();
          var poly;
          if (element.type == 'line') {
            poly = new google.maps.Polyline({
              map: this.map,
              strokeOpacity: 0.8,
              strokeWeight: 10,
              strokeColor: color,
            });
          }
          if (element.type == 'dash') {
            var lineSymbol = {
              // "M 0,0 0,1
              path: "M 11,0 0,1",
              scale: 1,
              strokeOpacity: 1,
            }
            poly = new google.maps.Polyline({
              map: this.map,
              // strokeWeight: 3,
              strokeColor: color,
              icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
              }],
            });
          }
          poly.setPath(path);
          for (var i = 0, len = result.routes[0].overview_path.length; i < len; i++) {
            path.push(result.routes[0].overview_path[i]);
          }
        }
      });
    });
    // }
  }
  createStrokeFns(zoomlevel: any) {
    this.marker.setMap(null)
    //  var path:any = new google.maps.MVCArray();
    //  var poly:any = [];
    var polyArray: any = [];
    //  var lat_lng = new Array();
    var service = new google.maps.DirectionsService();
    var static_street_list = [
      {
        origin: { lat: 9.9492001, lng: 76.2404252 },
        destination: { lat: 9.9602465, lng: 76.2407171 },
        color: "#8fc987",
        type: 'dash'
      },
      {
        origin: { lat: 9.960935, lng: 76.2513652 },
        destination: { lat: 9.9611259, lng: 76.2540239 },
        color: "#ffd666",
        type: 'line'
      },
      {
        origin: { lat: 9.9640456, lng: 76.2384906 },
        destination: { lat: 9.9677474, lng: 76.2413264 },
        color: "#ffd666",
        type: 'line'
      },
    ]
    //  for (var i = 0; i < static_street_list.length; i++) {
    static_street_list.forEach((element, index) => {
      var repeat_val: any = '';
      var scale_val: any = 0;
      var stroke_val: any = 0;
      // var lineSymbol
      var poly: any = [];
      if (zoomlevel >= 19 && zoomlevel < 18) {
        repeat_val = '12px';
        scale_val = 8;
        stroke_val = 6;
      }
      if (zoomlevel >= 18 && zoomlevel < 17) {
        repeat_val = '10px';
        scale_val = 6;
        stroke_val = 4;
      }
      if (zoomlevel <= 17) {
        repeat_val = '8px';
        scale_val = 1;
        stroke_val = 4;
      }
      if (element.type == 'dash') {
        var lineSymbol: any = {
          path: "M -1,1 1,1",
          strokeOpacity: 1,
          strokeColor: element.color,
          strokeWeight: stroke_val,
          scale: scale_val
        };
      }
      //  if ((i + 1) < lat_lng.length) {
      var src = element.origin;
      var des = element.destination;
      var color = element.color;
      service.route({
        origin: src,
        destination: des,
        provideRouteAlternatives: true,
        // travelMode: google.maps.DirectionsTravelMode.DRIVING
        travelMode: google.maps.TravelMode['DRIVING']
      }, (result: any, status: any) => {
        if (status == google.maps.DirectionsStatus.OK) {
          // poly.setPath(null);
          for (var j = 0; j < result.routes.length; j++) {
            // polyArray[polyArray.length - 1].setPath(null);
            var path = new google.maps.MVCArray();
            if (element.type == 'dash') {
              poly = new google.maps.Polyline({
                map: this.map,
                strokeOpacity: 0,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: "0",
                    repeat: repeat_val,
                  },
                ],
              });
              poly.setPath(path);
              for (var i = 0, len = result.routes[0].overview_path.length; i < len; i++) {
                path.push(result.routes[0].overview_path[i]);
              }
            }
            if (element.type == 'line') {
              polyArray.push(new google.maps.Polyline({
                map: this.map,
                strokeOpacity: 0.8,
                strokeWeight: 10,
                strokeColor: color,
              }));
              polyArray[polyArray.length - 1].setPath(path);
              for (var i = 0, len = result.routes[j].overview_path.length; i < len; i++) {
                path.push(result.routes[j].overview_path[i]);
              }
            }
          }
        }
      });
      //  }
    })
  }
}