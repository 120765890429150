<div class="row">
    <app-header (contactEventFun)="moveContactFns('contact')"></app-header>
</div>
<div class="page-content container-fluid">
    <div class="home1 row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <img src="assets/images/home/home_img.png" class="img-responsive center-block" alt="">
        </div>
   
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="mt-2"> <b>{{'Looking For' | translate}}</b> </div>

            <div class=" mt-2">{{'Tourist or Localite' | translate}}</div>

            <a class="btn btn-default" href="#myabout" role="button">{{'About the initiative'|translate}}</a>
      
        </div>

    </div>


    <div class="row">
        <!-- <div class="sec2  col-lg-12 col-md-12">-->
        <div class="sec2 col-lg-12 col-md-12">
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="circle" [routerLink]="['/wafi/trns']">
                    <span class="image">
                         <img src="assets/images/jpg/Graphics/1.png" class=" img-responsive cursor_pointer" alt="">
                        </span>
                    <h3 [ngClass]="{'language_ml_head':Service.current_language=='ML'}" class="abt-center ">
                        <span class="text-white">
                            {{'For planning'|translate}}>
                        </span>
                        <span class="">{{'Getting Around'|translate}}</span>
                    </h3>
                    <ul [ngClass]="{'language_ml_txt':Service.current_language=='ML'}"
                        class="text-mycolor text-content">
                        <li>{{'Find The Easiest'|translate}} - {{'Point To Point'|translate}}</li>
                        <li>{{'Find Nearby'|translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" [routerLink]="['/exko']">
                <div class="circle">
                    <span class="image">
                         <img src="assets/images/jpg/Graphics/2.png" class=" img-responsive cursor_pointer" alt="">
                        </span>
                    <h3 [ngClass]="{'language_ml_head':Service.current_language=='ML'}" class="abt-center fb">
                        <span class="text-white fb">
                            {{'For Sight'|translate}}-{{'Seeing'|translate}}>
                        </span>
                        <span class="">{{'Explore Kochi'|translate}}</span>
                    </h3>
                    <ul [ngClass]="{'language_ml_txt':Service.current_language=='ML'}"
                        class="text-mycolor text-content">
                        <li>{{'Tune in to events'|translate}}</li>
                        <li>{{'Discover places to see'|translate}}</li>
                        <li>{{'Find your way around town'|translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" [routerLink]="['/wafi/c019']">
                <div class="circle">
                    <span class="image">
                         <img src="assets/images/jpg/Graphics/3.png" class=" img-responsive cursor_pointer" alt="">
                        </span>
                    <h3 [ngClass]="{'language_ml_head':Service.current_language=='ML'}" class="abt-center ">
                        <span class="text-white ml-2">
                            {{'For Covid Info'|translate}}>
                        </span>
                        <span class=""><strong>{{'Pandemic'|translate}}</strong></span>
                    </h3>
                    <ul [ngClass]="{'language_ml_txt':Service.current_language=='ML'}"
                        class="text-mycolor text-content">
                        <li>{{'Find covid test and vaccination centers'|translate}}</li>
                        <li>{{'Find key information about Covid measures'|translate}}</li>

                    </ul>
                </div>
            </div>


        </div>

    </div>


    <div class="row" #about id="myabout">
        <div class="col-lg-12 mt-1" style="background-color: #fff;display: flex;">
            <div class="section3 container-fluid">
                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <img src="assets/images/jpg/Graphics/Graphics5.jpg" class="img-responsive pull-right center-block"
                        alt="">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <!-- <div class="col-lg-offset-1 col-lg-11 center"> -->
                        <h1 style="margin-top: 0px;">{{'About the initiative'|translate}}</h1>
                        <p>
                            {{'Kochi Ithile offers'|translate}}
                        </p>
                        <p class="mt-1">
                            {{'WRI India'|translate}}
                        </p>
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div class="row img-thumbnails">
            <div class="container-fluid2 center">
                <div class="container-about col-md-12 col-sm-12 col-xs-12  about_img  text-center pull-center">
                    <div class="col-md-2 col-sm-6 col-xs-6 mycontent-left ">
                        <div class="center-img">
                            <img src="assets/images/about/1.png" alt="about1" class="img-thumbnail img-responsive ">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xs-6  mycontent-left ">
                        <div class="center-img">
                            <img src="assets/images/about/2.png" alt="about2" class="img-thumbnail img-responsive">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xs-6 mycontent-left ">
                        <div class="center-img">
                            <img src="assets/images/about/3.jpg" alt="about3"
                                class="img-thumbnail img-responsive tst_img4">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xs-6 mycontent-left">
                        <div class="center-img">
                            <img src="assets/images/about/.1.png" alt="about4"
                                class="img-thumbnail img-responsive tst_img">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xs-6 mycontent-left">
                        <div class="center-img">
                            <img src="assets/images/about/img5.jpg" alt="about5"
                                class="img-thumbnail img-responsive  tst_img3 ">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xs-6  ">
                        <div class="center-img">
                            <img src="assets/images/about/6.png" alt="about6"
                                class="img-thumbnail img-responsive  tst_img2 ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row mydiv" > -->
    <div class="section4 " #contact id="contact-section">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 home1_content">
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> -->
                <h1>{{'Talk to Us'|translate}}</h1>

            <!-- </div> -->

            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> -->
                <div class="form-item">
                    <p class="">
                        {{'Got a query'|translate}}
                        <br>
                        {{'Drop us a message'|translate}}
                    </p>
                    <form [formGroup]="sendMailForm" #sendMailId="ngForm">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <input matInput autocomplete="off" oninput="this.value = this.value.replace(/^\s*/, '')"
                                    formControlName="mailerName" placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <input matInput autocomplete="off" oninput="this.value = this.value.replace(/^\s*/, '')"
                                    formControlName="mailerEmail" placeholder="{{ 'Email Id' | translate }}">

                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <input matInput autocomplete="off" oninput="this.value = this.value.replace(/^\s*/, '')"
                                    formControlName="mailercontactNumber" (keypress)="Service.NumberKeyPress($event)"
                                    [maxlength]="10" placeholder="{{ 'Contact no.(optional)' | translate }}">

                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-select [disableOptionCentering]="true" autocomplete="off"
                                    formControlName="mailerPurpose" placeholder="{{ 'Purpose' | translate }}">
                                    <mat-option value="Event">{{'Event' | translate}}</mat-option>
                                    <mat-option value="Information about city">{{'Information about city' | translate}} </mat-option>
                                    <mat-option value="Suggestion">{{'Suggestion' | translate}}</mat-option>
                                    <mat-option value="Complaint">{{'Complaint' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <textarea matInput rows="5" formControlName="mailerMessage" cols="40"
                                    placeholder="{{ 'Your query/ information to share' | translate }}"></textarea>

                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn_send" (click)="sendMail()">{{'Send
                                Message'|translate}}</button>
                        </div>
                    </form>
                </div>
            <!-- </div> -->
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pull-center "
            style="margin-top:30px;margin-bottom:50px;">
            <div class="col-lg-offset-1 col-lg-11 center">
                <img _ngcontent-dey-c69="" src="assets/images/home/contact.jpg" class="img-responsive center-block"
                    alt="">
            </div>
        </div>
    </div>
    <!-- </div> -->
    <div class="row">
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="width:100%;padding: 0px!important;">
    <app-footer [max_h]="maxiHeight"></app-footer>
</div>
</div>
</div>

<!-- <div class="row footer_bot"> -->

<!-- </div> -->




<!--   <app-footer [max_h]="maxiHeight"></app-footer> -->
<!--<div class="col-md-12 footer_bot" *ngIf="events_details == false">
    <app-footer [max_h]="maxiHeight"></app-footer>
</div>-->