import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Inject, ElementRef, Renderer2, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
// import {
//   SwiperComponent, SwiperDirective, SwiperConfigInterface,
//   SwiperScrollbarInterface, SwiperPaginationInterface
// } from 'ngx-swiper-wrapper';
import { DOCUMENT } from '@angular/common';
import { CustomService } from '../../services/custom.service';
import { SwiperOptions } from 'swiper';
import { Router } from '@angular/router';
import { inputs } from '@syncfusion/ej2-angular-richtexteditor/src/rich-text-editor/richtexteditor.component';
declare var $: any;
declare var google: any;
@Component({
  selector: 'app-map-section',
  templateUrl: './map-section.component.html',
  styleUrls: ['./map-section.component.scss'],

})
export class MapSectionComponent implements OnInit {

  config: SwiperOptions = {
    // pagination: { el: '.swiper-pagination', clickable: true },
    pagination: { clickable: true },
    // initialSlide: 3, // Slide Index Starting from 0
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 10
  };
  // config: SwiperConfigInterface = {
  //   loop: true,
  //   slidesPerView: 3,
  //   slidesOffsetBefore: 0,
  //   slidesOffsetAfter: 0,
  //   spaceBetween: 10,
  //   keyboard: true,
  //   mousewheel: true,
  //   scrollbar: false,
  //   navigation: true,
  //   pagination: false
  // };

  public trail1:any = [ 
    {'title':'Maritime Museum'},
    {'title': 'Indo-Portuguese Museum'},
    {'title': 'Kerala Kathakali Centre'},
    {'title': 'Santa Cruz Basilica'},
    {'title': 'St. Francis Church'},
    {'title': 'Parade Ground'},
    {'title': 'David Hall'},
    {'title': 'Beach Museum'},
    {'title': 'Fort Kochi View Point'},
    {'title': 'Mahatma Gandhi Beach'},
    {'title': 'Nehru Park'},
    {'title': 'Chinese Fishing Nets'},
    {'title': 'RoRo Jetty'},
    {'title': 'Aspinwall House'},
    {'title': 'Pepper House'},
    {'title': 'Fort Kochi Boat Jetty'}, 
    ];

    public trail2:any = [ 
    {'title':'Amman Kovil Temple'},
    {'title': 'Temple Pond'},
    {'title': 'Cochin Thirumala Devaswom Temple'},
    {'title': 'Statue'},
    {'title': 'Navagraha Temple'},
    {'title': 'Shantilal Sweets'},
    {'title': 'Jain Temple'},
    {'title': 'Experience the canal edge'},
    {'title': 'Spice Market'},
    {'title': 'Mahalara Masjid'},
    {'title': 'Balan Chettande Kada'},
    {'title': 'St. George Orthodox Koonam Kurish Old Syrian Church'},
    {'title': 'The Waterfront Granary Museum'},
    {'title': 'Puthiya Palli Masjid'},
    {'title': 'Kondo Syokai Marine-Mattancherry Yard'},
    {'title': 'Mattancherry Palace'},
    {'title': 'Mocha Art Cafe'},
    {'title': 'Paradesi Synagogue'} 
    ];

  items: any = [{ id: 1, url: 'assets/images/carn5.jpg', detail: { event: 'Carnival 2021', det: 'The magnificent city of kerala, the "Queen of Arabian Sea" - kochi always has some surprise for her visitors.', venue: 'Venue: Fort Kochi', date: 'Dec 25th to Jan 1st - 2021', det1: 'At vero eos et accusamus et iusto odio dignissimos sucimus qui blanditiis praesentium volumtatum deleniti atque corrupti quos dolore et quas molestias excepturi.', img: [{ id: 1, img: 'assets/images/2.jpg' }, { id: 2, img: 'assets/images/2.jpg' }, { id: 3, img: 'assets/images/2.jpg' }, { id: 4, img: 'assets/images/2.jpg' }] } },
  { id: 2, url: 'assets/images/carn1.jpg', detail: { event: 'Carnival 2021', det: 'The ambience of Kochi Fort is filled with cheerfulness when the Kerala Cochin Carnival is celebrated.', venue: 'Venue: Fort Kochi', date: 'Dec 25th to Jan 1st - 2021', det1: 'At vero eos et accusamus et iusto odio dignissimos sucimus qui blanditiis praesentium volumtatum deleniti atque corrupti quos dolore et quas molestias excepturi.', img: [{ id: 1, img: 'assets/images/2.jpg' }, { id: 2, img: 'assets/images/2.jpg' }, { id: 3, img: 'assets/images/2.jpg' }, { id: 4, img: 'assets/images/2.jpg' }] } },
  { id: 3, url: 'assets/images/carn4.jpg', detail: { event: 'Carnival 2021', det: 'This Carnival in Cochin which is marked by unlimited fun and frolic is held each year in the last week of December.', venue: 'Venue: Fort Kochi', date: 'Dec 25th to Jan 1st - 2021', det1: 'At vero eos et accusamus et iusto odio dignissimos sucimus qui blanditiis praesentium volumtatum deleniti atque corrupti quos dolore et quas molestias excepturi.', img: [{ id: 1, img: 'assets/images/2.jpg' }, { id: 2, img: 'assets/images/2.jpg' }, { id: 3, img: 'assets/images/2.jpg' }, { id: 4, img: 'assets/images/2.jpg' }] } },
  { id: 4, url: 'assets/images/carn3.jpg', detail: { event: 'Carnival 2021', det: 'The music created by the harmonious blending of five instruments called Panchavadyam is continuously played.', venue: 'Venue: Fort Kochi', date: 'Dec 25th to Jan 1st - 2021', det1: 'At vero eos et accusamus et iusto odio dignissimos sucimus qui blanditiis praesentium volumtatum deleniti atque corrupti quos dolore et quas molestias excepturi.', img: [{ id: 1, img: 'assets/images/2.jpg' }, { id: 2, img: 'assets/images/2.jpg' }, { id: 3, img: 'assets/images/2.jpg' }, { id: 4, img: 'assets/images/2.jpg' }] } },
  { id: 5, url: 'assets/images/carn6.png', detail: { event: 'Carnival 2021', det: 'Cochin Carnival, the biggest annual year-end celebration held at the Vasco da Gama Square in Fort Kochi.', venue: 'Venue: Fort Kochi', date: 'Dec 25th to Jan 1st - 2021', det1: 'At vero eos et accusamus et iusto odio dignissimos sucimus qui blanditiis praesentium volumtatum deleniti atque corrupti quos dolore et quas molestias excepturi.', img: [{ id: 1, img: 'assets/images/2.jpg' }, { id: 2, img: 'assets/images/2.jpg' }, { id: 3, img: 'assets/images/2.jpg' }, { id: 4, img: 'assets/images/2.jpg' }] } }];
  bus_route_list: any = []
  @Input() chev: any;
  // @Input() mapdata:any
  @ViewChild('gmap', { static: true }) mapElement: any;
  @ViewChild('route_bus', { static: false }) routeBus: any;
  @ViewChild('route_taxi', { static: false }) routeTaxi: any;
  @ViewChild('route_train', { static: false }) routeTrain: any;
  @ViewChild('route_ferry', { static: false }) routeFerry: any;
  @ViewChild('route_bike', { static: false }) routeBike: any;
  @ViewChild('route_walk', { static: false }) routeWalk: any;
  @ViewChild('route_auto', { static: false }) routeAuto: any;
  @ViewChild('route_metro', { static: false }) routeMetro: any;
  @ViewChild('route_bycycle', { static: false }) routeBycycle: any;
  @ViewChild('route_car', { static: false }) routeCar: any;
  // @ViewChild('route_main', { static: false }) routeMain: any;

  // @ViewChild('rrrr', { static: false }) rrrr: any;
  @ViewChild('distance_fees', { static: false }) distanceFees: any;

  @Output("parentwafiPlacesGetAll") parentwafiPlacesGetAll: EventEmitter<any> = new EventEmitter();
  @Output("parentwafiPlacesGet") parentwafiPlacesGet: EventEmitter<any> = new EventEmitter();
  map: any;
  href: any;
  parsedUrl: any;
  baseUrl: any;
  autoIcon: any;
  availabletransitDetails: any;
  availableRouteBus: any;
  availableRouteTaxi: any;
  availableRouteTrain: any;
  availableRouteFerry: any;
  availableRouteBike: any;
  availableRouteWalk: any;
  availableRouteAuto: any;
  availableRouteMetro: any;
  availableRouteBicycle: any;
  availableRouteCar: any;
  busTimingStart: any = [];
  busTimingEnd: any = [];
  busFare: any = [];
  ferry_route_list: any = [];
  ferry_List: any = [];
  metro_route_list: any = [];
  metro_List: any = [];
  bus_element: boolean = false;
  public ferry_element: boolean = false;
  public auto_element: boolean = false;
  public metro_element: boolean = false;
  public walk_element: boolean = false;
  route_List: any;
  bus_Fare_List: any;
  walk_route_list: any;
  walk_List: any;
  ferryTimeInfo: any;
  metroTimeInfo: any;

  constructor(private router: Router, private ngZone: NgZone, public Service: CustomService, private mapsAPILoader: MapsAPILoader, private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private _document: any) {
    // let oldScript: any = document.getElementById("googlemap"); 
    // if (oldScript !== null && oldScript !== undefined && oldScript !== '') {
    //   oldScript.remove();
    // }
    // let node = document.createElement('script');
    // node.src = 'https://maps.googleapis.com/maps/api/js?language=' + localStorage.getItem('current_language') + '&libraries=geometry&sensor=false&key=AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg';
    // node.type = 'text/javascript';
    // node.async = true;
    // node.id = 'googlemap'
    // // node.charset = 'utf-8';
    // document.getElementsByTagName('head')[0].appendChild(node); 
    // let oldScript:any = document.getElementById("googlemap");
    // if (oldScript !== null&&oldScript !== undefined&&oldScript !== '') {
    //   oldScript.remove();
    // }
    // let script = this.renderer.createElement('script');
    // script.type = 'text/javascript';
    // script.id='googlemap';
    // script.src = 'http://maps.googleapis.com/maps/api/js?language=' + localStorage.getItem('current_language') + '&libraries=geometry&sensor=false&key=AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg';
    // this.renderer.appendChild(this._document.body, script);
  }

  icon_url: any;
  latitude: any;
  longtitude: any;
  origin: any;
  destination: any;
  // zoom:any;
  mapOptions: any;
  // loadAPI: Promise<any>;
  @Input('transit_show_state') transit_show_state: any;
  // transit_show_state:boolean = false;
  ngOnInit(): void { 

   

    this.icon_url = {
      url: "assets/images/marker-icon.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
      // origin: new google.maps.Point(0, 0), // origin
      // anchor: new google.maps.Point(0, 0) // anchor
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32),


    };
   
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      console.log(result.state);
      if(result.state === "granted" || result.state === "prompt"){
            if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {

        let latitude = position.coords.latitude.toFixed(6);
        let langitude = position.coords.longitude.toFixed(6)

        this.origin = { lat: Number(latitude), lng: Number(langitude) };
        this.Service.current_lat = Number(latitude);
        this.Service.current_lng = Number(langitude);
      },err=>{
        console.log("err",err);
       
        this.origin = { lat: 9.9612714, lng: 76.2457904 };
        this.Service.current_lat = 9.953000;
        this.Service.current_lng = 76.240000;
      });
    } else {
      this.origin = { lat: 9.9612714, lng: 76.2457904 };
      this.Service.current_lat = 9.953000;
      this.Service.current_lng = 76.240000;
    }
      } else{
        this.origin = { lat: 9.9612714, lng: 76.2457904 };
        this.Service.current_lat = 9.953000;
        this.Service.current_lng = 76.240000;
      }
    });

     
    setTimeout(() => {

      this.loadMap();

    }, 500);

    this.auto_element = false;
    this.bus_element = false;
    this.ferry_element = false;
    this.metro_element = false;
    this.availableRouteBus = 0;
    this.availableRouteTaxi = 0;
    this.availableRouteTrain = 0;
    this.availableRouteFerry = 0;
    this.availableRouteBike = 0;
    this.availableRouteWalk = 0;
    this.availableRouteAuto = 0;
    this.availableRouteMetro = 0;
    this.availableRouteBicycle = 0;
    this.availableRouteCar = 0;
    this.busTimingStart = [];
    this.busTimingEnd = [];
    this.busFare = [];
    this.ferry_route_list = [];
    this.ferry_List = [];
    this.metro_route_list = [];
    this.metro_List = [];
    this.availabletransitDetails = [];
  }

  directionsService: any;
  loadMap() {
    var mapzoom: any;
    this.mapOptions = {
      zoom: this.Service.zoom,
      center: this.origin,
      mapId: '7ab97996c3d8635c',
      zoomControl: false,
    } 
    // new google.maps.Map(this.mapElement.nativeElement, this.mapOptions)
    this.map = new google.maps.Map(document.getElementById('gmap') as HTMLElement, this.mapOptions); 

     var zoomDiv = document.createElement('div');
    zoomDiv.style.margin = '9px 10px 0px 0px';
    zoomDiv.style.cursor = 'pointer';
    // zoomDiv.style.opacity = "0.8";
    zoomDiv.style.backgroundColor = "#FFFFFF";
    zoomDiv.style.fontFamily = 'Open Sans';
    zoomDiv.style.borderRadius = '3px';
    zoomDiv.style.height = '72px';
    zoomDiv.style.width = '42px';
    // zoomDiv.style.left = '21px !important';
    // zoomDiv.style.top = '33px';

    var zoomout = document.createElement('div');
    zoomout.title = 'Click to zoom out';
    zoomout.style.width = '100%';
    zoomout.style.height = '50%';
    zoomout.style.borderBottom = "1px solid rgb(158, 158, 158)";
    zoomDiv.appendChild(zoomout);

    var zoomoutText = document.createElement('div');
    zoomoutText.innerHTML = '<i class="fa fa-minus" style="line-height:36px" aria-hidden="true"></i>';
    zoomoutText.style.fontSize = '18px';
    zoomoutText.style.textAlign = 'center';
    zoomoutText.style.color = "#9e9e9e";
    zoomout.appendChild(zoomoutText);

    var zoomin = document.createElement('div');
    zoomin.title = 'Click to zoom in';
    zoomin.style.width = '100%';
    zoomin.style.height = '50%';
    zoomDiv.appendChild(zoomin);

    var zoominText = document.createElement('div');
    zoominText.innerHTML = '<i class="fa fa-plus" style="line-height:36px" aria-hidden="true"></i>';
    zoominText.style.fontSize = '18px';
    zoominText.style.textAlign = 'center';
    zoominText.style.color = "#9e9e9e";
    zoomin.appendChild(zoominText);
    google.maps.event.addDomListener(zoomout, 'click', () => {
      this.Service.zoom = this.map.getZoom() - 1;
      if (this.Service.zoom != 0) {
        this.map.setZoom(this.Service.zoom);
        if (this.Service.sub_layer_url == '' && this.Service.main_layer_url != '' && this.Service.main_url == 'wafi') {
          this.parentwafiPlacesGetAll.emit();
        } else if (this.Service.sub_layer_url != '' && this.Service.main_layer_url != '' && this.Service.main_url == 'wafi') {
          this.parentwafiPlacesGet.emit();
        }
      }
    });

    google.maps.event.addDomListener(zoomin, 'click', () => {
      this.Service.zoom = this.map.getZoom() + 1;
      if (this.Service.zoom != 21) {
        this.map.setZoom(this.Service.zoom);
        if (this.Service.sub_layer_url == '' && this.Service.main_layer_url != '' && this.Service.main_url == 'wafi') {
          this.parentwafiPlacesGetAll.emit();
        }
        else if (this.Service.sub_layer_url != '' && this.Service.main_layer_url != '' && this.Service.main_url == 'wafi') {
          this.parentwafiPlacesGet.emit();
        }
      }
    });

    google.maps.event.addListener(this.map, 'zoom_changed',  () => { 
      console.log("zoom_changed ",Math.ceil(this.map.getZoom()) , this.Service.zoom,Math.ceil(this.map.getZoom()) != Math.ceil(this.Service.zoom));
      
      if(Math.ceil(this.map.getZoom()) != Math.ceil(this.Service.zoom)){         
        this.map.setZoom(this.Service.zoom);
        if (this.Service.sub_layer_url == '' && this.Service.main_layer_url != '' && this.Service.main_url == 'wafi') {
          this.parentwafiPlacesGetAll.emit();
        }
        else if (this.Service.sub_layer_url != '' && this.Service.main_layer_url != '' && this.Service.main_url == 'wafi') {
          this.parentwafiPlacesGet.emit();
        }
      } 
      this.Service.zoom = Math.ceil(this.map.getZoom());
    });

    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomDiv); 

    this.Service.marker = new google.maps.Marker({
      position: this.origin,
       
    });

    this.Service.marker.setMap(this.map);
     

  }

  marker: any;
  mapChangeView(mapdata: any) {
    this.Service.marker.setMap(null);
    var myLatlng = new google.maps.LatLng(mapdata.lat, mapdata.lon);
    this.Service.marker = new google.maps.Marker({
      position: myLatlng,
      map: this.map,
      icon: mapdata.icon_img,
    });
    this.map.panTo(myLatlng);
  }
  infowindow: any;
  directionsRenderer: any;
  markerArray: any = [];
  mapDirectionView(origin: any, data: any) { 
console.log("origin,data",origin,data);
    this.showAvailableTrsport(origin, data)
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(null);
    }
    this.Service.marker.setMap(null)
    if (this.directionsRenderer != undefined && this.directionsRenderer != null && this.directionsRenderer != '') {
      this.directionsRenderer.setMap(null);
    }
    if (this.Service.directionsRenderer) {
      this.Service.directionsRenderer.setMap(null);
    }
    var lineSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      fillOpacity: 1,
      scale: 3
    };
    var arrowSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      fillOpacity: 1,
      scale: 5
    };
    var polylineDotted = {
      strokeColor: '#02205b',
      strokeOpacity: 0,
      fillOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '10px'
      },
      {
        icon: arrowSymbol,
        offset: '30%',
        repeat: '200px'
      }
      ],
    };
    var rendererOptions = {
      map: this.map,
      suppressMarkers: true,
      polylineOptions: polylineDotted
    };
    const symbolOne = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      strokeColor: "#F00",
      // fillColor: "#F00",
      fillOpacity: 1,
    };
    const stepDisplay = new google.maps.InfoWindow();
    var directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions);  
    directionsService.route({
      origin: origin,
      destination: { lat: data.lat, lng: data.lon },
      travelMode: google.maps.TravelMode['TRANSIT'],

    }, (response: any, status: any) => {
      if (status === 'OK') {
        this.directionsRenderer.setDirections(response);
      } else {
        this.Service.AlertWarning('No route could be found between the origin and destination');
      }
    });
    var icons_start = {
      url: "assets/images/marker-icon.png", // url
      scaledSize: new google.maps.Size(20, 20), // scaled size
    }; 
    var icons_end = {
      url: data.iconId != '' && data.iconId != null && data.iconId != undefined ? data.iconId : "assets/images/icon_general.png", // url
      scaledSize: new google.maps.Size(20, 20), // scaled size

    };

    this.makeMarker(origin, icons_start, "im here", this.map);
    this.Service.marker = new google.maps.Marker({
      position: { lat: data.lat, lng: data.lon },
      map: this.map,
      icon: icons_end,
      title: 'title'
    });
    var content = "<div><img class='marker_pop_img' src=" + data.imageId + "></div><div class='pop_head'>" + data.title + "</div><div class='pop_add'>" + data.address.fullAddrText + "</div>"

    this.infowindow = new google.maps.InfoWindow({
      content: content,
      maxWidth: 250
    });
    this.Service.marker.addListener("click", () => {
      this.infowindow.open(this.map, this.Service.marker);
    });
  }

  fx(o: any) {
    if (o && o.legs) {
      for (var l = 0; l < o.legs.length; ++l) {
        var leg = o.legs[l];
        for (var s = 0; s < leg.steps.length; ++s) {
          var step = leg.steps[s],
            a = (step.lat_lngs.length) ? step.lat_lngs[0] : step.start_point,
            z = (step.lat_lngs.length) ? step.lat_lngs[1] : step.end_point,
            dir = ((Math.atan2(z.lng() - a.lng(), z.lat() - a.lat()) * 120) / Math.PI) + 360,
            ico = ((dir - (dir % 3)) % 120);
          var icons_end = {
            url: 'http://maps.google.com/mapfiles/dir_' + ico + '.png', // url
            // assets/images/arrow_for/
            // url: 'assets/images/arrow_for'+ico+'.png',
            scaledSize: new google.maps.Size(24, 24), // scaled size

            origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32),
          };
          new google.maps.Marker({
            position: a,
            icon: icons_end,
            map: this.map,
            // title: Math.round((dir>360)?dir-360:dir)+'°'
          });
        }
      }
    }
    this.map.setZoom(15)
  }
  showSteps(directionResult: any, markerArray: any, stepDisplay: any, map: any) {
    // marker.setMap(this.map);
    // For each step, place a marker, and add the text to the marker's infowindow.
    // Also attach the marker to an array so we can keep track of it and remove it
    // when calculating new routes.
    var icons_end = {
      // url: "assets/images/arrow_for.png", // url
      //   scaledSize: new google.maps.Size(40, 40), // scaled size
      //   origin: new google.maps.Point(0,0), // origin
      // anchor: new google.maps.Point(12, 12),
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      rotation: 0,
      fixedRotation: true,
      offset: '20%',
      scale: 7,
      strokeColor: 'blue',
      strokeWeight: 3,
      fillColor: 'blue',
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32),
      // anchor: new google.maps.Point(0, 2.5)
      // fillColor: 'blue',
    };
    const myRoute = directionResult.routes[0].legs[0];
    for (let i = 0; i < myRoute.steps.length; i++) {
      const marker = (markerArray[i] =
        markerArray[i] || new google.maps.Marker({
          map: this.map,
          icon: icons_end,
        }));
      // marker.setMap(this.map);
      marker.setPosition(myRoute.steps[i].start_location);
    }
  }

  makeMarker(position: any, icon: any, title: any, map: any) {
    new google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title
    });
  }

  makeDestMarker(position: any, icon: any, map: any) {
    // new google.maps.Marker({
    //     position: position,
    //     map: map,
    //     icon: icon
    // });
    var icons_end = {
      url: icon, // url
      scaledSize: new google.maps.Size(80, 100), // scaled size

    };
    this.Service.marker = new google.maps.Marker({
      position: position,
      icon: icons_end,
      map: map,
      // title:"hi!",
    });
  }
  transit_show: boolean = false;
  transit_status: boolean = false;
  transitToggle() {
    this.transit_show = !this.transit_show
  }
  // timer_status:boolean =false;

  routeTimerCheck(type: any) {
    // this.createStrokeFns(type);
    this.transit_status = true;
    this.busTimingStart = [];
    this.busTimingEnd = [];
    this.busFare = [];
    this.ferry_route_list = [];
    this.ferry_List = [];
    this.metro_route_list = [];
    this.metro_List = [];

    this.auto_element = false;
    this.bus_element = false;
    this.ferry_element = false;
    this.metro_element = false;
    this.removeClass();
    if (type == 'bus') {
      this.bus_element = true;
      this.renderer.addClass(
        this.routeBus.nativeElement,
        'active'
      );
      this.trasitOptionFns(this.Service.origin, this.Service.locationInfo, 'BUS_ROUTES_GET', 'DRIVING')
    }
     
    if (type == 'ferry') {
      this.bus_element = false;
      this.ferry_element = true;
      this.renderer.addClass(
        this.routeFerry.nativeElement,
        'active'
      );
      this.trasitOptionFns(this.Service.origin, this.Service.locationInfo, 'FERRY_ROUTES_GET', 'TRANSIT')
    }

    if (type == 'metro') {
      this.metro_element = true;
      this.renderer.addClass(
        this.routeMetro.nativeElement,
        'active'
      );
      this.trasitOptionFns(this.Service.origin, this.Service.locationInfo, 'KMRL_ROUTES_GET','TRANSIT' )
    }
    

  }
  public trasitOptionFns(origin: any, destination: any, type: any, travelMode: any) {
    // this.Service.directionsRenderer.setMap(null);
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    } 
    const httpOptions = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      /*  params: { 'lang': this.Service.current_language, 'originlat': 9.98, 'originlng': 76.27, 'destinationlat': 10.05, 'destinationlng': 76.30, routeSP: type } */
      params: { 'lang': this.Service.current_language, 'originlat': origin.lat.toFixed(2), 'originlng': origin.lng.toFixed(2), 'destinationlat': destination.lat.toFixed(2), 'destinationlng': destination.lng.toFixed(2), routeSP: type }
    };
    let Data = { 'url': this.Service.BASE_URL + 'transitDetails', 'header': httpOptions }
    this.Service.GetApiService(Data, (result: any) => { 
      if (this.bus_element) {
        this.bus_route_list = result[0][2];
        this.route_List = result[0][1];
        this.bus_Fare_List = result[0][3];
        this.mapTransportDirectionView(this.Service.origin, this.Service.locationInfo, this.Service.placesList, 'black', travelMode);
        
      }
      if (this.ferry_element) {
        this.ferry_route_list = result[0][2];
        this.ferry_List = result[0][1];
        this.mapTransportDirectionView(this.Service.origin, this.Service.locationInfo, this.Service.placesList, 'blue', travelMode);
        
      }
      if (this.metro_element) {
        this.metro_route_list = result[0][2];
        this.mapTransportDirectionView(this.Service.origin, this.Service.locationInfo, this.Service.placesList, 'green', travelMode);
        this.metro_List = result[0][1]; 
      }
      if (this.walk_element) {
        this.walk_route_list = result[0][2];
        this.mapTransportDirectionView(this.Service.origin, this.Service.locationInfo, this.Service.placesList, 'green', travelMode);
        this.walk_List = result[0][1]; 
      }
    })

  }

  public removeClass() { 
    if (this.availableRouteBus == 1) {
      this.renderer.removeClass(
        this.routeBus.nativeElement,
        'active',
      );
    }

     if (this.availableRouteAuto == 1) {
      this.renderer.removeClass(
        this.routeAuto.nativeElement,
        'active',
      );
    }

    if (this.availableRouteTaxi == 1) {
      this.renderer.removeClass(
        this.routeTaxi.nativeElement,
        'active',
      );
    }

    if (this.availableRouteTrain == 1) {
      this.renderer.removeClass(
        this.routeTrain.nativeElement,
        'active',
      );
    }

    if (this.availableRouteFerry == 1) {
      this.renderer.removeClass(
        this.routeFerry.nativeElement,
        'active',
      );
    }

    if (this.availableRouteBike == 1) {
      this.renderer.removeClass(
        this.routeBike.nativeElement,
        'active',
      );
    }

    if (this.availableRouteWalk == 1) {
      this.renderer.removeClass(
        this.routeWalk.nativeElement,
        'active',
      );
    }

    if (this.availableRouteMetro == 1) {
      this.renderer.removeClass(
        this.routeMetro.nativeElement,
        'active',
      );
    }

    if (this.availableRouteBicycle == 1) {
      this.renderer.removeClass(
        this.routeBycycle.nativeElement,
        'active',
      );
    }

    if (this.availableRouteCar == 1) {
      this.renderer.removeClass(
        this.routeCar.nativeElement,
        'active',
      );
    }  

  }

  setPolygon(marker: any){
    let PlanCoordinates1:any = [];
    let PlanCoordinates12:any = [];
    let PlanCoordinates2:any = []; 

    

    for (var i = 0; i < marker.length; i++) {
    if(marker[0].subLayerCode == 'hetr' || marker[0].subLayerCode == 'heml'){
      if(marker[i].lat != undefined){
        if(marker[i].line_stage == 1){
          PlanCoordinates1.push({ lat: marker[i].lat, lng: marker[i].lon })
        }
        if(marker[i].line_stage == 2){
          PlanCoordinates12.push({ lat: marker[i].lat, lng: marker[i].lon })
        }
        
      }    
      
      } 

 
      if(marker[0].subLayerCode == 'hett' || marker[0].subLayerCode == 'hemm'){
        if(marker[i].lat != undefined){
      PlanCoordinates2.push({ lat: marker[i].lat, lng: marker[i].lon })
        }
      }
    }

    if(marker[0].subLayerCode == 'hetr' || marker[0].subLayerCode == 'heml'){ 
      
      const flightPath1 = new google.maps.Polyline({
        path: PlanCoordinates1,
        geodesic: true,
        strokeColor: "brown", 
        strokeOpacity: 1.0,
        strokeWeight: 1,
      }); 

      
      const flightPath12 = new google.maps.Polyline({
        path: PlanCoordinates12,
        geodesic: true,
        strokeColor: "brown", 
        strokeOpacity: 1.0,
        strokeWeight: 1,
      }); 
    
      flightPath1.setMap(this.Service.map); 
      flightPath12.setMap(this.Service.map); 
    } 

    if(marker[0].subLayerCode == 'hett' || marker[0].subLayerCode == 'hemm'){
      
 
       
      const flightPath2 = new google.maps.Polyline({
        path: PlanCoordinates2,
        geodesic: true,
        strokeColor: "brown",
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });
    
      flightPath2.setMap(this.Service.map);
    }

  }


  markersArray: any = [];
  markerSet(marker: any, markerIcon: any) { 
    let PlanCoordinates1:any = [];
    let PlanCoordinates2:any = [];
    let flightPath1:any;
    let flightPath2:any;
    /* if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    } */
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(null);
    }
    this.Service.markers = [];
    var icons_start = {
      url: markerIcon != '' && markerIcon != null && markerIcon != undefined ? markerIcon : "assets/images/marker-icon.png", // url
      scaledSize: new google.maps.Size(20, 20), // scaled size
    };
    for (var i = 0; i < marker.length; i++) {
      var marke = new google.maps.Marker({
        position: { lat: marker[i].lat, lng: marker[i].lon },
        map: this.map,
        icon: icons_start,
      });


      var infowindow:any;
      infowindow = new google.maps.InfoWindow({maxWidth: 200});
    var map = this.map; 
    google.maps.event.addListener(marke, 'click', ((markerr, i) => {
      return () => {
           console.log(" content 2 =>",i); 
        var content = "<div class='pop_head'>"+marker[i].title+"</div><div class='pop_add'>"+marker[i].address.fullAddrText+"</div>";
 
        infowindow.setContent(content);
        infowindow.open(map, markerr);
      }
    }) (marke, i));
      marke.setMap(this.map)
      // this.markers.push(marke);


      this.Service.markers.push(marke);
      
      if(this.trail1.filter((x: any) => x.title == marker[i].title).length > 0){
        PlanCoordinates1.push({ lat: marker[i].lat, lng: marker[i].lon })
      }
  
      if(this.trail2.filter((x: any) => x.title == marker[i].title).length > 0){
        PlanCoordinates2.push({ lat: marker[i].lat, lng: marker[i].lon })
      }
    }
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(this.map);
    }
    // this.Service.zoom = 16;
    this.map.setZoom(this.Service.zoom);
    if(marker[0].subLayerCode == 'hetr' || marker[0].subLayerCode == 'heml'){ 
      
      
      this.Service.flightPath1 = new google.maps.Polyline({
        path: PlanCoordinates1,
        geodesic: true,
        strokeColor: "brown", 
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      
      this.Service.flightPath1.setMap(this.map); 
 
    }else if(marker[0].subLayerCode == 'hett' || marker[0].subLayerCode == 'hemm'){
      
       
      this.Service.flightPath2 = new google.maps.Polyline({
        path: PlanCoordinates2,
        geodesic: true,
        strokeColor: "brown",
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      
      this.Service.flightPath2.setMap(this.map); 
    } 
   
  }

  markerMultiSet(markerData: any) { 
    /* if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    } */

    let PlanCoordinates1:any = [];
    let PlanCoordinates2:any = [];
    let flightPath1:any;
    let flightPath2:any;
    
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(null);
    }
    
    this.Service.markers = [];
    for (var i = 0; i < markerData.length; i++) {
      var icons_start = {
        url: markerData[i].iconId, // url
        scaledSize: new google.maps.Size(20, 20), // scaled size
        // labelOrigin: new google.maps.Point(16,64),
        // scaledSize: new google.maps.Size(20, 20),
        // origin: new google.maps.Point(0, 0),
        // anchor: new google.maps.Point(11, 40),
        origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32),
        labelOrigin: new google.maps.Point(11, 30)
      };
      var icon_label = {
        text: markerData[i].title,
        color: "black",
        // fontWeight: "bold",
        fontSize: "10px"
      }
      var marke = new google.maps.Marker({
        position: { lat: markerData[i].lat, lng: markerData[i].lon },
        map: this.map,
        icon: icons_start,
        label: icon_label
      });

      if(this.trail1.filter((x: any) => x.title == markerData[i].title).length > 0){
        PlanCoordinates1.push({ lat: markerData[i].lat, lng: markerData[i].lon })
      }
  
      if(this.trail2.filter((x: any) => x.title == markerData[i].title).length > 0){
        PlanCoordinates2.push({ lat: markerData[i].lat, lng: markerData[i].lon })
      }

      var infowindow: any;
      infowindow = new google.maps.InfoWindow({ maxWidth: 200 });
      var map = this.map;
      google.maps.event.addListener(marke, 'click', (function (marker, i) {
        return function () {
          var content = "<div class='pop_head'>" + markerData[i].title + "</div><div class='pop_add'>" + markerData[i].address.fullAddrText + "</div>"
          infowindow.setContent(content);
          infowindow.open(map, marker);
        }
      })(marke, i));
      marke.setMap(this.map)

      this.Service.markers.push(marke);

      //  marker.setMap(this.map)

    }
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(this.map);
    }
    
    this.map.setZoom(this.Service.zoom);
    
    if(markerData[0].subLayerCode == 'hetr' || markerData[0].subLayerCode == 'heml'){ 
      
      
        flightPath1 = new google.maps.Polyline({
        path: PlanCoordinates1,
        geodesic: true,
        strokeColor: "brown", 
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      
      flightPath1.setMap(this.map); 
 
    }else if(markerData[0].subLayerCode == 'hett' || markerData[0].subLayerCode == 'hemm'){
      
       
        flightPath2 = new google.maps.Polyline({
        path: PlanCoordinates2,
        geodesic: true,
        strokeColor: "brown",
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      
      flightPath2.setMap(this.map); 
    } 
  }

  /* markerMultiSet(markerdata:any){  
     if(this.directionsRenderer){
       this.directionsRenderer.setMap(null);
     }
     for (let i = 0; i < this.Service.markers.length; i++) {
       this.Service.markers[i].setMap(null);
       
     }
     this.Service.markers = [];
   /*   for(var i =0;i<marker.length;i++){
       var icons_start ={
             url: marker[i].iconId, // url
             scaledSize: new google.maps.Size(20, 20), // scaled size
             
         };
        
         
        var content = "<div class='infowindow'><img class='marker_pop_img' src="+marker[i].imageId+"></div><div class='pop_head'>"+marker[i].title+"</div><div class='pop_add'>"+marker[i].address.fullAddrText+"</div>";
        
       this.infowindow= new google.maps.InfoWindow({
         content: content,
         maxWidth: 250
       });
       marker[i].push({message: this.infowindow})
       
       var marke = new google.maps.Marker({
         position: {lat:marker[i].lat,lng:marker[i].lon},
         map: this.map,
         icon: icons_start, 
         title:marker[i].title
       });
       var infowindow = new google.maps.InfoWindow({ maxWidth: 320 });      
       google.maps.event.addListener(marke, "click", ((marke) => {
         return () => {           
           infowindow.setContent(content);
           infowindow.open(this.map, marke);
         }
       })(marke)); 
       this.Service.markers.push(marke);
     }
     for (let i = 0; i < this.Service.markers.length; i++) {
       this.Service.markers[i].setMap(this.map);
     } */

  /* for(var i =0;i<markerdata.length;i++){
     var icons_start ={
       url: markerdata[i].iconId, // url\
       scaledSize: new google.maps.Size(20, 20),
       origin: new google.maps.Point(0, 0), 
       anchor: new google.maps.Point(11, 40), 
       labelOrigin: new google.maps.Point(11, 10) 
   };
   var infowindow:any;
   var markerLabel = 'GooooooooooooooooO!';  
   var marker = new google.maps.Marker({
     position:{lat:markerdata[i].lat,lng:markerdata[i].lon},
     map: this.map,
     icon: icons_start,
     title: markerdata[i].title
   });
   infowindow = new google.maps.InfoWindow({maxWidth: 200});
   var map = this.map;
   google.maps.event.addListener(marker, 'click', (function(marker, i) {
     return function() {
       var content = "<div class='pop_head'>"+markerdata[i].title+"</div><div class='pop_add'>"+markerdata[i].address.fullAddrText+"</div>"
       infowindow.setContent(content);
       infowindow.open(map, marker);
     }
   }) (marker, i));
  marker.setMap(this.map)
 
 }

   this.map.setZoom(this.Service.zoom);
 }*/

  clearMarkers(marker: any) {
    // this.Service.marker
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
      this.Service.marker.setMap(null)
    }
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(null);
    }
    this.Service.markers = [];
    this.Service.zoom = 15;
    this.map.setZoom(this.Service.zoom);
  }

  static_street_list = [{
    origin: { lat: 9.9640384, lon: 76.2390787 },
    destination: { lat: 9.9644284, lon: 76.2400264 },
    color: "#ffd66"
  }]
  createStrokeFns(type: any) {
    var lineSymbol: any = "";
    this.Service.marker.setMap(null)
    var path: any = new google.maps.MVCArray();

    if (this.directionsService) {
      // this.directionsService.route
      // this.directionsRenderer.setMap(null);
      // this.directionsDisplay.set('directions', null);
      // this.Service.marker.setMap(null)
    }
    var poly: any = [];
    var polyArray: any = [];
    var lat_lng = new Array();

    this.directionsService = new google.maps.DirectionsService();
    var origin = { lat: 9.9606128, lng: 76.2417868 }
    var destination = { lat: 9.9611057, lng: 76.2443493 }
    var marke = [
      {
        "title": '',
        "lat": '9.9606128',
        "lng": '76.2417868',
        "description": ''
      },
      {
        "title": '',
        "lat": '9.9611057',
        "lng": '76.2443493',
        "description": ''
      },
    ];
    // var colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff'];
    for (var i = 0; i < marke.length; i++) {
      var data: any = marke[i];
      var myLatlng = new google.maps.LatLng(data.lat, data.lng);
      // var myLatlng = {lat:data.lat, lng:data.lng}

      lat_lng.push(myLatlng);

    }

    if (type == 'bus') {
      lineSymbol = {
        // path: "M 0,-1 0,1",
        path: "M 0,1 1,1",
        // path: "M 0,0 -8,0 M -8,0 -8,11 M -8,11 0,11 M 0,11 0,22",
        strokeOpacity: 1,
        strokeColor: '#d14e2b',
        // strokeColor: '#445396',
        scale: 2,
        repeat: "10px",
      };
    }
    if (type == "taxi") {
      lineSymbol = {
        // path: "M 0,-1 0,1",
        // path: "M 0,5 0,16 M -3,22 3,22",
        path: "M 0,0 -8,0 M -8,0 -8,11 M -8,11 0,11 M 0,11 0,22",
        strokeOpacity: 1,
        // strokeColor: '#FFFF00',
        strokeColor: '#445396',
        scale: 2,
      };
    }

    for (var i = 0; i < lat_lng.length; i++) {
      if ((i + 1) < lat_lng.length) {
        var src = lat_lng[i];
        var des = lat_lng[i + 1];
        this.directionsService.route({
          origin: src,
          destination: des,
          provideRouteAlternatives: true,
          // travelMode: google.maps.DirectionsTravelMode.DRIVING
          travelMode: google.maps.TravelMode['DRIVING']
        }, (result: any, status: any) => {
          if (status == google.maps.DirectionsStatus.OK) {
            // poly.setPath(null);
            for (var j = 0; j < result.routes.length; j++) {
              // polyArray[polyArray.length - 1].setPath(null);
              polyArray.push(new google.maps.Polyline({
                map: this.map,
                strokeOpacity: 0,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: "0",
                    repeat: "20px",
                  },
                ],
              }));

              polyArray[polyArray.length - 1].setPath(path);
              for (var i = 0, len = result.routes[j].overview_path.length; i < len; i++) {
                path.push(result.routes[j].overview_path[i]);
              }
            }
          }
        });
      }
    }
    var icons_start = {
      url: "assets/images/marker-icon.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size 
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32), 
    };
    var icons_end = {
      url: "assets/images/icon_general.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32),

    };
    this.makeMarker(origin, icons_start, "im here", this.map);
    this.Service.marker = new google.maps.Marker({
      position: destination,
      map: this.map,
      icon: icons_end,
      title: 'title'
    });

  }

  showAvailableTrsport(origin: any, destination: any) { 
    
    var minimumlat, maximumlat, minimumlng, maximumlng;
    if (origin.lat.toFixed(3) > destination.lat.toFixed(3)) {
      maximumlat = origin.lat.toFixed(3);
      minimumlat = destination.lat.toFixed(3);
    } else {
      maximumlat = origin.lat.toFixed(3);
      minimumlat = destination.lat.toFixed(3);
    }
    if (origin.lng.toFixed(3) > destination.lon.toFixed(3)) {
      maximumlng = origin.lng.toFixed(3);
      minimumlng = destination.lon.toFixed(3);
    } else {
      maximumlng = origin.lng.toFixed(3);
      minimumlng = destination.lon.toFixed(3);
    }
    const httpOptions1 = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'lang': this.Service.current_language, 'minimumlat': minimumlat, 'maximumlat': minimumlat, 'minimumlng': minimumlng, 'maximumlng': maximumlng, routeSP: '' }
      /*  params: { 'lang': this.Service.current_language, 'originlat': this.Service.origin.lat.toFixed(3), 'destinationlat': this.Service.destination.lat.toFixed(3),  'originlng': this.Service.origin.lng.toFixed(3),'destinationlng': this.Service.destination.lng.toFixed(3), routeSP: '' } */
    };
    let Data1 = { 'url': this.Service.BASE_URL + 'availabletransitDetails', 'header': httpOptions1 }

    this.Service.GetApiService(Data1, (result: any): void => {
      this.availabletransitDetails = result[0]; 
      this.availableRouteBus = result[0].bus_route || 0;
      this.availableRouteTaxi = result[0].taxi_route || 0;
      this.availableRouteTrain = result[0].train_route || 0;
      this.availableRouteFerry = result[0].ferry_route || 0;
      this.availableRouteBike = result[0].bike_route || 0;
      this.availableRouteWalk = result[0].walk_route || 0;
      this.availableRouteAuto = result[0].auto_route || 0;
      this.availableRouteMetro = result[0].kmrl_route || 0;
      this.availableRouteBicycle = result[0].Bicycle_route || 0;
      this.availableRouteCar = result[0].Car_route || 0;  
    })
  }

  mapTransportDirectionView(origin: any, data: any, alldata: any, color: any, travelMode: any) { 
    if (this.Service.markers.length > 0) {
      for (let i = 0; i < alldata.length; i++) {
        this.Service.markers[i].setMap(null);
      }
    }
    this.Service.marker.setMap(null);
    if (this.Service.directionsRenderer != undefined && this.Service.directionsRenderer != null && this.Service.directionsRenderer != '') {
      this.Service.directionsRenderer.setMap(null);
    }

    var lineSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      // path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      fillOpacity: 1,
      scale: 3
    };

    var arrowSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      fillOpacity: 1,
      scale: 5
    };

    var polylineDotted = {
      // strokeColor: '#02205b',
      strokeColor: color,
      strokeOpacity: 0,
      fillOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '10px'
      },
      {
        icon: arrowSymbol,
        offset: '30%',
        repeat: '200px'
      }],
    };

    var rendererOptions = {
      map: this.map,
      suppressMarkers: true,
      polylineOptions: polylineDotted,
      // preserveViewport: true
    };

    var directionsService = new google.maps.DirectionsService();
    this.Service.directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions);
    directionsService.route({
      // zoom: this.Service.zoom,
      // preserveViewport: true,
      origin: origin,
      destination: { lat: data.lat, lng: data.lng },
      // travelMode: google.maps.TravelMode['DRIVING'],
      // travelMode: google.maps.TravelMode.DRIVING,
      travelMode: travelMode

    }, (response: any, status: any) => {
      if (status === 'OK') {
        // var map = this.Service.directionsRenderer.getMap();
        this.Service.directionsRenderer.setDirections(response);
        // map.setZoom(1);
      } else {
        this.Service.AlertWarning('No route could be found between the origin and destination');
      }
    });
    var icons_start = {
      url: "assets/images/marker-icon.png",
      scaledSize: new google.maps.Size(20, 20),
      // origin: new google.maps.Point(0, 0),
      // anchor: new google.maps.Point(11, 40),
      // labelOrigin: new google.maps.Point(11, 30)
    };
    var icons_end = {
      url: data.iconId != '' && data.iconId != null && data.iconId != undefined ? data.iconId : "assets/images/icon_general.png", // url
      scaledSize: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16,32),
      labelOrigin: new google.maps.Point(11, 30)

    };

    var icon_label = {
      text: data.title,
      color: "black",
      fontSize: "15px"
    }
    this.makeMarker(origin, icons_start, "im here", this.map);
    this.marker = new google.maps.Marker({
      position: { lat: data.lat, lng: data.lon },
      map: this.map,
      icon: icons_end,
      label: icon_label
      // title: 'title'
    });
    // 
    var content = "<div><img class='marker_pop_img' src=" + data.title + "></div>"
    this.infowindow = new google.maps.InfoWindow({
      content: content,
    });
    this.marker.addListener("click", () => {
      var content = "<div class='pop_head'>" + data.title + "</div><div class='pop_add'>" + data.address.fullAddrText + "</div>"
      this.infowindow.setContent(content);
      this.infowindow.open(this.map, this.marker);
    });

  }
  public ShowBusTimings(busroute: any) {
    this.busTimingStart = [];
    this.busTimingEnd = [];
    this.busFare = []; 
    let businfo = this.route_List.filter((x: any) => x.stopstime_tripid == busroute.route_tripid);
    let fare = this.bus_Fare_List.filter((x: any) => x.fare_tripid == busroute.route_tripid); 
    if (fare.length > 0) {
      this.busFare = fare[0];
    } else {
      this.busFare.fare_price = 'N/A';
    }
    this.firstAndLast(businfo);
  }

  public ShowFerryTimings(ferryRoute: any) { 
    this.ferryTimeInfo = this.ferry_List.filter((x: any) => x.ferry_stopstime_tripid == ferryRoute.ferry_routetrips_tripid)[0]; 
  }

  public ShowMetroTimings(metroRoute: any) { 
    this.metroTimeInfo = this.metro_List.filter((x: any) => x.ferry_stopstime_tripid == metroRoute.ferry_routetrips_tripid)[0]; 
  }

  public firstAndLast(myArray: string | any[]) {

    this.busTimingStart = myArray[0];
    this.busTimingEnd = myArray[myArray.length - 1]; 
  }

}