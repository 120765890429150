import { Component, OnInit,ElementRef,ViewChild,Output,EventEmitter,Inject,Renderer2 } from '@angular/core';
import { Routes, RouterModule,Router,ActivatedRoute } from '@angular/router'; 
import { CustomService } from '../../services/custom.service';
import { HttpClientModule, HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { EmergencyComponent } from '../emergency/emergency.component';
import { EventsComponent } from '../events/events.component';
declare var $:any; 
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
// import  *  as  data  from  '../../../assets/i18n/en.json';
// import  *  as  data1  from  '../../../assets/i18n/ml.json';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public url1: string = "";
  search_txt:any;
  @ViewChild(EmergencyComponent) emergencychild: any;
  @ViewChild(EventsComponent) eventschild: any;
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  @Output("disableFun") disableFun:EventEmitter<any> = new EventEmitter();
  @Output("parentMain") parentMain: EventEmitter<any> = new EventEmitter();
  @Output("singlePlacesFns") singlePlacesFns: EventEmitter<any> = new EventEmitter();
  @Output("parentWafiFun") parentWafiFun: EventEmitter<any> = new EventEmitter();
  @Output("singleEventFun") singleEventFun: EventEmitter<any> = new EventEmitter();
  @Output("contactEventFun") contactEventFun: EventEmitter<any> = new EventEmitter();
  
  title:any="Home";
  cur_layer_name: any;
  isWay: boolean = false;
  isExplore: boolean = false;
  constructor(@Inject(DOCUMENT) private _document: Document,private renderer: Renderer2,public cookieService: CookieService,public Service:CustomService,private router: Router,private el: ElementRef,public translate: TranslateService,@Inject(DOCUMENT) private document: Document) {    

    this.Service.current_lat = 9.953000;
    this.Service.current_lng = 76.240000;
    this.Service.zoom = 16;
    let url1 = this.router.url;
    this.Service.main_url = url1.split('/')[1];
    this.Service.main_layer_url = url1.split('/')[2];
    this.Service.sub_layer_url = url1.split('/')[3];
    this.Service.current_language = localStorage.getItem('current_language');
    translate.addLangs(['EN', 'ML']);
    translate.setDefaultLang('EN');
    this.translate.use(this.Service.current_language);
    this.getApis();
   }
   getApis() {
    this.Service.GetApiUrl().subscribe((data: any) => {
      this.Service.BASE_URL = data.BASE_URL;
      this.Service.langList();
    this.Service.wafiMenuList();
    this.Service.exkoMenuList();
    })
  }
  ngOnInit() {
    this.url1 = this.router.url;
    let url_split = this.url1.split('/')
   
    this.Service.main_url = url_split[1];
    this.Service.main_layer_url = url_split[2]; 
    if(this.Service.main_url == 'wafi'){
      this.Service.sub_layer_url = this.Service.main_url == 'wafi'&&url_split.length>3?url_split[3]:'';
    }else{
      this.Service.sub_layer_url = this.Service.main_url == 'exko'&&url_split.length>3?url_split[3]:'';
    }
    this.Service.sub_layer_url1 = this.Service.main_url == 'wafi'&&url_split.length>4?url_split[4]:'';
    // this.Service.wafiMenuList();
    // this.Service.exkoMenuList();
   
    setTimeout(() => {
      if(this.Service.main_url == 'wafi'){
        if(this.Service.sub_layer_url1 != ''){
          let data ={id:this.Service.sub_layer_url1,type_status:'qrcode'}
          this.router.navigate(['/wafi/'+this.Service.main_layer_url+'/'+this.Service.sub_layer_url], {state: {data: data}});
         }
         if(this.Service.main_layer_url==''||this.Service.main_layer_url==null||this.Service.main_layer_url==undefined){
          this.disableFun.emit();
         }
        if(this.Service.sub_layer_url!=''&&this.Service.main_layer_url!=''&&this.Service.main_layer_url!=null&&this.Service.main_layer_url!=undefined){
            this.parentWafiFun.emit();
        }
        else if(this.Service.sub_layer_url==''&&this.Service.main_layer_url!=''&&this.Service.main_layer_url!=null&&this.Service.main_layer_url!=undefined){
            this.parentMain.emit();
         }
      }
      else{
        if(this.Service.sub_layer_url != ''&&this.Service.sub_layer_url!=null&&this.Service.sub_layer_url!=undefined){
          let data ={id:this.Service.sub_layer_url,type_status:'qrcode'}
          this.router.navigate(['/exko/'+this.Service.main_layer_url], {state: {data: data}});
        }
        else{
          if(this.Service.main_layer_url!=''&&this.Service.main_layer_url!=undefined&&this.Service.main_layer_url!=null){
            this.parentFun.emit('sub');
          }
          else{
            this.parentFun.emit('main');
          }
          
        }
         
      }
    }, 500);
  }
  
  loadLocationDetails(wafi:any,wayfisubcode:any,wayfisubname:any){  
    this.Service.searchlist = [];
    this.Service.zoom = 16;
    this.Service.mainLayer = wafi.name;
    this.url1 = '/'+wafi.type+'/'+wafi.code+'/'+wayfisubcode;
    this.cur_layer_name = '/'+wafi.type+'/'+wafi.name+'/'+wayfisubname; 
    this.Service.main_url = this.url1.split('/')[1];
    this.Service.main_layer_url = this.url1.split('/')[2];
    this.Service.sub_layer_url = this.url1.split('/')[3];
    this.parentWafiFun.emit();
  }
  openHome(){ 
  }
  loadLocationMainDetails(type:any,code:any){ 
    

    this.Service.searchlist = [];
    this.Service.zoom = 16;
    this.url1 = '/'+type+'/'+code; 
    this.Service.main_url = this.url1.split('/')[1];
    this.Service.main_layer_url = this.url1.split('/')[2];
    this.Service.sub_layer_url = '';
    this.parentMain.emit();
  }
  loadeventDetails(data:any){
    this.Service.searchlist = [];
    this.url1 = '/'+data.type+'/'+data.code; 
    this.Service.main_url = this.url1.split('/')[1];
    this.Service.main_layer_url = this.url1.split('/')[2];
    this.Service.sub_layer_url = '';
    this.parentFun.emit('sub');
  }
  loadeventMainDetails(data:any){ 
    this.Service.searchlist = [];
    this.url1 = '/'+data; 
    let x = this.Service.main_url == data?'samepath':'otherpath'
    this.Service.main_url = data;
    this.Service.main_layer_url = '';
    this.Service.sub_layer_url = '';
    this.router.navigate([ this.url1], {state: {data: 'mainevent'}});
  }
  disablelocationFns(data:any){
    this.Service.searchlist = [];
    this.url1 = '/'+data; 
    this.Service.main_url = data;
    this.Service.main_layer_url = '';
    this.Service.sub_layer_url = '';
    // this.parentFun.emit();
   // this.disableFun.emit();
  }
  langChange(lang:any){
    this.Service.searchlist = [];
    this.translate.use(lang);
    localStorage.setItem('current_language',lang);
    let oldScript:any = document.getElementById("googlemap");
    if (oldScript !== null&&oldScript !== undefined&&oldScript !== '') {
      oldScript.remove();
    }
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.id='googlemap';
    // script.src = 'http://maps.googleapis.com/maps/api/js?language=' + localStorage.getItem('current_language') + '&libraries=geometry&sensor=false&key=AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg';
    script.src = 'https://maps.googleapis.com/maps/api/js?language=' + localStorage.getItem('current_language') + '&key=AIzaSyDjvjVSrDJA2dCpKqYaf4keThmElDGRSCg';//&sensor=false
    this.renderer.appendChild(this._document.body, script);

    if(this.Service.main_url != 'home'){
      this.router.navigate(['/home'], { state: { data: 'redir' },replaceUrl: true })
  //   this.router.navigateByUrl('/wafi', {skipLocationChange: true}).then(() => {
  //     this.router.navigate(['/home']);
  //     location.reload()
  // });
  }
  else{ 
    this.router.navigateByUrl('/wafi', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/home'],{ state: { data: 'redir' }});
          // location.reload()
      });
    // window.location.reload();
  }
    // 
    
   
  }
  search_text_len:number = 0;
  searchGet(event:any){
    let searchdata:any = [];
    this.Service.searchlist = [];
    this.search_text_len = event.target.value.length;
    this.Service.current_lat = 9.953000;
    this.Service.current_lng = 76.240000;
    // .searchbar
    if(event.target.value.length>0){
      $(".searchbar").trigger('hover');
    }
    if(event.target.value.length>3){
      const httpOptions = {
        // data.id
        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
        params:{'text':event.target.value,'limit':25,'scale':this.Service.zoom,'lat':this.Service.current_lat,'lon':this.Service.current_lng}
      };
      let Data = { 'url': this.Service.BASE_URL + 'search', 'header': httpOptions }
      this.Service.GetApiService(Data, (result: any): void => {
        // let events = result.events;
        // let places = result.places;
        if(result.events.length>0){
          result.events.forEach((element:any) => {
            element.type = 'events';
            searchdata.push(element);
          });
         
        }
        if(result.places.length>0){
          result.places.forEach((element:any) => {
            element.type = 'places';
            searchdata.push(element);
          });
          // searchdata.push(result.places);
        }
        this.Service.searchlist = searchdata;
      })
    }
  }
  over(){
    // $(".search_input_over").trigger('hover');
    $(".search_input").addClass('search_input_over');
    $(".search_dropdown_content_w").css("display", "block");
  }
  out(){
    $('.search_input').blur();
    $(".search_dropdown_content_w").css("display", "none"); 
    if(this.search_text_len==0){
    $(".search_input").removeClass('search_input_over');
  }
  }
  goTOPage(data:any){ 
    this.Service.searchlist = [];
    if (data.layerCode == this.Service.main_layer_url && this.Service.main_url == 'exko') {
      let sinle_events = this.Service.eventDetails.filter((x: any) => x.id == data.id)[0];
      // let layer_name = this.Service.expkoMenuList.filter((x: any) => x.code == data.layerCode)[0].name; 
      this.singleEventFun.emit(sinle_events);
    }
    else if (data.layerCode == this.Service.main_layer_url&& this.Service.sub_layer_url!=''&& this.Service.sub_layer_url!=null&& this.Service.sub_layer_url!=undefined&& this.Service.main_url == 'wafi') {
      let sinle_places = this.Service.placesList.filter((x: any) => x.id == data.id)[0];
      this.singlePlacesFns.emit(sinle_places);
    }
    else {
      if (data.type == 'events') {
          data.type = 'search';
        this.router.navigate(['/exko/' + data.layerCode], { state: { data: data },replaceUrl:true });
      }
      else {
        data.type = 'search';
        this.router.navigate(['/wafi/' + data.layerCode + '/' + data.subLayerCode], { state: { data: data },replaceUrl:true });
      }
    }
  //  this.Service.searchlist = [];
  //   if(data.layerCode == this.Service.main_layer_url && this.Service.main_url == 'exko'){
  //     let sinle_events = this.Service.eventDetails.filter((x:any)=>x.id == data.id)[0];
  //     this.singleEventFun.emit(sinle_events);
  //   }
  //   else if (data.layerCode == this.Service.main_layer_url && this.Service.main_url == 'wafi'){
  //     this.parentWafiFun.emit();
     
  //     let sinle_places = this.Service.placesList.filter((x:any)=>x.id == data.id)[0];
  //     this.singlePlacesFns.emit(sinle_places);
  //     // 
  //   }
  //   else{
  //     if(data.type == 'events'){
  //       data.type_status='redirect';
  //       this.router.navigate(['/exko/'+data.layerCode], {state: {data: data}});
  //     }
  //     else{ 
  //       data.type_status='redirect';
  //       this.router.navigate(['/wafi/'+data.layerCode+'/'+data.subLayerCode], {state: {data: data}});
  //     }
  //   }
  }
  isWayFinding()
  {
    if(this.isWay == false)
    {
      this.isWay = true;
    }
    else{
      this.isWay = false;
    }
  }
  isExploreKochi()
  {
    if(this.isExplore == false)
    {
      this.isExplore = true;
    }
    else{
      this.isExplore = false;
    }
  }
  goToPandemic()
  { 
    let x = this.Service.wayfiMenuList.filter((x: any) => x.code == 'C019'||x.code == 'c019')[0] 
    if(x!=null&&x!=undefined&&x!=''){
      this.Service.main_layer_url = x.code;
      this.router.navigate(['/wafi/'+x.code], {state: {data: 'pandemic'}});
      if(this.Service.main_url=='wafi'){
        this.loadLocationMainDetails('wafi',x.code)
      }
    }
    
  }

  toTop() { 
  if(this.Service.main_url=='home'){
    this.contactEventFun.emit('contact');
  }
    this.router.navigate(['/home'], {state: {data: 'redirectcontact'}});
    // this.document.getElementById("contact-section").scrollIntoView();
    // this.contactEventFun.emit()
  }
}