<div class="page-wrapper"> 
    <header id="page-header" style="position: relative;">
       
        <nav class="navbar navbar-default"> 
            <div class="left">
                <div class="side_menu">
                    <div class="burger_box">
                        <div class="menu-icon-container">
                            <a class="menu-icon js-menu_toggle closed">
                                <span class="menu-icon_box">
                                <span class="menu-icon_line menu-icon_line--1"></span>
                                <span class="menu-icon_line menu-icon_line--2"></span>
                                <span class="menu-icon_line menu-icon_line--3"></span>
                                </span>
                            </a>
                        </div>
                    </div> 
                    
                    <div class="px-5 sidemenu-scroll"> 
                        <h2 class="menu_title"></h2>
                        <ul class="list_load">
                            <li class="list_item">
                                <a></a>
                            </li>
                            <li class="list_item">
                                <a></a>
                            </li>
                            <li class="list_item" style="margin-top:0px;" routerLink="/home"><span><i class="fa fa-home fa_sz20 pad_r_15" aria-hidden="true"></i>{{'Home' | translate}}</span></li>
                            <li (click)="isWayFinding()" class="list_item"><span><i class="fa fa-road fa_sz20 pad_r_15" aria-hidden="true"></i>{{'Getting Around' | translate}}<i *ngIf="!isWay" style="margin-left: 50px;" class="fa fa-caret-right fa_sz22 pad_r_15" aria-hidden="true"></i>
                                 <i *ngIf="isWay" style="margin-left: 50px;"  class="fa fa-caret-down fa_sz22 pad_r_15" aria-hidden="true"></i>                                
                                </span>
                            <!-- [ngClass]="{'dropdown_in':wayfi.subLayers?.length>0}" [class.active]="Service.main_layer_url==wayfi.code" -->
                                <ul [hidden]="!isWay">
                                    <li *ngFor="let wayfi of Service.wayfiMenuList">
                                        <a class="side_menu_sublayer" (click)="loadLocationMainDetails(wayfi.type,wayfi.code)" routerLink="/{{wayfi.type}}/{{wayfi.code}}">{{wayfi.name.slice(0, 25) + (wayfi.name.length > 25 ? "..." : "")}}</a> 
                                        <ul> 
                                                <li *ngFor="let wayfisub of wayfi.subLayers">
                                                    
                                                    <a (click)="loadLocationDetails(wayfi,wayfisub.code,wayfisub.name)" routerLink="/{{wayfi.type}}/{{wayfi.code}}/{{wayfisub.code}}">{{wayfisub.name.slice(0, 25) + (wayfisub.name.length > 25 ? "..." : "")}}</a>
                                                </li> 
                                        </ul>
                                    </li>
                                </ul>
                            </li><!-- (click)="isExploreKochi()" -->
                            <li  class="list_item" ><span><i class="fa fa-chevron-circle-down fa_sz20 pad_r_15" aria-hidden="true"></i><span (click)="loadeventMainDetails('exko')">{{'Explore Kochi' | translate}}</span><i *ngIf="!isExplore"  style="margin-left: 50px;" class="fa fa-caret-right fa_sz22 pad_r_15" aria-hidden="true"></i>
                                <i *ngIf="isExplore" style="margin-left: 50px;"  class="fa fa-caret-down fa_sz22 pad_r_15" aria-hidden="true"></i> 
                            </span>
                           <!--  <i class="fas fa-chevron-circle-down"></i> -->
                                <ul [hidden]="!isExplore"> <!-- [ngClass]="{'d-flex': Service.main_url=='exko'}" class="dropdown-menu container mega-dropdown-menu" style="padding-left:'65px';" -->
                                    <li *ngFor="let exko of Service.expkoMenuList"><a class="side_menu_sublayer" (click)="loadeventDetails(exko)" routerLink="/{{exko.type}}/{{exko.code}}">{{exko.name}}</a>
                                        <ul class="dropdown-content">
                                            <li *ngFor="let exkosub of exko.subLayers">
                                                <a class="dropdown-item">{{exkosub.name}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            
                            </li>
                             
                           <!--  <li class="list_item"><span><i class="fa fa-info fa_sz22 pad_r_19" aria-hidden="true"></i>{{'About The Initiative' | translate}}</span></li> -->
                        </ul>
                    </div> 
                </div>
            </div>
            <!-- <a _ngcontent-dey-c69="" class="brand"><img _ngcontent-dey-c69="" src="assets/images/logo8.png" alt="" style="padding-left: 50px;margin-top:10px;"></a> -->
            <a _ngcontent-dey-c69="" [routerLink]="['/home']" class="brand"><img _ngcontent-dey-c69="" src="assets/images/logo_kochi.png" alt="" class="kochi_logo"></a>
            <div class="collapse navbar-collapse js-navbar-collapse"  id="mobile-hide">
                <ul class="nav navbar-nav"  id="mobile-hide">
                    <li class="dropdown mega-dropdown" id="mobile-hide" [ngClass]="{'active': url1=='/home'}">
                        <a type="button" [routerLink]="['/home']"  >
                            {{'Home' | translate}}</a>
                        <!-- <a>{{'Home' | translate}}</a> --><!-- (click)="openHome()"  [routerLink]="['/home']" -->
                        <ul  [ngClass]="{'d-flex': url1=='/home'}" class="rm-outline dropdown-menu mega-dropdown-menu">
                            <li class="text1" style="color: '#445396 !important';font-size: '16px';margin-left: '3px !important';background-color:'unset !important'">
                                <a style="color: '#445396 !important';font-weight: '500';margin-left:'19px';background-color:'unset !important'">{{'Home Hi'|translate}}</a></li>
                        </ul>
                    </li>
                    <li class="mega-dropdown" id="mobile-hide" [ngClass]="{'active': Service.main_url=='wafi'}"> <!--  (click)="disablelocationFns('wayfi')" -->
                        <a type="button"  routerLink="/wafi"  (click)="disablelocationFns('wafi')" >{{'Getting Around' | translate}}</a><!-- data-toggle="dropdown" --> <!-- routerLink="/wafi" -->
                        <ul [ngClass]="{'d-flex': Service.main_url=='wafi'}" class="dropdown-menu container mega-dropdown-menu" style="padding-left:'65px';">
                            <li *ngFor="let wayfi of Service.wayfiMenuList" [ngClass]="{'dropdown_in':wayfi.subLayers?.length>0}" [class.active]="Service.main_layer_url==wayfi.code"><a (click)="loadLocationMainDetails(wayfi.type,wayfi.code)" routerLink="/{{wayfi.type}}/{{wayfi.code}}">{{wayfi.name}}</a>
                                <ul class="dropdown-content scrollable-menu" *ngIf="wayfi.subLayers?.length>0">
                                    <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar">
                                        <li *ngFor="let wayfisub of wayfi.subLayers">
                                            <!--  -->
                                            <a (click)="loadLocationDetails(wayfi,wayfisub.code,wayfisub.name)" routerLink="/{{wayfi.type}}/{{wayfi.code}}/{{wayfisub.code}}" class="dropdown-item"><!-- {{wayfisub.name}} -->
                                            
                                                {{ wayfisub.name | slice: 0:27 }}{{wayfisub.name.length > 27 ? ' ... ' : ' '}}
                                            </a>
                                        </li>
                                    </ng-scrollbar>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="mega-dropdown"  id="mobile-hide"  [ngClass]="{'active': Service.main_url=='exko'}"> <!-- class="dropdown mega-dropdown" data-toggle="dropdown" -->
                        <a type="button"  (click)="loadeventMainDetails('exko')" >{{'Explore Kochi' | translate}}</a><!-- routerLink="/exko" -->
                        <ul [ngClass]="{'d-flex': Service.main_url=='exko'}" class="dropdown-menu container mega-dropdown-menu" style="padding-left:'65px';">
                            <li *ngFor="let exko of Service.expkoMenuList" [ngClass]="{'dropdown_in':exko.subLayers?.length>0}" [class.active]="Service.main_layer_url==exko.code"><a (click)="loadeventDetails(exko)" routerLink="/{{exko.type}}/{{exko.code}}">{{exko.name}}</a>
                                <ul class="dropdown-content">
                                    <li *ngFor="let exkosub of exko.subLayers">
                                        <a class="dropdown-item">{{exkosub.name}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="mega-dropdown" id="mobile-hide">
                        <a type="button" class="blink" data-period="2000" data-type='[ "Pandemic" ]' (click)="goToPandemic()">
                            <!-- routerLink="/wafi/C019" -->
                        
                            <span class="wrap">{{'Pandemic' | translate}}</span>
                        </a> 
                    </li>
                   <!--  <li class="dropdown mega-dropdown" [ngClass]="{'active': url1=='/contact'}">
                        <a type="button" data-toggle="dropdown">{{'About The Initiative' | translate}}</a>
                        <ul [ngClass]="{'d-flex': url1=='/contact'}" class="dropdown-menu container mega-dropdown-menu" style="margin-left:'0px';">
                            <li><a>{{'The Project' | translate}}</a></li>
                            <li><a>{{'Key Stakeholders' | translate}}</a></li>
                            <li><a>{{'Contribute' | translate}}</a></li>
                            <li [ngClass]="{'active': url1=='/contact'}"><a routerLink="/contact">{{'Contact Us' | translate}}</a></li>
                        </ul>
                    </li> -->
                </ul>
                <div class="filter-section">
                    <div class="searchbar" (mouseenter)='over()' (mouseleave)='out()'>
                        <input class="search_input" type="text" [(ngModel)]="search_txt" (keyup)="searchGet($event)" placeholder="{{'Search'|translate}}">
                        <a class=" search_icon "><i class="fa fa-search fa-1x " style="color:#999; "></i></a>
                        <ul class="search_dropdown_content_w " *ngIf="Service.searchlist?.length>0">
                            <li *ngFor="let data of Service.searchlist" (click)="goTOPage(data)" id="{{data.id}}" class="drop_item_lang languageTrigger"><a><i class="fa fa-map-marker" aria-hidden="true"></i>{{data.title.slice(0, 20)}}</a></li>
                        </ul>
                    </div>
                    <!-- <ul class="search_dropdown_content_w" *ngIf="Service.searchlist?.length>0">
                            <li *ngFor="let data of Service.searchlist" (click)="goTOPage(data)" id="{{data.id}}" class="drop_item_lang languageTrigger"><a><i class="fa fa-map-marker" aria-hidden="true"></i>{{data.title.slice(0, 20)}}</a></li>
                        </ul> -->
                    <!-- </div> -->
                    <!-- <span class="d-block text-center m-lr-lf-20 ml_10"><span><span class="material-icons d-block">
                        content_copy
                        </span> -->
                    <span id="mobile-hide" class=" text-center m-lr-lf-20 ml_10"><span>
                       
                    <div class="dropdown_in">
                        
                        <button class="dropdown_style_rem">
                            <span id="mobile-hide" class="material-icons d-block">
                                content_copy
                                </span>
                            {{'Language' | translate}}</button>
                        <ul class="dropdown_content_w">
                            <li *ngFor="let data of Service.language" (click)="langChange(data.code)" id="{{data.code}}" class="cursor_pointer drop_item_lang languageTrigger"><a>{{data.name}}</a></li>
                        </ul>
                    </div>
                    </span>
                    </span>
                    <!-- <span id="mobile-hide" class=" text-center"><span><span id="mobile-hide" class="material-icons d-block">
                        insert_drive_file
                        </span>
                    <div class="dropdown_in">
                        <button class="dropdown_style_rem">{{'Contact' | translate}}</button>
                       
                    </div>
                    </span>
                    </span> -->
                    <span id="mobile-hide" class=" text-center"><span>
                        
                         <div class="dropdown_in">
                            <button class="dropdown_style_rem">
                               
                                <a (click)="toTop()" class="text-dark">
                                    <span id="mobile-hide" class="material-icons d-block">
                                        contacts
                                         </span>
                                         {{'Contact' | translate}}</a>
                            </button>
    
                        </div>
                     </span>
 
                     </span>
                    <!-- <span class="d-block text-center"><span>
                        <i class="d-block fa fa-qrcode fa_sz_24" aria-hidden="true"></i>
                       
                    <div class="dropdown_in">
                        <button class="dropdown_style_rem">Scanner</button>
                    </div>
                    </span>
                    </span> -->
                    <!-- <span class="mrg_l_10">
                        <i class="fa fa-qrcode fa_sz_24" aria-hidden="true"></i>
                    </span> -->
                    <!-- <span><span class="dropup">
                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                        Legend
                        <span class="material-icons d-block">
                        insert_drive_file
                        </span>
                    <span class="caret"></span></button>
                    <ul class="dropdown-menu">
                        <li><a>HTML</a></li>
                        <li><a>CSS</a></li>
                        <li><a>JavaScript</a></li>
                    </ul>
                    </span>
                    </span> -->
                </div>
            </div>
            <!-- /.nav-collapse -->
        </nav>
    </header>
</div>
<!--end page-wrapper-->
<!-- <li><a>Transit</a></li>
                            <li><a>Tourisum</a></li>
                            <li><a>Religious</a></li>
                            <li><a>Recreation</a></li>
                            <li class="dropdown_in" [ngClass]="{'active': url1=='/emergency'}"><a routerLink="/emergency">Emergency</a>
                                <ul class="dropdown-content">
                                    <li><a class="dropdown-item">Hospital</a></li>
                                    <li><a class="dropdown-item">Clinic</a></li>
                                    <li><a class="dropdown-item">Pharmacy</a></li>
                                    <li><a class="dropdown-item">Police Station</a></li>
                                    <li><a class="dropdown-item">Fire Station</a></li>
                                </ul>
                            </li>
                            <li><a>Education</a></li>
                            <li><a>Facilities</a></li> -->