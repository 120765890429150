<div class="container-fluid ">
    <h1 class="center-item mt-2">Privacy Policy</h1>   

    <p class="page-content f20">
        Kochi Ithile is committed to protecting your privacy. By visiting <a href="https://kochiithile.com/#/home">kochiithile.com/#/home</a>  ("the site") and without submitting personal information you acknowledge and accept the practices and policies outlined in this privacy policy ("Privacy Policy"). <br/><br/>
        This Privacy Policy explains in general terms how we collect and use Personal Information and certain other information that we collect from you at the Site. If you have questions, concerns, or complaints regarding our privacy statement or practices, please contact us at <a href="https://kochiithile.com/#/contact"> kochiithile.com/#/contact.</a> <br/><br/>
        <b>Log Data</b>
        <br/>
        When you use our Site and services, our servers automatically record information that your browser sends whenever you visit a website (“Log Data”). This Log Data may include information such as your IP address, browser type or the domain from which you are visiting, your current location (if user allows) and the search terms you use.<br/><br/>

        <b>Cookies</b>
        <br/>
        As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience.<br/><br/>

        <b>Do not track</b>
        <br/>
        Kochi Ithile does not process or respond to web browsers’ “do not track” signals or other similar transmissions that indicate a request to disable online tracking of users who use our Site.<br/><br/>

        <b>Third Party</b>
        <br/>
        The use of the Kochi Ithile Platform by a third party using a User’s Device will despite any conditions under which the service provider is providing such services to the User, be subject to the Terms and Conditions for the purposes of this Agreement.<br/><br/>

        <b>Data security</b>
        <br/>
        Kochi Ithile employs industry accepted standards in securing your personal information on our Site.<br/><br/>

        <b>Links to Others Sites</b> <br/>
        This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.<br/><br/>

        <b>Devices</b> <br/>
        The Kochi Ithile platform is accessible via Smart Devices with the ability to browse the Services on the internet. To the extent you access the Service through a Smart Device, your wireless service carrier's standard charges, data rates and other fees may apply
        <br/><br/>

        <b>Contact Us</b> <br/>
        Any comments or queries related to this privacy policy should be directed to  <a href="https://kochiithile.com/#/contact"> kochiithile.com/#/contact</a>. Queries to the Data Protection Officer can be made to the same email address and will be forwarded accordingly.
        <br/><br/>

        <b>Changes to our Privacy Policy</b> <br/>
        We reserve the right to change this privacy policy at any time for any reason. Notice of any such changes will be posted as an updated Privacy Policy on this site. 

    </p>
</div> 