import { Component, OnInit, ViewChild, ElementRef,Inject, Output, EventEmitter, Input, ContentChildren,Renderer2 } from '@angular/core';
// import { MapSectionComponent } from '../map-section/map-section.component';
// import { MapsAPILoader } from '@agm/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { MapSectionComponent } from '../map-section/map-section.component';
import { CustomService } from '../../services/custom.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
declare var $:any; 

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss']
})
export class EmergencyComponent implements OnInit {
  current_lat: any;
  current_lng: any;
  origin: any;
  destination: any;
  placesList: any = [];
  allmarkerset: any = [];
  markerIcon: any;
  @ViewChild(MapSectionComponent) mapchild: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  availabletransitDetails: any;
  availableRouteBus: any;
  availableRouteTaxi: any;
  availableRouteTrain: any;
  availableRouteFerry: any;
  availableRouteBike: any;
  availableRouteWalk: any;
  availableRouteAuto: any;
  availableRouteMetro: any;
  availableRouteBicycle: any;
  availableRouteCar: any;
  current_url:any;
  url1:any;
  isPandemic:boolean=false;
  isImgZoom:boolean = false
    constructor(public http: HttpClient,@Inject(DOCUMENT) private _document: Document,private renderer: Renderer2,private spinner: NgxSpinnerService,public Service: CustomService, private el: ElementRef, private router: Router) {
    this.current_url = this.router.url;
    this.Service.placesList=[];
    this.url1 = this.router.url;
    
    this.Service.main_url = this.url1.split('/')[1];
    this.Service.main_layer_url = this.url1.split('/')[2];
    this.Service.sub_layer_url = this.url1.split('/')[3];
    this.Service.current_language = localStorage.getItem('current_language');
    if(this.Service.main_layer_url!=''&&this.Service.main_layer_url!=null&&this.Service.main_layer_url!=undefined){
      this.show_map = false;
    }
    else{
      this.show_map= true;
    } 

    if (this.router.getCurrentNavigation()?.extras.state) {
     let val:any = this.router.getCurrentNavigation()?.extras.state;
     this.isPandemic = val.data == "pandemic" ? true : false;
      
     /*  setTimeout(() => {
        if (this.searchInfo.data.type === "search") {
         
         
          let sinle_places = this.originalList.filter((x: any) => x.id == this.searchInfo.data.id)[0];
          if(sinle_places != undefined){
            this.locationDetailsFlag = true;
            
            this.locationDetails(sinle_places);
          }else{
            // this.Service.AlertWarning("Route is not available");
          }
         
        }
      }, 6000); */
    }
  }
// history_status:boolean =true;
show_map:boolean = false;
  ngOnInit() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      console.log(result.state);
      if(result.state === "granted" || result.state === "prompt"){
            if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {

        let latitude = position.coords.latitude.toFixed(6);
        let langitude = position.coords.longitude.toFixed(6)

        this.origin = { lat: Number(latitude), lng: Number(langitude) };
        this.current_lat = Number(latitude);
        this.current_lng = Number(langitude);
      },err=>{
        console.log("err",err);
       
        this.origin = { lat: 9.9612714, lng: 76.2457904 };
        this.current_lat = 9.953000;
        this.current_lng = 76.240000;
      });
    } else {
      this.origin = { lat: 9.9612714, lng: 76.2457904 };
      this.current_lat = 9.953000;
      this.current_lng = 76.240000;
    }
      } else{
        this.origin = { lat: 9.9612714, lng: 76.2457904 };
        this.current_lat = 9.953000;
        this.current_lng = 76.240000;
      }
    });
   
    this.Service.zoom = 15; 
    // this.show_map = history.state.data !='' && history.state.data !=null && history.state.data !=undefined?false:true;
    // this.history_status = history.state.data !='' && history.state.data !=null && history.state.data !=undefined?true:false;
    // setTimeout(() => {
    //   if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
    //     this.show_map = false;
    //     //this.wafiPlacesGetWithLayer();
        this.getApis()
        
    //   } 
     
    // }, 500);
    
    // this.getApis();
  }
  getApis() {
    this.Service.GetApiUrl().subscribe((data: any) => {
      this.Service.BASE_URL = data.BASE_URL;
      // if(this.show_map==true)
      // this.wafiPlacesGetWithMainLayer();
    })
  }
 
  public hideMarkers(): void {
    // this.setMapOnAll(null);
    // this.createStrokeFns(this.Service.zoom);
    
    for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(null);
    }
    // this.mapchild.loadMap();
  }
  
  // Shows any markers currently in the array.
  public showMarkers(): void {
    
   /*  for (let i = 0; i < this.Service.markers.length; i++) {
      this.Service.markers[i].setMap(this.map);
    } */
    // this.mapchild.loadMap();
    // this.markerSet(this.placesList);
  }
  wafiPlacesGetWithMainLayer() { 

    
    if (this.Service.directionsRenderer) {
      this.Service.directionsRenderer.setMap(null);
    }
    this.show_map == false; 
    this.allmarkerset = [];
    this.Service.placesList = []; 
    var subLayer = "";
    // let lang_name = localStorage.getItem('language_name');
    this.Service.current_language = localStorage.getItem('current_language') !=null && localStorage.getItem('current_language') !=undefined && localStorage.getItem('current_language') !=''?localStorage.getItem('current_language'):'EN';
    this.Service.wafiMenuList();
    this.Service.wayfiMenuList.forEach((element: any) => {
      if(element.code === this.Service.main_layer_url)
      {
        if(element.subLayers!=null&&element.subLayers!=undefined&&element.subLayers!=''&&element.subLayers.length>0){
        subLayer=element.subLayers[0].code;
        }
      }
    });

    const httpOptions = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'level': 'details', 'scale': this.Service.zoom, 'lat': this.current_lat, 'lon': this.current_lng, 'layer': this.Service.main_layer_url}/*level: 'med'  'subLayer': this.Service.sub_layer_url */
    };
    let Data = { 'url': this.Service.BASE_URL + 'places', 'header': httpOptions }
    this.Service.GetApiService(Data, (res: any): void => {
      var result = res.length>0?res.slice(0, -1):[];
      if (result.length > 0) {

        var list:any=[]
        this.hideMarkers();
        this.showMarkers();
        if (this.Service.zoom >= 16) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 0);

        }
        if (this.Service.zoom == 15) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 4);
        }
        if (this.Service.zoom == 14) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 6);
        }
        if (this.Service.zoom == 13) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 8);
        }
        if (this.Service.zoom == 12) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 10);
        }
        if (this.Service.zoom == 11) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 12);
        }
        if (this.Service.zoom == 10) { 
          list = this.Service.getDistanceFromLatLonInKm(result, 14);
        } 
      }
   
      this.Service.placesList = list;
    
      if (this.Service.placesList.length > 0) {
      this.Service.placesList.forEach((element: any, index: any) => {   

    
        let ic_filt = this.Service.wayfiMenuList.filter((x: any) => x.code == element.layerCode)[0].subLayers.filter((x: any) => x.code == element.subLayerCode);
   
        if (ic_filt.length > 0&&ic_filt[0].iconId!=null) {
          element.iconId = this.Service.IMG_URL+'images/sublayer/' + ic_filt[0].iconId;
        }
        else {
          
          element.iconId = 'assets/images/icon_general.png'
        }
        // this.allmarkerset.push({lat:element.lat,lon:element.lon,iconId:element.iconId})
        element.showstatus = true;
        // element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL+'images/place/' + element.imageId : 'assets/images/wafi.jpg';
        let Data = this.Service.IMG_URL + 'images/place/' + element.imageId 
            this.http.get(Data).subscribe((res: any) => {
              element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/place/' + element.imageId : 'assets/images/noImage.png';
            }, err => {
              if (err.status == 404) {
                element.imageId = 'assets/images/noImage.png';
              } else {
                element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/place/' + element.imageId : 'assets/images/noImage.png';
              }

            })
        if (element.schedules != '' && element.schedules != null && element.schedules != undefined) {
          element.schedules[0].opentime = element.schedules[0].open.substring(0, 2) < 12 ? element.schedules[0].open.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].open.substring(0, 2) - 12 + ':' + element.schedules[0].open.substring(2, 4) + 'pm';
          element.schedules[0].closetime = element.schedules[0].close.substring(0, 2) < 12 ? element.schedules[0].close.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].close.substring(0, 2) - 12 + ':' + element.schedules[0].close.substring(2, 4) + 'pm';

          let day = element.schedules[0].day != '' && element.schedules[0].day != null && element.schedules[0].day != undefined ? this.Service.scheduleList.filter((x: any) => x.code == element.schedules[0].day).map((x: any) => x.name)[0] : '';
          element.schedules[0].dayto = day;
          var time = new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
          element.openstatus = time < element.schedules[0].closetime && time > element.schedules[0].opentime ? 'Open' : 'Close';
        }
      });
      // this.Service.markers = [];
        this.mapchild.markerMultiSet(this.Service.placesList);
      }
      else {
        // this.Service.markers = [];
        setTimeout(() => {
          this.mapchild.loadMap();
        }, 100);
      }
    })
  }
  wafiPlacesGetWithLayer() {
    // this.spinner.show(); 
    /* if (this.Service.directionsRenderer) {
      this.Service.directionsRenderer.setMap(null);
    } */
    
    if (this.Service.directionsRenderer) {
      this.Service.directionsRenderer.setMap(null);
      this.Service.flightPath1.setMap(null);
      this.Service.flightPath2.setMap(null);
      
    }
    // this.mapchild.loadMap();
    this.show_map == false;
    this.allmarkerset = [];
    this.Service.placesList = [];
    this.placesList_Single = []; 
    // this.Service.zoom = 16;
   
    this.Service.current_language = localStorage.getItem('current_language') !=null && localStorage.getItem('current_language') !=undefined && localStorage.getItem('current_language') !=''?localStorage.getItem('current_language'):'EN';

    for (var i = 0; i < this.Service.wayfiMenuList.length; i++) {
      if (this.Service.wayfiMenuList[i].subLayers != undefined && this.Service.wayfiMenuList[i].subLayers != null && this.Service.wayfiMenuList[i].subLayers.length > 0 && this.Service.wayfiMenuList[i].subLayers != '') {
        this.Service.subLayer = this.Service.wayfiMenuList[i].subLayers.filter((x: any) => x.code == this.Service.sub_layer_url).map((x: any) => x.name)[0];
        if (this.Service.subLayer != null && this.Service.subLayer != '' && this.Service.subLayer != undefined) {
          break;
        }
      }
    }
    // this.zoom = 16;
   
    const httpOptions1 = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'level': 'med', 'scale': Math.ceil(this.Service.zoom), 'lat': this.current_lat, 'lon': this.current_lng, 'layer': this.Service.main_layer_url, 'subLayer': this.Service.sub_layer_url }
    };
    let Data1 = { 'url': this.Service.BASE_URL + 'places', 'header': httpOptions1 }
    if(this.Service.sub_layer_url == 'hetr' || this.Service.sub_layer_url == 'hett' || this.Service.sub_layer_url == 'heml' || this.Service.sub_layer_url == 'hemm'){

      let mainlayer = '';

      let sublayer = ''; 

      if(this.Service.main_layer_url == 'tour' && this.Service.sub_layer_url == 'hetr'){
            mainlayer = 'tour';
            sublayer = 'hetr';
      }

      if(this.Service.main_layer_url == 'tour' && this.Service.sub_layer_url == 'hett'){
        mainlayer = 'tour';
        sublayer = 'hett';
  }

      if(this.Service.main_layer_url == 'toml' && this.Service.sub_layer_url == 'hemm'){
          mainlayer = 'tour';
          sublayer = 'hett';
      }

      


      if(this.Service.main_layer_url == 'toml' && this.Service.sub_layer_url == 'heml'){
        mainlayer = 'tour';
        sublayer = 'hetr';
      }

      const httpOptions2 = {
        headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
        params: { 'level': 'med', 'scale': this.Service.zoom, 'lat': this.Service.current_lat.toFixed(6), 'lon': this.Service.current_lng.toFixed(6), 'layer': mainlayer, 'subLayer':  sublayer }
      };
      let Data2 = { 'url': this.Service.BASE_URL + 'heritageTrails', 'header': httpOptions2 }


       this.Service.GetApiService(Data2, (result: any): void => {
    
    let originalList = result; 
        let allmarkerset:any = [];
     originalList.forEach((element: any, index: any) => { 
      element.subLayerCode = sublayer;
      element.lat = element.line_lat;
      element.lon = element.line_lon;
      element.line_stage = element.line_stage;
         allmarkerset.push(element)
      })
      
      this.mapchild.setPolygon(allmarkerset);  

  })
} 

    this.Service.GetApiService(Data1, (result: any): void => {
      // this.spinner.hide();
      result.forEach((element: any, index: any) => {
        if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
          if (element.id == history.state.data.id) {
                element.showstatus = true;
              }
              else{
                element.showstatus = false;
              }
        }
        else{
          element.showstatus = true;
        }
      })

      var list:any=[]
      this.hideMarkers();
        this.showMarkers();
        if(this.Service.zoom >= 16){
          list =  this.Service.getDistanceFromLatLonInKm(result,0);
         
        }
        if(this.Service.zoom == 15){
          list =  this.Service.getDistanceFromLatLonInKm(result,4); 
        }
        if(this.Service.zoom == 14){
          list =  this.Service.getDistanceFromLatLonInKm(result,6); 
        }
        if(this.Service.zoom == 13){
          list =  this.Service.getDistanceFromLatLonInKm(result,8); 
        }
        if(this.Service.zoom == 12){
          list =  this.Service.getDistanceFromLatLonInKm(result,10); 
        }     
        if(this.Service.zoom == 11){
          list =  this.Service.getDistanceFromLatLonInKm(result,12); 
        } 
        if(this.Service.zoom == 10){
          list =  this.Service.getDistanceFromLatLonInKm(result,14); 
        }   
       
        this.Service.placesList = list;

      // this.Service.placesList = result;
      let ic_filt = this.Service.wayfiMenuList.filter((x: any) => x.code == this.Service.main_layer_url)[0].subLayers;
      this.markerIcon = this.Service.IMG_URL+'images/sublayer/' + ic_filt.filter((x: any) => x.code == this.Service.sub_layer_url)[0].iconId;

      this.Service.placesList.forEach((element: any, index: any) => {
        this.allmarkerset.push(element)
        
        element.iconId = this.markerIcon;
        // element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL+'images/place/' + element.imageId : 'assets/images/wafi.jpg';
        let Data = this.Service.IMG_URL + 'images/place/' + element.imageId 
            this.http.get(Data).subscribe((res: any) => {
              element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/place/' + element.imageId : 'assets/images/noImage.png';
            }, err => {
              if (err.status == 404) {
                element.imageId = 'assets/images/noImage.png';
              } else {
                element.imageId = element.imageId != null && element.imageId != undefined && element.imageId != '' ? this.Service.IMG_URL + 'images/place/' + element.imageId : 'assets/images/noImage.png';
              }

            })
        if (element.schedules != '' && element.schedules != null && element.schedules != undefined) {
          element.schedules[0].opentime = element.schedules[0].open.substring(0, 2) < 12 ? element.schedules[0].open.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].open.substring(0, 2) - 12 + ':' + element.schedules[0].open.substring(2, 4) + 'pm';
          element.schedules[0].closetime = element.schedules[0].close.substring(0, 2) < 12 ? element.schedules[0].close.replace(/..\B/g, '$&:') + 'am' : element.schedules[0].close.substring(0, 2) - 12 + ':' + element.schedules[0].close.substring(2, 4) + 'pm';

          let day = element.schedules[0].day != '' && element.schedules[0].day != null && element.schedules[0].day != undefined ? this.Service.scheduleList.filter((x: any) => x.code == element.schedules[0].day).map((x: any) => x.name)[0] : '';
          element.schedules[0].dayto = day;
          var time = new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
          element.openstatus = time < element.schedules[0].closetime && time > element.schedules[0].opentime ? 'Open' : 'Close';
        }

      });
      
      if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
        let sinle_places = this.Service.placesList.filter((x: any) => x.id == history.state.data.id)[0];
        this.wafiPlacesDetails(sinle_places);
      }
      if (this.Service.placesList.length > 0 && (history.state.data == '' || history.state.data == null || history.state.data == undefined)) {
        // this.mapchild.markerMultiSet(this.Service.placesList);
        this.mapchild.markerSet(this.allmarkerset, this.markerIcon);
      // this.mapchild.markerMultiSet(this.Service.placesList);
      }
      else {
        setTimeout(() => {
          // this.Service.markers = [];
          this.mapchild.loadMap();
        }, 100);
      }
    })
  }

  chevaron_status: boolean = true;
  chevaronMin(type: any) {
    if (type == 'min') {
      this.chevaron_status = false;
    }
    else {
      this.chevaron_status = true;
    }
  }

  back_btn: boolean = false;
  qr_back_btn:boolean = true;
  placeDetail: any;
  placesList_Single:any = '';
  wafiPlacesDetails(data: any) { 
   
    
    // data.showstatus = true;
    if (this.Service.directionsRenderer) {
      this.Service.directionsRenderer.setMap(null);
    }
    this.Service.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
this.Service.locationInfo = {lat:data.lat,lng:data.lon};
    const httpOptions = {
      headers: { 'lang': this.Service.current_language, 'Authorization': this.Service.authorization_token },
      params: { 'id': data.id }
    };
    let Data = { 'url': this.Service.BASE_URL + 'place', 'header': httpOptions }
    this.Service.GetApiService(Data, (result: any): void => {
      if (result.images.length > 0) {
        for (var i = 0; i < result.images.length; i++) {
          result.images[i] = this.Service.IMG_URL+'images/place/' + result.images[i];
          // let imgVal = this.Service.IMG_URL + 'images/place/' +  result.images[i];
            /* this.http.get(imgVal).subscribe((res: any) => {
              result.images[i]=  result.images[i]!= null &&  result.images[i] != undefined &&  result.images[i] != '' ? this.Service.IMG_URL + 'images/place/' +  result.images[i]: 'assets/images/noImage.png';
            }, err => {
              if (err.status == 404) {
                // result.images[i] = 'assets/images/noImage.png';
              } else {
                result.images[i] =  result.images[i] != null &&  result.images[i] != undefined &&  result.images[i] != '' ? this.Service.IMG_URL + 'images/place/' +  result.images[i] : 'assets/images/noImage.png';
              }

            }) */
        }
      }
      else {
        let img = 'assets/images/wafi.jpg';
        result.images.push(img)
      }
      // this.placesList_Single = data;
      
      if(history.state.data!=null&& history.state.data!=undefined&& history.state.data!=''){
      
        this.qr_back_btn = history.state.data.type_status == 'qrcode'?false:true;
      }
      // else{
      //   this.back_btn = true;
      // }
      this.back_btn = true;
      
      this.Service.placesList.forEach(function (element: any) {
        
        if (element.id != data.id) {
          element.showstatus = false;
        }
        else {
          // element.showstatus = true;
          element.details = result;
          element.details.imageId = element.imageId
          element.details.iconId = element.iconId
        }
      });
    // let dd = this.Service.placesList;
    let fin_Data:any;
      if (history.state.data != '' && history.state.data != null && history.state.data != undefined) {
        fin_Data = this.Service.placesList.filter((x: any) => x.id == history.state.data.id)[0];       
      }
      if(this.isPandemic){
         fin_Data = this.Service.placesList.filter((x: any) => x.id == data.id)[0];
        // result.images[i] =  result.images[i] != null &&  result.images[i] != undefined &&  result.images[i] != '' ? this.Service.IMG_URL + 'images/place/' +  result.images[i] : 'assets/images/noImage.png';
        
      }else{
        fin_Data = this.Service.placesList.filter((x: any) => x.id == data.id)[0];
        
      }
      this.placesList_Single = fin_Data;
      
      
      setTimeout(() => {

       
        this.Service.origin = this.origin;
        if(history.state.data!=null&&history.state.data!=undefined&&history.state.data!=''){
          if(history.state.data.type_status=='qrcode'){
            let icon = "assets/images/flag.png"
            let destination= {lat:data.lat,lng:data.lon};            
            this.mapchild.makeDestMarker(destination, icon, this.mapchild.map);
          }else{
            this.mapchild.mapDirectionView(this.origin, data.details);
          }
        
      }else{
        this.mapchild.mapDirectionView(this.origin, data.details);
      }

      }, 1000);
    })

  }

 
  wafiPlacesDetailsClose() {
    this.back_btn = false;
    this.placesList_Single = '';
    let data = { img: 'assets/images/2.jpg', starpoint: 0, latlng: { latitude: this.mapchild.latitude, longtitude: this.mapchild.longtitude }, icon_img: this.mapchild.icon_url }
    this.Service.placesList.forEach(function (element: any) {
      element.showstatus = true;
    });
    this.Service.zoom = 15;
    setTimeout(() => {
      if (this.Service.placesList.length > 0) {
        this.mapchild.clearMarkers();
      }
      if (this.Service.placesList.length > 0 && this.Service.sub_layer_url == '') {
        this.mapchild.markerMultiSet(this.Service.placesList);
      }
      else {
        this.mapchild.markerSet(this.allmarkerset, this.markerIcon);
      }
    }, 100);

  }

  disableFun(){
  this.show_map = true;
  // setTimeout(() => {
  this.loadMap();
    
  // }, 100);
  }
  marker:any;
  map:any;
  
  loadMap() {
    this.show_map = true;
    // this.Service.current_lat = 9.953000;
    // this.Service.current_lng = 76.240000;
    // this.Service.zoom = 16;
    // this.origin = { lat: 9.9612714, lng: 76.2457904 };
    var mapOptions = {
      zoom: this.Service.zoom,
      center: this.origin,
      mapId: '7ab97996c3d8635c',
      zoomControl: false,
    }
   
    this.map = new google.maps.Map(document.getElementById('map_canvas') as HTMLElement, mapOptions);
    
  var zoomDiv = document.createElement('div');
  zoomDiv.style.margin = '9px 10px 0px 0px';
  zoomDiv.style.cursor = 'pointer';
  // zoomDiv.style.opacity = "0.8";
  zoomDiv.style.backgroundColor = "#FFFFFF";
  zoomDiv.style.fontFamily = 'Open Sans';
  zoomDiv.style.borderRadius = '3px';
  zoomDiv.style.height = '72px';
  zoomDiv.style.width = '42px';

  var zoomout = document.createElement('div');
  zoomout.title = 'Click to zoom out';  
  zoomout.style.width = '100%';
  zoomout.style.height = '50%';
  zoomout.style.borderBottom = "1px solid rgb(158, 158, 158)";
  zoomDiv.appendChild(zoomout);

  var zoomoutText = document.createElement('div');
  zoomoutText.innerHTML = '<i class="fa fa-minus" style="line-height:36px" aria-hidden="true"></i>';
  zoomoutText.style.fontSize = '18px';
  zoomoutText.style.textAlign = 'center';
  zoomoutText.style.color = "#9e9e9e";
  zoomout.appendChild(zoomoutText);

  var zoomin = document.createElement('div');
  zoomin.title = 'Click to zoom in';
  zoomin.style.width = '100%';
  zoomin.style.height = '50%';
  zoomDiv.appendChild(zoomin);

  var zoominText = document.createElement('div');
  zoominText.innerHTML = '<i class="fa fa-plus" style="line-height:36px" aria-hidden="true"></i>';
  zoominText.style.fontSize = '18px';
  zoominText.style.textAlign = 'center';
  zoominText.style.color = "#9e9e9e";
  zoomin.appendChild(zoominText);
  google.maps.event.addDomListener(zoomout, 'click', ()=> {
    this.Service.zoom = this.map.getZoom() - 1;
    if(this.Service.zoom != 0){
      this.map.setZoom(this.Service.zoom);
      // this.Service.eventsDetailsFns();
      // this.placesGetWithoutLayer();
    }  
   });
 
    google.maps.event.addDomListener(zoomin, 'click', ()=> {
    this.Service.zoom = this.map.getZoom() + 1;
    if(this.Service.zoom != 21){
      this.map.setZoom(this.Service.zoom);
      // this.Service.eventsDetailsFns();
      // this.placesGetWithoutLayer();
    }
   });
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomDiv);

    this.marker = new google.maps.Marker({
      position: this.origin,
      title: "im here!",
    });

    this.marker.setMap(this.map);
    var radius_circle = new google.maps.Circle({
  		center: this.origin,
  		radius: 1 * 1000,
      strokeColor: "#919191",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FFFFFF",
      fillOpacity: 0,
  		clickable: false,
  		map: this.map
  	});
  }
  

  zoomImage(type:any){
    // 
   if(type =='add'){
     this.isImgZoom = true;
    $("#demo .item-1 img").addClass("zoom_img");
    // $("#demo .item-1 i").addClass("remove_show_emerg")
   }
   if(type =='remove'){
    this.isImgZoom = false;
      $("#demo .item-1 img").removeClass("zoom_img");
      // $("#demo .item-1 i").removeClass("remove_show_emerg")
    }
  }
}
