import { Component, OnInit, ViewChild, ElementRef,Renderer2, Inject, AfterViewInit, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MapSectionComponent } from '../map-section/map-section.component';
import { CustomService } from '../../services/custom.service';
declare const google: any;
import { SwiperOptions } from 'swiper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { $ } from 'protractor';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-init-home',
  templateUrl: './init-home.component.html',
  styleUrls: ['./init-home.component.scss']
})
export class InitHomeComponent implements OnInit {
  maxiHeight: boolean = false;
  mailFunction: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  sendMailForm: any;
  @ViewChild("sendMailId") sendMailId: any;
  constructor(public translate: TranslateService,@Inject(DOCUMENT) private _document: Document,private renderer: Renderer2,private formBuilder: FormBuilder, public Service: CustomService, private router: Router, private route: ActivatedRoute, private el: ElementRef, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, @Inject(DOCUMENT) document: any) {

    this.Service.current_language = localStorage.getItem('current_language');
    translate.addLangs(['EN', 'ML']);
    translate.setDefaultLang('EN');
    this.translate.use(this.Service.current_language);

  }
  ngOnInit() {
    this.Service.current_lat = 9.953000;
    this.Service.current_lng = 76.240000;
    this.Service.zoom = 16;
    // this.Service.lc = 'evnt';
    this.formValidation();
    this.mailFunction = new MailFunction();
    if (history.state.data != '' && history.state.data != null && history.state.data != undefined&&history.state.data=='redirectcontact') {
      this.moveContactFns('contact');
    }
    // this.loadScript();

  }

  formValidation() {
    this.sendMailForm = this.formBuilder.group({
      mailerName: ['',Validators.required],
      mailerEmail: ['',Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      mailercontactNumber: [''],
      mailerPurpose: [''],
      mailerMessage: ['',Validators.required]
    });
  }

  public errorHandling = (control: string, error: string) => {

    return this.sendMailForm.controls[control].hasError(error);

  }
  eventsDetail(data: any) {
    this.router.navigate(['/exko/evnt'])
  }

  sendMail() {
   
    if (this.sendMailForm.valid) {
      const httpOptions = {
        headers: { 'Authorization': this.Service.authorization_token },
        params: this.sendMailForm.value
      };
      let Data = { 'url': this.Service.BASE_URL + 'sendFeedback', 'header': httpOptions }
      this.Service.SendMailApiService(Data, (result: any): void => {
       
        this.Service.AlertSuccess(result.message);
        this.sendMailId.resetForm();
        // this.sendMailId.markAsUntouched();
        // this.sendMailId.updateValueAndValidity();

      })
    }
  }


  @ViewChild('contact', { static: true }) contact: any;
  @ViewChild('about', { static: true }) about: any;
  moveContactFns(type:any) {
    if(type=='about'){
      this.about.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }else{
      this.contact.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    
  }

}

export class MailFunction {
  mailerName: any = '';
  maileremail: any = "";
  mailercontactNumber: any = '';
  mailerPurpose: any = '';
  mailerMessage: any = '';
}