<div class="row">
    <app-header (parentFun)="getEventsfns($event)" (singleEventFun)="eventsDetail($event)"></app-header>
</div>
<!-- <ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "large"
color = "#fff"
type = "ball-fussion"
></ngx-spinner> -->
<!-- <mat-progress-spinner [mode]="mode" class="spinner" diameter='20' strokeWidth='50'></mat-progress-spinner> -->
<div class="row swiper-container" *ngIf="showgrid == true" style="height: 100%; overflow-y: scroll; overflow-x: hidden;"
    >
    <!-- <div class="twitter-like">
        <mat-spinner class="background" 
            mode="determinate" 
            value="100">
        </mat-spinner>
        <mat-spinner></mat-spinner>
        </div> [ngClass]="{'dark-overlay':mode=='indeterminate'}"-->
       
    <div class="event-wrapper" id="style-4" >
        <!--  scrollbar event-wrapper mode="indeterminate"-->
        <div class="container">
            <div class="force-overflow" style="padding-bottom:100px;padding-top:10px;">
                
                <div *ngIf="Service.eventAllDetails?.length>0">
                    
                    <div *ngFor="let eventall of Service.eventAllDetails">
                        <div class="d-flex">
                            <!--  style="padding-top: 20px;  routerLink=""  " <i class="fa fa-ellipsis-h" aria-hidden="true"></i>-->
                            <p class="eventsName">{{eventall.name}}</p>
                            <span *ngIf="eventall.array_data?.length>0" (click)="knowMoreFns(eventall.array_data[0])" class="know_more_btn">View All</span>
                        </div>
                        <div class="EventBox" style="padding: 0 35px;text-align: left !important;">
                            <div class="row m-0" *ngIf="eventall.array_data?.length>0">
                                <!-- <div id="qr_code" (click)="DownloadQRCode()">
                                   
                                    <span class="d-none">
                                <qr-code [size]="378" [value]="'https://kochiithile.com'"></qr-code>
                            </span>
                            </div> -->
                                <swiper [config]="config">
                                    <div class="swiper-wrapper">
                                        
                                    <!-- [slidesPerView]="3" [spaceBetween]="0" [initialSlide]="0"
                                    (swiper)="onSwiper($event)" (slideChange)="onSlideChange()" #newSwiper
                                    [navigation]="true" -->
                                    <!-- <ng-container class="swiper-slide">
                                        <ng-template swiperSlide *ngFor="let item of eventall.array_data"> -->
                                            <div *ngFor="let item of eventall.array_data" class="swiper-slide">
                                            <div class="col-padding-top col-md-12">
                                                <div class="card" style="text-align: left;">
                                                    <div class="img_height_200">
                                                        <img (click)="eventsDetail(item)"
                                                            class="emerg_inner_img cursor_pointer"
                                                            [src]='item.imageId' />
                                                    </div>
                                                    <div class="card-body">
                                                        <p class="carousel_item_head">
                                                           
                                                            {{item.title.slice(0, 100) + (item.title.length > 100 ? "..." : "")}}
                                                        </p>
                                                        <p class="font_w_600 cursor_pointer" matTooltipPosition="below"
                                                            [matTooltipClass]="'row-tooltip'"
                                                            matTooltip="{{item.description}}"
                                                            [innerHTML]="item.description.slice(0, 90) + (item.description.length > 90 ? '...' : '')">
                                                        </p>

                                                        <div *ngIf="eventall.name == 'Events'">
                                                            <p class="mrg_bot7" matTooltipPosition="below"
                                                                [matTooltipClass]="'row-tooltip'"
                                                                matTooltip="{{item.address.fullAddrText}}"><b>Venue:
                                                                </b>{{item.address.fullAddrText.slice(0, 70) +
                                                                (item.address.fullAddrText.length > 70 ? "..." : "")}}
                                                            </p>
                                                            <p class="mrg_bot7"><b>Date:</b> {{item.start |
                                                                date:'dd.MM.yyyy'}} -
                                                                {{item.end | date:'dd.MM.yyyy'}}</p>
                                                        </div>

                                                        <div *ngIf="eventall.name == 'Places To Visit'">
                                                            <p class="mrg_bot7" matTooltipPosition="below"
                                                                [matTooltipClass]="'row-tooltip'"
                                                                matTooltip="{{item.address.fullAddrText}}"><b>Location:
                                                                </b>{{item.address.fullAddrText.slice(0, 70) +
                                                                (item.address.fullAddrText.length > 70 ? "..." : "")}}
                                                            </p>
                                                        </div>
                                                        <div *ngIf="eventall.name == 'Things to do'">
                                                            <p class="mrg_bot7" matTooltipPosition="below"
                                                                [matTooltipClass]="'row-tooltip'"
                                                                matTooltip="{{item.address.fullAddrText}}"><b>Venue:
                                                                </b>{{item.address.fullAddrText.slice(0, 70) +
                                                                (item.address.fullAddrText.length > 70? "..." : "")}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <!-- </ng-template>
                                    </ng-container> -->
                                    <div class="swiper-pagination"></div>
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>
                                </swiper>
                            </div>
                            <div class="no_data" *ngIf="eventall.array_data?.length==0">
                                {{'No Data Found'|translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container"
                    *ngIf="Service.main_layer_url!=''&&Service.main_layer_url!=null&&Service.main_layer_url!=undefined">
                    <div class="page-header">
                        <h3 class="event_head">{{Service.mainLayer}}</h3>
                    </div>
                    <div class="row">
                        <div class="col-md-4" (click)="eventsDetail(location)"
                            *ngFor="let location of Service.eventDetails">
                            <div class="col-padding">
                                <div class="card">
                                    <div class="img_height_200">
                                        <img class="emerg_inner_img cursor_pointer" [src]='location.imageId'
                                            alt="Card image cap">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title" matTooltipPosition="below"
                                            [matTooltipClass]="'row-tooltip'" matTooltip="{{location.title}}">
                                            {{location.title.slice(0, 100) + (location.title.length > 100 ? "..." : "")}}
                                        </h5>
                                        <p class="card-text cursor_pointer"
                                            [innerHTML]="location.description.slice(0, 90) + (location.description.length > 90 ? '...' : '')"
                                            matTooltipPosition="below" [matTooltipClass]="'row-tooltip'"
                                            matTooltip="{{location.description}}">
                                        </p>
                                        <div *ngIf="Service.mainLayer == 'Events'">
                                            <p matTooltipPosition="below" [matTooltipClass]="'row-tooltip'"
                                                matTooltip="{{location.address.fullAddrText}}"><b>Venue:</b>
                                                {{location.address.fullAddrText.slice(0, 70) +
                                                (location.address.fullAddrText.length > 70 ? "..." : "")}}</p>
                                            <p><b>Date:</b> {{location.start | date:'dd.MM.yyyy'}} - {{location.end |
                                                date:'dd.MM.yyyy'}}</p>
                                        </div>
                                        <div *ngIf="Service.mainLayer == 'Places To Visit'">
                                            <p matTooltipPosition="below" [matTooltipClass]="'row-tooltip'"
                                                matTooltip="{{location.address.fullAddrText}}"><b>Location:</b>
                                                {{location.address.fullAddrText.slice(0, 70) +
                                                (location.address.fullAddrText.length > 70 ? "..." : "")}}</p>
                                            
                                        </div>
                                        <div *ngIf="Service.mainLayer == 'Things to do'">
                                            <p matTooltipPosition="below" [matTooltipClass]="'row-tooltip'"
                                                matTooltip="{{location.address.fullAddrText}}"><b>Venue:</b>
                                                {{location.address.fullAddrText.slice(0, 70) +
                                                (location.address.fullAddrText.length > 70 ? "..." : "")}}</p>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="no_data" *ngIf="Service.eventDetails?.length==0">
                            {{'No Data Found'|translate}}
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
<div class="cotact-wrapper" *ngIf="showgrid == false && showgrid_status==1">
    <div class="form-section" style="margin-top: 60px;" [ngClass]="{'remove-form-section':chevaron_status == false}">
        <span *ngIf="chevaron_status == true" (click)="chevaronMin('min')" class="chevaron_left_sty">
            <i class="fas fa-chevron-left"></i>
        </span>
        <span *ngIf="chevaron_status == false" (click)="chevaronMin('max')" class="chevaron_left_sty">
            <i class="fas fa-chevron-right"></i>
        </span>
        <div class="form-item">
            <span class="emergncy_steps"><span (click)="eventsDetailClose()" class="back_btn"
                    *ngIf="events_details==true&&qr_back_btn==true"><span><i
                            class="fas fa-chevron-left left_icon_pos"></i>
                    </span><span
                        class="cursor_pointer">{{'Back'|translate}}</span></span><span>{{Service.mainLayer}}</span><span
                    class="arrow_icn"><i class="fa fa-arrow-right arrow_icn_right" aria-hidden="true"></i>

                </span> <span style="color:'#445396'">{{event_list.title}}</span></span>
            <div class="emerg_sec-scroll">
                <ng-scrollbar #scrollable track="all" thumbClass="custom-scrollbars" pointerEventsMethod="scrollbar">
                    <div>
                        <div class="emerg_sec">
                            <div class="emerg_main">
                                <div class="emerg_img"><img [src]='event_list.images[0]' /></div>
                                <p class="head-tag">{{event_list.title}}</p>
                                <p class="emerg_detail" [innerHTML]="event_list.description"></p>
                                <div *ngIf="Service.mainLayer == 'Events'">
                                    <p><b>Address:</b> {{event_list.address.fullAddrText}}</p>
                                    <p><b>Start Date & Time:</b> {{event_list.start}}</p>
                                    <p><b>End Date & Time:</b> {{event_list.end}}</p>
                                    <p><b>For More Details:</b> </p>
                                </div>
                                <div *ngIf="Service.mainLayer == 'Places To Visit'">
                                    <p><b>Location:</b> {{event_list.address.fullAddrText}}</p>
                                    <!-- <p><b>Opening Time:</b> {{event_list.start | date:'h:mm a'}}</p>
                                    <p><b>Closing Time:</b> {{event_list.end | date:'h:mm a'}}</p>
                                    <p><b>Ticket:</b> </p>
                                    <p><b>Contact No:</b> </p> -->
                                </div>
                                <div *ngIf="Service.mainLayer == 'Things to do'">
                                    <p><b>Venue:</b> {{event_list.address.fullAddrText}}</p>
                                   <!--  <p><b>Duration/Time:</b> {{event_list.start | date:'h:mm a'}} - {{event_list.end |
                                        date:'h:mm a'}}</p>
                                    <p><b>Contact No:</b> </p>
                                    <p><b>For More Details:</b> </p> -->
                                </div>
                                <div class="content-height">
                                    <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar">
                                        <div>
                                            <div class="residency-details" id="demo">
                                                <div *ngFor="let list of event_list.images"
                                                    class="residency-details-item item-1"><img
                                                        (click)="zoomImage('add')" class="img-responsive cursor_pointer"
                                                        [src]="list" alt=""> <i class="fa fa-times cursor_pointer" (click)="zoomImage('remove')" 
                                                        [ngClass]="isImgZoom == true ? 'remove_show_emerg' : 'remove_Icon'"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-scrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-scrollbar>
            </div>
        </div>
        <div>
            <app-footer></app-footer>
        </div>
    </div>
    <div class="map-section" [ngClass]="{'remove-map-section':chevaron_status == false}">
        <app-map-section></app-map-section>
    </div>

</div>
<div class="col-lg-12 footer_bot" *ngIf="showgrid == true">
    <app-footer></app-footer>
</div>