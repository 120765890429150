<div class="container-fluid ">
    <h1 class="center-item mt-2">Terms and Conditions</h1>   

    <p class="page-content f20">
        In Kochi Ithile site, Terms and Conditions agreement outlines the terms that visitors must agree to if they want to interact with this website. Essentially, if the visitor continues to use the website after accepting the Terms, they enter into all terms provided by Kochi Ithile. <br/><br/>
You aren't legally required to have a Terms and Conditions agreement. However, there are many reasons why you should draft one and display it on your website.<br/><br/>
All details related to contact information is available on our website. This is a digital platform provides its Services to you through its website and its mobile applications and related services, subject to the following Terms and Conditions as amended from time to time.<br/><br/>
These Terms and Conditions therefore constitute an agreement between you as User and Kochi Ithile as it relates to the use of this website and related services, irrespective of visits / seeing information.<br/><br/>
Kochi Ithile reserves the right, in its sole discretion, to amend these Terms and Conditions from time to time. Any changes may be implemented on any time without any notice / alerts.<br/><br/>
Your continued use of the Service after any such changes constitutes your acceptance of the new Terms and Conditions. If you do not agree to abide by these or any future Terms and Conditions do not use or access (or continue to use or access) the Kochi Ithile digital platform and related Service.<br/><br/>
In addition, when using certain services, you shall be subject to any additional terms applicable to such services that may be posted by any Service Provider from time to time like reference links, events, and other information. All such terms are hereby incorporated by reference into these Terms and Conditions.<br/><br/>
It is the User's responsibility to regularly check the website to determine if there have been changes to these Terms and Conditions and to review such changes.

    </p>
</div> 