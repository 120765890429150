import { Injectable } from '@angular/core';
import { ViewChild } from '@angular/core';
import { HttpClientModule, HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { EventsComponent } from '../pages/events/events.component';
import { NgxSpinnerService } from "ngx-spinner";

interface HttpCallback {
  (Apiresult?: any): void;
}
interface ICallback {
  (value?: boolean): void;
}
@Injectable({
  providedIn: 'root'
})
export class CustomService {
  map:any;
   
  origin:any = []
  destination:any = [];
  @ViewChild(EventsComponent) eventchild: any;
  // public authorization_token = 'Bearer dEhJUyBpUyBmUk9NIHRIRSBma3drVUkgY0xJRU5UIG9OTFkgLSBzTyBhUElTIGNBTiByRVNQT05ELg==';
  public authorization_token = 'Bearer dEhJUyBpUyBmUk9NIHRIRSBma3drVUkgY0xJRU5UIG9OTFkgLSBzTyBhUElTIGNBTiByRVNQT05ELg==';
  //  public BASE_URL = 'http://52.70.70.23:8079/public/'; 

  /***********     Dev API     ***********/
  //  public BASE_URL = 'http://52.70.70.23:8079/public/';
  // public IMG_URL = 'http://52.70.70.23:8079/';

  /***********     UAT API   ***********/
  // BASE_URL:any='';
//  public BASE_URL = 'http://52.70.70.23:8099/public/'; 
//    public IMG_URL = 'http://52.70.70.23:8099/';


public BASE_URL = 'https://api.kochiithile.com/public/'; 
public IMG_URL = 'https://api.kochiithile.com/';
  public language: any;
  // public language_name:string = 'EN';
  public scheduleList: any = [];
  public wayfiMenuList: any = [];
  public expkoMenuList: any = [];
  public markers:any =[];
  public marker:any;
  main_url: any;
  main_layer_url: any;
  main_layer_url1: any;
  sub_layer_url: any;
  sub_layer_url1: any;
  mainLayer: string = '';
  subLayer: string = '';
  placesList: any = [];
  eventDetails: any = [];
  current_lat: any;
  current_lng: any;
  zoom: any;
  lc: any;
  current_language: any = 'EN';
  searchlist: any = [];
  go_to_page: boolean = false;
  event_single_id: number = 0;
  eventAllDetails: any = [];
  directionsRenderer: any;
  locationInfo: any;
  // showgrid:boolean = false;
  malayalam_layer = [
    { code: "trml" },
    { code: "toml" },
    { code: "reml" },
    { code: "emml" },
    { code: "edml" },
    { code: "asml" },
    { code: "wsml" },
    { code: "otml" },
    { code: "C0ML" },
  ]
  flightPath1: any;
  flightPath2: any;

  constructor(private spinner: NgxSpinnerService,public http: HttpClient, public router: Router) { }
  langList() {
    const httpOptions = {
      headers: { 'Authorization': this.authorization_token }
    };
    let Data = { 'url': this.BASE_URL + 'lang/list', 'header': httpOptions }
    this.GetApiService(Data, (result: any): void => {
      this.language = result; 
    })
  }
  typeList() {
    this.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
    // let lang_name = localStorage.getItem('language_name');
    const httpOptions = {
      headers: { 'lang': this.current_language, 'Authorization': this.authorization_token }
    };
    let Data = { 'url': this.BASE_URL + 'scheduleTypes', 'header': httpOptions }
    this.GetApiService(Data, (result: any): void => {
      this.scheduleList = result;
    })
  }
  wafiMenuList() {
    this.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
    
    const httpOptions = {
      headers: { 'lang': this.current_language, 'Authorization': this.authorization_token },
      params: { 'type': 'wafi', 'langCode': this.current_language }
    };
    let Data = { 'url': this.BASE_URL + 'layerDetails', 'header': httpOptions }
    this.GetApiService(Data, (result: any): void => {
      this.wayfiMenuList = result;
      for (var i = 0; i < this.wayfiMenuList.length; i++) {
        if (this.wayfiMenuList[i].subLayers != undefined && this.wayfiMenuList[i].subLayers != null && this.wayfiMenuList[i].subLayers.length > 0 && this.wayfiMenuList[i].subLayers != '') {
          this.subLayer = this.wayfiMenuList[i].subLayers.filter((x: any) => x.code == this.sub_layer_url).map((x: any) => x.name)[0];
          if (this.subLayer != null && this.subLayer != '' && this.subLayer != undefined) {
            break;
          }
        }
      }
      if (this.main_url == 'wafi') {
        this.mainLayer = this.wayfiMenuList.filter((x: any) => x.code == this.main_layer_url).map((x: any) => x.name)[0];
      }
    
    })
  }
  exkoMenuList() {
    this.current_language = localStorage.getItem('current_language') != null && localStorage.getItem('current_language') != undefined && localStorage.getItem('current_language') != '' ? localStorage.getItem('current_language') : 'EN';
    const httpOptions = {
      headers: { 'lang': this.current_language, 'Authorization': this.authorization_token },
      params: { 'type': 'exko', 'langCode': this.current_language }
    };
    let Data = { 'url': this.BASE_URL + 'layerDetails', 'header': httpOptions }
    this.GetApiService(Data, (result: any): void => {
      this.expkoMenuList = result;
      this.lc = result[0].code;
      if (this.main_url == 'home') {
        //this.eventsDetailsFns();
      }
     
    })
  }
  
  /*------All Api's URL------*/
 
  GetApiUrl() {
    let Data = this.http.get("assets/config/config.json");
    return Data;
  }
      // Key Press
      NumberKeyPress(event: any) {
        const pattern = /^\d{1,6}(\.\d{1,2})?$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
      }

  GetApiService(Data: any, myCalback: HttpCallback) {
    this.GetService(Data).subscribe((result: any) => {
      if (result.status.code == 1) {
        myCalback(result['result']);
      }
    })
  }

  GetService(Datas: any) {
    return this.http.get(Datas.url, Datas.header)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide(); 
          let errMsg = '';
          if (error.error instanceof ErrorEvent) {
             errMsg = "Something went wrong! Please check network or try again!" //`Error: ${error.error.message}`;
          }
          else {  // Server Side Error
            errMsg = "Something went wrong! try again!" // `Error Code: ${error.status},  Message: ${error.message}`;
          }
          if(error.statusText=="Not Found"){
            this.AlertWarning("Server not running");
          }
          else{
            this.AlertWarning(errMsg);
          }
         
          return throwError(errMsg);
        })
      )
  }

  SendMailApiService(Data: any, myCalback: HttpCallback) {
    this.GetMailService(Data).subscribe((result: any) => {
      if (result.status.code == 1) {
        myCalback(result['status']);
      }
    })
  }

  GetMailService(Datas: any) {
    
    return this.http.post(Datas.url,Datas.header.params,{'headers':Datas.header.headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          let errMsg = '';
          if (error.error instanceof ErrorEvent) {
            errMsg = "Something went wrong! try again!";//`Error: ${error.error.message}`;
          }
          else {  // Server Side Error
            errMsg = "Something went wrong! try again!";//`Error Code: ${error.status},  Message: ${error.message}`;
          }
          this.AlertWarning(errMsg);
          return throwError(errMsg);
        })
      )
  }

 
  
  /*--------- Alert Messages-------*/
  AlertSuccess(Message: string) {
    Swal.fire({
      position: 'top-end',
      title: Message,
      toast: true,
      showConfirmButton: false,
      // allowEscapeKey: true,
      // allowOutsideClick: true,
      timer: 3000,
      type: 'success',
    })
  }
  AlertWarning(Message: string) {
    Swal.fire({
      position: 'top-end',
      title: Message,
      toast: true,
      showConfirmButton: false,
      // allowEscapeKey: true,
      // allowOutsideClick: true,
      timer: 3000,
      type: 'warning',
    })
  }
  ConfirmDialog(message: any, Yes: string, No: string, callback: (callback: ICallback) => void) {
    Swal.fire({
      html: message,
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: Yes,
      cancelButtonText: No,
    }).then((result) => {
      callback((result.dismiss != undefined && result.dismiss == Swal.DismissReason.cancel) ? false : result.value);
    })
  }
  /*--------//Alert ----------*/


  getDistanceFromLatLonInKm(list:any,km:any) {
    var MarkerList:any = [];
    var lat2 = 0,lat1 = 0, lon2 =0,lon1=0;
    list.forEach((e:any) => {
     lat1= e.lat, lon1 = e.lon;
  
     var R = 6371; // Radius of the earth in km
     var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
     var dLon = this.deg2rad(lon2-lon1); 
     var a =
         Math.sin(dLat/2) * Math.sin(dLat/2) +
         Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
         Math.sin(dLon/2) * Math.sin(dLon/2);
         var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
         var d = R * c; // Distance in km
         if(d > km){
          MarkerList.push(e); 
  
         }
        
        lat2= lat1;
        lon2 = lon1;
    });
     return MarkerList;
    }
  
     deg2rad(deg: number) {
      return deg * (Math.PI/180)
    }

}
